import { DialogTitle, Grid, Typography } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { TFullReviewMessageModalProps } from "./types/TFullReviewMessageModalProps";

export default function FullReviewMessageModal(
  props: TFullReviewMessageModalProps
) {
  const handleCloseModal = () => {
    props.setOpenModal(false);
  };

  return (
    <MuiDialog open={props.openModal} onClose={handleCloseModal} maxWidth="sm">
      <DialogTitle>{`Revue de la table n°${props.review.tableNumber} - ( note : ${props.review.rating} / 5 )`}</DialogTitle>
      <Grid container justifyContent={"center"} p={2}>
        <Typography>{props.review.message}</Typography>
      </Grid>
    </MuiDialog>
  );
}
