import { Grid, Typography, Box } from "@mui/material";
import ItemPreview from "../ItemPreview/ItemPreview";
import { TCategoryPreviewProps } from "./types/TCategoryPreviewProps";

export default function CategoryPreview(props: TCategoryPreviewProps) {
  return (
    <Box>
      {props.menu.categories.map((category) => (
        <Box key={category.id} position="relative">
          <div
            ref={(el) => (props.categoryRefs.current[category.id!] = el)}
            style={{
              position: "absolute",
              top: "-140px", // Adjust this value to fine-tune scroll position
              left: 0,
              height: "1px",
              width: "100%",
              pointerEvents: "none",
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: "30px", fontWeight: "600" }}
                gutterBottom
              >
                {category.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {category.itemList.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <ItemPreview item={item} category={category} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
