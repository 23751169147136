import React from "react";
import { Route, Routes } from "react-router-dom";
import MenuPreviewPage from "../Pages/MenuPreviewPage";
import CartValidationPage from "../../../Cart/modules/Validation/pages/CartValidationPage";
import OrderSentPage from "../../../Cart/modules/Validation/pages/OrderSentPage/OrderSentPage";

export default function InsideMenu() {
  return (
    <Routes>
      <Route path="empty" element={<MenuPreviewPage />} />
      <Route path="validation" element={<CartValidationPage />} />
      <Route path="orderSent" element={<OrderSentPage />} />
    </Routes>
  );
}
