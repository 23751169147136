import { ICartEntity } from "../../../common/Entities/ICartEntity";
import { useAppDispatch } from "../../../common/store/redux";
import {
  closeCart,
  getAllPendingCarts,
  rejectAllCart,
  rejectCartItem,
} from "../services/kitchenServices";
import { TCloseCartRequest } from "../services/types/Requests/TCloseCartRequest";
import { TRejectAllCartItemRequest } from "../services/types/Requests/TRejectAllCartItemRequest";
import { kitchenActions } from "../slice/kitchenSlice";

export default function useKitchenRepository() {
  const dispatch = useAppDispatch();
  const onFindAllKitchenCarts = async (): Promise<boolean> => {
    const resultAction = await dispatch(getAllPendingCarts());

    if (getAllPendingCarts.rejected.match(resultAction)) {
      return false;
    }
    const carts = resultAction.payload.carts;
    dispatch(kitchenActions.setKitchenCarts(carts));

    return true;
  };

  const onRejectEntireCart = async (
    request: TRejectAllCartItemRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(rejectAllCart(request));

    if (rejectAllCart.rejected.match(resultAction)) {
      return false;
    }
    dispatch(kitchenActions.cancelCart({ cartId: request.cartId }));
    return true;
  };

  const onCloseCart = async (request: TCloseCartRequest): Promise<boolean> => {
    const resultAction = await dispatch(closeCart(request));

    if (closeCart.rejected.match(resultAction)) {
      return false;
    }
    dispatch(kitchenActions.removeKitchenCart({ cartId: request.cartId }));
    return true;
  };

  const onRejectCartItem = async (request: {
    cartId: number;
    cartItemId: number;
    table: string;
  }): Promise<boolean> => {
    const resultAction = await dispatch(
      rejectCartItem({
        cartId: request.cartId,
        rejectDetails: { cartItemId: request.cartItemId, quantity: 1 },
        table: request.table,
      })
    );
    if (rejectCartItem.rejected.match(resultAction)) {
      return false;
    }
    dispatch(
      kitchenActions.rejectKitchenCartItem({
        cartId: request.cartId,
        cartItemId: request.cartItemId,
      })
    );
    return true;
  };

  const onUpdateCartList = (request: { cart: ICartEntity }): void => {
    dispatch(kitchenActions.updateCartList(request));
  };

  return {
    onFindAllKitchenCarts,
    onRejectEntireCart,
    onCloseCart,
    onRejectCartItem,
    onUpdateCartList,
  };
}
