import { useState } from "react";
import { useEditableTitle } from "../../../../../../common/containers/EditableTitle/useEditableTitleProps";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { categoryActions } from "../../slice/categorySlice";
import DeleteMenuCategoryConfirmationModal from "../Modals/DeleteMenuCategoryConfirmationModal";
import useMenuCategoryRepository from "../repository/useMenuCategoryRepository";
import { TEditableCategoryNameProps } from "./types/TEditableCategoryNameProps";
export default function EditableCategoryName(
  props: TEditableCategoryNameProps
) {
  const { onUpdateMenuCategory, onDeleteMenuCategory } =
    useMenuCategoryRepository({
      selectedMenu: props.selectedMenu,
    });
  const dispatch = useAppDispatch();

  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] =
    useState<boolean>(false);

  const {
    hasClickedAway,
    getEditableTitle,
    editableValue,
    handleCloseEdition,
  } = useEditableTitle({
    key: props.category.id!,
    defaultValue: props.category.name,
    style: {
      fontWeight: `${props.category.id! === props.selectedCategory?.id ? `600` : `500`}`,
      borderBottom: `${props.category.id! === props.selectedCategory?.id ? `2px solid #FBBC04` : `none`}`,
      fontSize: `20px`,
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
    saveEdit: async () => {
      const newCategory: IMenuCategoryEntity = {
        ...props.category,
        name: editableValue,
      };
      await onUpdateMenuCategory({
        id: newCategory.id!,
        name: newCategory.name,
        position: newCategory.position,
        menuId: newCategory.menuId,
      });
    },
    deleteEdit: () => {
      setIsDeleteCategoryModalOpen(true);
    },
  });

  const handleDeleteMenuCategoryTitle = async () => {
    await onDeleteMenuCategory({ category: props.category });
    handleCloseEdition();
    setIsDeleteCategoryModalOpen(false);
  };

  return (
    props.selectedCategory && (
      <div
        onClick={() => {
          props.setSelectedMenuCategory(props.category);
        }}
      >
        <DeleteMenuCategoryConfirmationModal
          handleDeleteMenuCategory={handleDeleteMenuCategoryTitle}
          handleCloseEdition={handleCloseEdition}
          open={isDeleteCategoryModalOpen}
          setIsDeleteCategoryModalOpen={setIsDeleteCategoryModalOpen}
        />
        {getEditableTitle()}
      </div>
    )
  );
}
