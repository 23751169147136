import { Grid, Typography } from "@mui/material";
import React from "react";
import { TEditCartTicketSupplementBoxProps } from "./types/TEditCartTicketSupplementBoxProps";

export default function EditCartTicketSupplementBox(
  props: TEditCartTicketSupplementBoxProps
) {
  return (
    <Grid item xs={12} md={12}>
      <Typography sx={{ color: "white" }} className="font-manrope">
        <span style={{ fontWeight: "500", fontSize: "11px" }}>Garnitures:</span>{" "}
        <span style={{ fontWeight: "300", fontSize: "10px" }}>
          {props.cartSupplements.map((supplement, index) => {
            return `${supplement.supplement.name} ${index !== props.cartSupplements.length - 1 ? `,` : ``}`;
          })}
        </span>
      </Typography>
    </Grid>
  );
}
