import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";
import { TCartItemCheckBoxProps } from "./types/TCartItemCheckBoxProps";

export default function CartItemCheckbox(props: TCartItemCheckBoxProps) {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const handleOnClick = () => {
    checked
      ? dispatch(cartActions.removeItemFromFinalCart(props.cartItem))
      : dispatch(cartActions.addItemToFinalCart(props.cartItem));

    setChecked(!checked);
  };
  const paymentCart = useAppSelector((state) => state.cart.paymentCart);
  const categoryId = props.cartItem.cartCategoryId;
  const finalCart = useAppSelector((state) => state.cart.finalCart);

  const isItemInFinalCart = () => {
    const categoryFinalCart = finalCart.categories.find(
      (category) => category.category.id === props.cartItem.item.categoryId
    );
    const categoryPaymentCart = paymentCart?.categories.find(
      (category) => category.category.id === props.cartItem.item.categoryId
    );
    if (
      !categoryFinalCart ||
      !categoryFinalCart.items.find((item) => item.id === props.cartItem.id)
    ) {
      setChecked(false);
      return;
    }
    if (categoryFinalCart && categoryPaymentCart) {
      const itemCurrentCart = categoryPaymentCart.items.find(
        (item) => item.id === props.cartItem.id
      );

      if (itemCurrentCart) {
        if (
          categoryFinalCart.items.find(
            (item) =>
              item.id === itemCurrentCart.id &&
              item.quantity === itemCurrentCart.quantity
          )
        ) {
          setChecked(true);
          return;
        }
      }
    }
  };

  useEffect(() => {
    isItemInFinalCart();
  }, [JSON.stringify(finalCart.categories)]);
  return (
    <Checkbox
      checked={checked}
      onClick={handleOnClick}
      sx={{
        color: "black",
        top: 0,
        margin: 0,
        justifyContent: "start",
        alignItems: "start",
        alignContent: "start",
      }}
      size="small"
    />
  );
}
