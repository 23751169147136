import accountOrdersIcon from "../../../../../../common/images/account_orders_icon.png";
import { useAppSelector } from "../../../../../../common/store/redux";
import StatsBox from "../StatsBox/StatsBox";

export default function AccountOrders() {
  const monthOrders = useAppSelector((state) => state.cart.monthOrders);
  const monthOrdersGrowthPercentage = useAppSelector(
    (state) => state.cart.monthOrdersGrowthPercentage
  );
  return (
    <>
      <StatsBox
        statValue={monthOrders ?? 0}
        hideCurrency={true}
        title="Commandes"
        img={{ url: accountOrdersIcon }}
        growthPercentage={monthOrdersGrowthPercentage}
        growthTimeframe={"MONTH"}
      />
    </>
  );
}
