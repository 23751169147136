import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LeavePageConfirmationModal from "../../../../../../common/containers/Popup/LeavePageConfirmationModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import SelectedMenuItemForm from "../Forms/SelectedMenuItemForm";
import PersonalizeItemImageUpload from "../PersonalizeItemImageUpload.tsx/PersonalizeItemImageUpload";
import { TSelectedMenuItemPageProps } from "./types/TSelectedMenuItemPageProps";
import { categoryActions } from "../../../category/slice/categorySlice";
import { itemActions } from "../../slice/itemSlice";
import { menuActions } from "../../../menu/slice/menuSlice";
import { selectSelectedMenu } from "../../../menu/selectors/menuSelector";
import UploadImageBoxWithoutModal from "../../../image/containers/UploadImageBoxWithoutModal/UploadImageBoxWithoutModalProps";
import { ReactComponent as CustomizeButtonIcon } from "../../../../../../common/images/itemCustomIcon.svg";
import CreateMenuItemCustomizationForm from "../../../itemCustomization/containers/Forms/CreateMenuItemCustomizationForm";
import CreateMenuItemCustomizationModal from "../../../itemCustomization/containers/Modal/CreateMenuItemCustomizationModal";
import { menuItemCustomizationActions } from "../../../itemCustomization/slice/menuItemCustomizationSlice";

export default function SelectedMenuItemPage(
  props: TSelectedMenuItemPageProps
) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { menuId, categoryId, itemId } = useParams();
  const selectedMenu = useAppSelector((state) => selectSelectedMenu(state));
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );
  const selectedItem = useAppSelector((state) => state.item.selectedItem);

  const [hasImageChanged, setHasImageChanged] = useState<boolean>(false);
  const [hasItemChanged, setHasItemChanged] = useState<boolean>(false);
  const [hasSupplementChanged, setHasSupplementChanged] =
    useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [hasMenuItemAllergenChanged, setHasMenuItemAllergenChanged] =
    useState<boolean>(false);

  useEffect(() => {
    if (!menuId || !categoryId || !itemId) {
      navigate("/dashboard/menus");
    }
  }, []);

  useEffect(() => {
    if (props.menuList && props.menuList.length > 0) {
      if (menuId) {
        if (!selectedMenu) {
          const foundMenu = props.menuList.find(
            (menu) => menu.id === Number(menuId)
          );

          foundMenu && dispatch(menuActions.setSelectedMenu(foundMenu));
        }
      }

      if (selectedMenu) {
        if (categoryId) {
          if (!selectedCategory) {
            const foundCategory = selectedMenu.categories.find(
              (category) => category.id === Number(categoryId)
            );

            foundCategory &&
              dispatch(categoryActions.setSelectedCategory(foundCategory));
          }
        }

        if (selectedCategory) {
          if (itemId) {
            if (!selectedItem) {
              const foundItem = selectedCategory.itemList.find(
                (item) => item.id === Number(itemId)
              );

              foundItem && dispatch(itemActions.setSelectedItem(foundItem));
            }
          }
        }
      }
    }
  }, [
    JSON.stringify(props.menuList),
    selectedMenu,
    selectedCategory,
    selectedItem,
  ]);

  useEffect(() => {}, [selectedMenu]);

  const handleOpenCreateMenuItemCustomizationModal = () => {
    dispatch(
      menuItemCustomizationActions.setIsCreateMenuItemCustomizationModalOpen(
        true
      )
    );
  };

  let hasUnsavedChanges =
    hasImageChanged ||
    hasItemChanged ||
    hasSupplementChanged ||
    hasMenuItemAllergenChanged;

  return selectedMenu && selectedCategory && selectedItem ? (
    <Grid container>
      <CreateMenuItemCustomizationModal menuItemId={selectedItem.id!} />
      {/*<LeavePageConfirmationModal hasUnsavedChanges={hasUnsavedChanges} />*/}
      <Grid item>
        <Button
          sx={{
            position: "absolute",
            right: "35px",
            backgroundColor: "#FBBC04",
            color: "white",
            textTransform: "none",
            borderRadius: "12px",
            fontWeight: "bold",
            top: "115px",
            "&:hover": { backgroundColor: "#FBBC04" },
          }}
          onClick={handleOpenCreateMenuItemCustomizationModal}
        >
          <CustomizeButtonIcon />
          <span style={{ marginLeft: "5px" }}>Donner le choix</span>
        </Button>
        <Box sx={{ display: "flex", flexWrap: "nowrap", width: "100%" }}>
          <Box sx={{ minWidth: "200px", maxWidth: "585px", width: "100%" }}>
            <UploadImageBoxWithoutModal
              selectedItem={selectedItem}
              selectedCategory={selectedCategory}
              selectedMenu={selectedMenu}
              setUploadedImage={setUploadedImage}
              setHasImageChanged={setHasImageChanged}
            />
          </Box>
          <Box
            sx={{
              width: `calc(100% - 585})`,
              flexShrink: 0,
              overflow: "auto",
              paddingLeft: 8,
            }}
          >
            <SelectedMenuItemForm
              selectedCategory={selectedCategory}
              selectedItem={selectedItem}
              selectedMenu={selectedMenu}
              hasImageChanged={hasImageChanged}
              setHasImageChanged={setHasImageChanged}
              hasItemChanged={hasItemChanged}
              setHasItemChanged={setHasItemChanged}
              hasSupplementChanged={hasSupplementChanged}
              setHasSupplementChanged={setHasSupplementChanged}
              uploadedImage={uploadedImage}
              hasMenuItemAllergenChanged={hasMenuItemAllergenChanged}
              setHasMenuItemAllergenChanged={setHasMenuItemAllergenChanged}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
