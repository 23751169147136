import { DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { TCreateMenuItemModalProps } from "./types/TCreateMenuItemModalProps";
import { itemActions } from "../../slice/itemSlice";
import CreateMenuItemForm from "../Forms/CreateMenuItemForm";

export default function CreateMenuItemModal(props: TCreateMenuItemModalProps) {
  const isCreateMenuItemModalOpen = useAppSelector(
    (state) => state.item.isCreateMenuItemModalOpen
  );
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(itemActions.setIsCreateMenuItemModalOpen(false));
  };
  return (
    <>
      <MuiDialog
        open={isCreateMenuItemModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
      >
        <DialogTitle>Création d'un artcile</DialogTitle>
        <Grid item p={2}>
          <CreateMenuItemForm
            selectedCategory={props.selectedCategory}
            selectedMenu={props.selectedMenu}
            onClose={handleCloseModal}
          />
        </Grid>
      </MuiDialog>
    </>
  );
}
