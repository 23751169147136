import { AddCircleOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { categoryActions } from "../../slice/categorySlice";
import CreateMenuCategoryModal from "../Modals/CreateMenuCategoryModal";
import EditableCategoryName from "./EditableCategoryName";
import { TMenuCategoryNavigationBarProps } from "./types/TMenuCategoryNavigationBarProps";

export default function MenuCategoryNavigationBar(
  props: TMenuCategoryNavigationBarProps
) {
  const dispatch = useAppDispatch();
  const menuList = useAppSelector((state) => state.menu.menuList);

  const handleOpenCreateMenuCategoryModal = () => {
    dispatch(categoryActions.setIsCreateCategoryModalOpen(true));
  };
  useEffect(() => {
    //Check if there is a category if so select the first one if not propose to create a new one
    if (props.selectedMenu.categories.length === 0) {
      handleOpenCreateMenuCategoryModal();
    }
  }, [JSON.stringify(menuList)]);

  return (
    <>
      <CreateMenuCategoryModal selectedMenu={props.selectedMenu} />

      <Grid
        container
        mt={4}
        sx={{
          borderBottom: "1px solid",
          borderColor: "rgba(0, 0, 0, 0.13)",
        }}
      >
        {props.selectedCategory &&
          props.selectedMenu.categories.map((category) => {
            return (
              <Grid
                key={category.id!}
                item
                mr={2}
                className="grid-center"
                sx={{ cursor: "pointer" }}
              >
                <EditableCategoryName
                  selectedCategory={props.selectedCategory}
                  selectedMenu={props.selectedMenu}
                  category={category}
                  setSelectedMenuCategory={props.setSelectedMenuCategory}
                />
              </Grid>
            );
          })}

        <Grid
          item
          mr={2}
          className="grid-center"
          onClick={handleOpenCreateMenuCategoryModal}
          sx={{ cursor: "pointer" }}
        >
          {props.selectedMenu.categories.length === 0 && (
            <Typography pr={2}>Ajouter une catégorie </Typography>
          )}
          <AddCircleOutlined sx={{ fontSize: "20px" }} />
        </Grid>
      </Grid>
    </>
  );
}
