import React, { useEffect, useRef, useState } from "react";
import { Typography, CircularProgress, Box, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../common/store/redux";
import useIAMRepository from "../../respository/useIAMRepository";
import ValidateIAMForm from "../Forms/ValidateIAMForm";
import useAuthRepository from "../../../Auth/repository/useAuthRepository";
import { IUserEntity } from "../../../../common/Entities/IUserEntity";

enum RediectionMessage {
  INVALID_URL = "Url invalide...",
  ALREADY_VALIDATED = "Cet utilisateur a déjà été validé...",
  ACCOUNT_VALIDATED = "Votre compte a été validé avec succès !",
  INTERNAL_ERROR = "Erreur interne. Impossible de validé le comtpe.",
}

export default function ValidateIAMPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const userToValidate = useAppSelector((state) => state.iam.userToValidate);
  const invalidUserRef = useAppSelector((state) => state.iam.invalidUserRef);
  const [countdown, setCountdown] = useState(5); // 5 seconds countdown
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const { onFindUserToValidate } = useIAMRepository();
  const timer = useRef<NodeJS.Timer | null>(null);
  const redirectionTimer = useRef<NodeJS.Timeout | null>(null);
  const [redirectionMessage, setRedirectionMessage] = useState<string | null>(
    null
  );
  const { onValidateIAM } = useIAMRepository();
  const { onSignIn } = useAuthRepository();

  const handleValidateRootUser = async (user: IUserEntity) => {
    const userId = user.userId!;
    const isValidated = await onValidateIAM({
      firstName: null,
      lastName: null,
      email: null,
      userId: userId,
      password: null,
    });
    if (!isValidated) {
      startTimerWithErrorMessage(RediectionMessage.INTERNAL_ERROR);
    }
    startTimerWithErrorMessage(RediectionMessage.ACCOUNT_VALIDATED);
  };

  const startTimerWithErrorMessage = (message: string) => {
    timer.current = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    redirectionTimer.current = setTimeout(() => {
      if (timer.current) {
        clearInterval(timer.current);
        navigate("/auth/signin");
      }
    }, 5000); // 5 seconds
    setRedirectionMessage(message);
  };

  useEffect(() => {
    return () => {
      if (timer.current && redirectionTimer.current) {
        clearInterval(timer.current);
        clearTimeout(redirectionTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!userRef || invalidUserRef) {
      startTimerWithErrorMessage(RediectionMessage.INVALID_URL);
    }
    if (!userToValidate && userRef) {
      onFindUserToValidate({ userRef: userRef });
    }
    if (userToValidate && userToValidate.emailValidated) {
      startTimerWithErrorMessage(RediectionMessage.ALREADY_VALIDATED);
    }
    if (userToValidate && userToValidate.role.name === "ROOT") {
      handleValidateRootUser(userToValidate);
    }
  }, [userRef, navigate, userToValidate, invalidUserRef]);

  return redirectionMessage ? (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h6" gutterBottom>
          {redirectionMessage ?? ""} Vous allez être redirigé vers la page
          d'accueil dans {countdown} secondes.
        </Typography>
        <CircularProgress />
      </Box>
    </>
  ) : userToValidate && userToValidate.role.name !== "ROOT" ? (
    <>
      <Grid
        container
        height={"calc(100vh - 86px)"}
        direction={"column"}
        alignItems={"center"}
      >
        <Grid item width={"400px"} mt={2}>
          <ValidateIAMForm
            userToValidate={userToValidate}
            setRedirectionMessage={setRedirectionMessage}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    </>
  );
}
