import React, { useEffect } from "react";
import { TItemCustomizationCategoryProps } from "./types/TItemCustomizationCategoryProps";
import { Divider, Grid, Typography } from "@mui/material";
import RequiredBox from "../../../../Menu/modules/itemCustomization/containers/RequiredBox/RequiredBox";
import ItemCustomizationOptions from "../Options/ItemCustomizationOptions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { menuPreviewActions } from "../../../slices/menuPreviewSlice";

export default function ItemCustomizationCategory(
  props: TItemCustomizationCategoryProps
) {
  const dispatch = useAppDispatch();

  const addToUnfilledIds = () => {
    if (props.menuItemCustomization.required) {
      dispatch(
        menuPreviewActions.addToUnfilledCustomizationIds(
          props.menuItemCustomization.id!
        )
      );
    }
  };
  const removeFromUnfilledIds = () => {
    if (props.menuItemCustomization.required) {
      dispatch(
        menuPreviewActions.removeFromUnfilledCustomizationIds(
          props.menuItemCustomization.id!
        )
      );
    }
  };

  useEffect(() => {
    addToUnfilledIds();
  }, []);
  const itemToAdd = useAppSelector((state) => state.menuPreview.itemToAdd);
  useEffect(() => {
    if (itemToAdd) {
      const found = itemToAdd?.cartCustomizations.find(
        (c) => c.menuItemCustomizationId === props.menuItemCustomization.id
      );
      if (found) {
        removeFromUnfilledIds();
      } else {
        addToUnfilledIds();
      }
    }
  }, [JSON.stringify(itemToAdd)]);
  const unfilledIds = useAppSelector(
    (state) => state.menuPreview.unfilledCustomizationIds
  );
  return (
    <Grid item width={"100%"} xs={12} md={12}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        paddingLeft="16px"
        paddingRight="16px"
      >
        <Grid item>
          <Typography
            sx={{ color: "black", fontWeight: "bold", fontSize: "24px" }}
          >
            {props.menuItemCustomization.name}
          </Typography>
        </Grid>
        {props.menuItemCustomization.required && (
          <Grid item>
            <RequiredBox />
          </Grid>
        )}
      </Grid>
      <Grid item mb={2} paddingLeft="16px" paddingRight="16px">
        {/*{unfilledIds.find((u) => u === props.menuItemCustomization.id) ? (
          <Typography
            sx={{ fontSize: "15px", color: "#f44336", fontWeight: "300" }}
          >
            Veuillez choisir une option
          </Typography>
        ) : (
          <Typography
            sx={{ fontSize: "15px", color: "#818181", fontWeight: "300" }}
          >
            Choisisez en jusqu'à {props.menuItemCustomization.maxQuantity}
          </Typography>
        )} */}
        <Typography
          sx={{ fontSize: "15px", color: "#818181", fontWeight: "300" }}
        >
          Choisisez en jusqu'à {props.menuItemCustomization.maxQuantity}
        </Typography>
      </Grid>
      {props.menuItemCustomization.menuItemCustomizationOptions.map(
        (o, index) => {
          return (
            <div key={o.id!}>
              <ItemCustomizationOptions
                option={o}
                customization={props.menuItemCustomization}
              />
              {index !==
                props.menuItemCustomization.menuItemCustomizationOptions
                  .length -
                  1 && <Divider sx={{ mt: 2, mb: 2 }} />}
            </div>
          );
        }
      )}
    </Grid>
  );
}
