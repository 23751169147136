import React from "react";
import { TKitchenTableProps } from "./types/TKitchenTableProps";
import { Grid, Typography } from "@mui/material";

export default function KitchenTable(props: TKitchenTableProps) {
  const tableNumberArray = (tableNumber: number): number[] => {
    return tableNumber.toString().split("").map(Number);
  };

  return (
    <Grid container spacing={1} justifyContent="flex-end" xs={6} md={6}>
      {tableNumberArray(props.tableNumber).map((digit, index) => (
        <Grid item key={index}>
          <Grid
            container
            sx={{
              backgroundColor: "#091E42",
              width: "24px",
              height: "24px",
              borderRadius: "4px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: 1,
              }}
            >
              {digit}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
