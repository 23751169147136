import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { TCustomPopupProps } from "./types/TCustomPopupProps";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomPopup = (props: TCustomPopupProps) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  const dialogPaperStyle = {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    position: "fixed" as "fixed",
    bottom: 0,
    margin: 0,
    width: "100%",
    maxWidth: "100%",
  };

  const dialogContentStyle = {
    padding: props.disablePadding ? 0 : "16px",
  };

  const backdropStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  const closeButtonStyle = {
    position: "absolute" as "absolute",
    right: 16,
    top: 16,
    color: "#9e9e9e", // Grey color
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{ style: dialogPaperStyle }}
      BackdropProps={{ style: backdropStyle }}
      onClose={handleClose}
    >
      {!props.disablePadding && (
        <DialogTitle>
          {props.title}
          <IconButton
            aria-label="close"
            style={closeButtonStyle}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent style={dialogContentStyle}>{props.children}</DialogContent>
    </Dialog>
  );
};

export default CustomPopup;
