import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import UpdateMenuItemTextField from "./UpdateMenuItemTextField";
import { TSelectedMenuItemFieldsProps } from "./types/TSelectedMenuItemFieldsProps";
import { useFormContext } from "react-hook-form";
import AddSupplementPage from "../../../supplements/containers/Pages/AddSupplementPage";
import UpdateMenuItemNumberField from "./UpdateMenuItemNumberField";
import AddMenuItemAllergenPage from "../../../allergens/containers/Pages/AddMenuItemAllergenPage";
import MenuItemCustomizationPage from "../../../itemCustomization/containers/Pages/MenuItemCustomizationPage";

export default function SelectedMenuItemFields(
  props: TSelectedMenuItemFieldsProps
) {
  const { watch } = useFormContext();
  const name = watch("name");
  const description = watch("description");
  const price = watch("price");

  useEffect(() => {
    if (
      name !== props.selectedItem.name ||
      description !== props.selectedItem.description ||
      price !== props.selectedItem.price
    ) {
      props.setHasItemChanged(true);
    } else {
      props.setHasItemChanged(false);
    }
  }, [name, description, price]);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        lg={12}
        xl={12}
        md={12}
        direction="column"
      >
        <Grid
          item
          xs={12}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <UpdateMenuItemTextField
            name="name"
            label="Nom"
            validation={{
              required: "Veuillez renseigner le nom de votre article",
            }}
            defaultValue={props.selectedItem.name}
            isBold={true}
            firstLetterUppercase={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <UpdateMenuItemTextField
            name="description"
            label="Description"
            validation={{
              required: "Veuillez renseigner le nom de votre article",
            }}
            defaultValue={props.selectedItem.description}
            isBold={false}
            multiline={true}
            rows={4}
            firstLetterUppercase={true}
          />
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <AddMenuItemAllergenPage
          menuItem={props.selectedItem}
          hasMenuItemAllergenChanged={props.hasMenuItemAllergenChanged}
          setHasMenuItemAllergenChanged={props.setHasMenuItemAllergenChanged}
        />
      </Grid>
      <AddSupplementPage
        selectedItemId={props.selectedItem.id!}
        supplementList={props.supplementList}
        setHasSupplementsChanged={props.setHasSupplementChanged}
        hasSupplementsChanged={props.hasSupplementChanged}
      />
      <MenuItemCustomizationPage menuItem={props.selectedItem} />

      <UpdateMenuItemNumberField
        name="price"
        label="Prix"
        validation={{
          required: "Veuillez renseigner le prix de votre atricle",
        }}
        currency="€"
        defaultValue={props.selectedItem.price}
        width="108.5px"
      />
    </>
  );
}
