import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuSupplementEntity } from "../../../../../common/Entities/IMenuSupplementEntity";
import { TMenuSupplementSliceState } from "./types/TMenuSupplementSliceState";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";

const initialState: TMenuSupplementSliceState = {
  selectedSupplement: null,
  supplementList: [],
  hasSupplementsChanged: false,
};

const menuSupplementSlice = createSlice({
  name: "supplement",
  initialState,
  reducers: {
    setSupplementList: (
      state,
      action: PayloadAction<IMenuSupplementEntity[]>
    ) => {
      state.supplementList = action.payload;
    },
    addSupplementToList: (
      state,
      action: PayloadAction<IMenuSupplementEntity>
    ) => {
      state.supplementList.push(action.payload);
      state.hasSupplementsChanged = true;
    },
    removeSupplementFromList: (state, action: PayloadAction<number>) => {
      state.supplementList = state.supplementList.filter(
        (supplement, supplementIndex) => supplementIndex !== action.payload
      );
      state.hasSupplementsChanged = true;
    },
    setSelectedSupplement: (
      state,
      action: PayloadAction<IMenuSupplementEntity | null>
    ) => {
      state.selectedSupplement = action.payload;
    },
    // New reducer to sync supplements with updated menu data
    syncSupplements: (state, action: PayloadAction<IMenuEntity[]>) => {
      // Extract all supplements from the updated menu data
      const allSupplements = action.payload
        .flatMap((menu) => menu.categories)
        .flatMap((category) => category.itemList)
        .flatMap((item) => item.supplements);

      // Update the supplement list
      state.supplementList = allSupplements;

      // Update the selected supplement if it exists
      if (state.selectedSupplement) {
        const updatedSupplement = allSupplements.find(
          (supplement) => supplement.id === state.selectedSupplement?.id
        );
        state.selectedSupplement = updatedSupplement || null;
      }

      // Reset the hasSupplementsChanged flag
      state.hasSupplementsChanged = false;
    },
  },
});

export const supplementReducer = menuSupplementSlice.reducer;
export const supplementActions = menuSupplementSlice.actions;
