import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPaymentState } from "./types/TPaymentState";
import { IPaymentEntity } from "../../../common/Entities/IPaymentEntity";

const initialState: TPaymentState = {
  paymentRef: "",
  payments: null,
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentRef: (state, action: PayloadAction<string>) => {
      state.paymentRef = action.payload;
    },
    setPayments: (state, action: PayloadAction<IPaymentEntity[]>) => {
      state.payments = action.payload;
    },
  },
});

export const paymentReducer = slice.reducer;

export const paymentActions = slice.actions;
