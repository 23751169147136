import { Box, Button, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import orderSentGif from "../../../../../../common/images/order_sent_gif.gif";
import handIcon from "../../../../../../common/images/orderSent_hand_icon.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import CartHeader from "../../../../containers/Header/CartHeader";
import OrderSentCartRecapBox from "../../containers/CartRecapBox/OrderSentCartRecapBox";
import { ICartEntity } from "../../../../../../common/Entities/ICartEntity";
import { menuPreviewActions } from "../../../../../MenuPreview/slices/menuPreviewSlice";

const OrderSentPage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const menuId = urlParams.get("menuId") || "";
  const cartState = useAppSelector((state) => state.cart.currentCart);
  const cartRecapBoxRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shouldSendOrder = useAppSelector((state) => state.cart.shouldSendOrder);
  const pendingCarts = useAppSelector((state) => state.cart.pendingCarts);
  const [cartsToShow, setCartsToShow] = useState<ICartEntity[] | null>(null);
  const [hasPendingCarts, setHasPendingCarts] = useState<boolean>(false);
  const rejectedCartItems = useAppSelector(
    (state) => state.cart.rejectedCartItems
  );

  const handleGoToPaymentPage = () => {
    dispatch(menuPreviewActions.setPaymentAvailable(true));
  };

  useEffect(() => {
    let carts: ICartEntity[] = [];
    if (
      cartState.categories.length === 0 &&
      pendingCarts &&
      pendingCarts.length > 0
    ) {
      carts = pendingCarts;
      setHasPendingCarts(true);
      setCartsToShow(carts);
    } else if (cartState.categories.length > 0) {
      carts.push(cartState);
      setCartsToShow(carts);
      setHasPendingCarts(false);
    }
  }, [JSON.stringify(cartState), JSON.stringify(pendingCarts)]);

  const showPaymentButton = (pendingCarts: ICartEntity[]) => {
    if (pendingCarts.find((pendingCart) => pendingCart.status === "READY")) {
      return true;
    }
    return false;
  };
  const showRejectButton = (pendingCarts: ICartEntity[]) => {
    if (rejectedCartItems && rejectedCartItems.length > 0) {
      return true;
    }
    return false;
  };
  return cartsToShow ? (
    <>
      <Grid container marginTop={3} marginBottom={3} justifyContent={"center"}>
        <Grid xs={12} md={8} sm={12} lg={8} padding={2}>
          <CartHeader
            title={"Commande envoyée"}
            subtitle="La cuisine a reçu votre commande"
            userRef={userRef}
            table={table}
            menuId={parseInt(menuId)}
          />
        </Grid>
        <Grid mt={2} height={"300px"}>
          <img
            src={orderSentGif}
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "cover" }}
          />
        </Grid>
        <Grid xs={12} height={"102px"} mb={3} padding={2}>
          <Box
            sx={{
              border: 1,
              borderColor: "grey.300",
              borderRadius: 4,
              marginTop: 2,
              boxShadow: 1,
              height: "100%",
              width: "100%",
            }}
            className="grid-center"
            padding={1}
          >
            <Grid container xs={12}>
              <Grid xs={3} p={2} className="grid-center">
                <img
                  src={handIcon}
                  width={"100%"}
                  height={"100%"}
                  style={{ border: 1 }}
                />
              </Grid>
              <Grid xs={9} className="grid-center">
                Personnel est informé! <br />
                Pour toute question, n'hésitez pas à nous appeller.
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {cartsToShow.map((cart) => {
          return (
            <Grid xs={12} md={8} sm={12} lg={8} padding={2}>
              <div key={cart.id!} tabIndex={-1} style={{ outline: "none" }}>
                <OrderSentCartRecapBox cart={cart} />
              </div>
            </Grid>
          );
        })}
        {hasPendingCarts && (
          <Grid
            container
            mt={5}
            mb={5}
            justifyContent={"center"}
            spacing={2}
            direction={"column"}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {/*showRejectButton(cartsToShow) && (
              <Grid item>
                <Button
                  sx={{
                    backgroundColor: "#F80000",
                    color: "white",
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: "bold",
                    p: 1,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    width: "181px",
                    "&:hover": {
                      backgroundColor: "#F80000",
                    },
                  }}
                >
                  Articles rejettés
                </Button>
              </Grid>
            )*/}

            {showPaymentButton(cartsToShow) && (
              <Grid item>
                <Button
                  sx={{
                    backgroundColor: "#00AC4F",
                    color: "white",
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: "bold",
                    p: 1,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    width: "181px",
                    "&:hover": {
                      backgroundColor: "#00AC4F",
                    },
                  }}
                  onClick={handleGoToPaymentPage}
                >
                  Aller au paiement
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  ) : (
    <>Chargement...</>
  );
};

export default OrderSentPage;
