import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TFindAllUserRoleResponse } from "./types/Response/TFindAllUserRoleResponse";

export const findAllRoles = createAsyncThunk(
  "user-role/find-all",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindAllUserRoleResponse>(
      dispatch,
      `/api/user-role/find-all/`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
