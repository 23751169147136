import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { menuActions } from "../../../menu/slice/menuSlice";
import {
  deleteMenuCategory,
  updateMenuCategory,
} from "../../service/categoryServices";
import { TDeleteMenuCategoryRequest } from "../../service/type/Request/TDeleteMenuCategoryRequest";
import { TUpdateMenuCategoryRequest } from "../../service/type/Request/TUpdateMenuCategoryRequest";
import { categoryActions } from "../../slice/categorySlice";
import { TuseMenuCategoryRepositoryProps } from "./types/TuseMenuCategoryRepositoryProps";

export default function useMenuCategoryRepository(
  props: TuseMenuCategoryRepositoryProps
) {
  const dispatch = useAppDispatch();
  const onUpdateMenuCategory = async (request: TUpdateMenuCategoryRequest) => {
    const resultAction = await dispatch(updateMenuCategory(request));

    if (updateMenuCategory.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const updatedMenuCategory: IMenuCategoryEntity = resultAction.payload;
    dispatch(
      menuActions.updateMenuCategory({
        menuId: props.selectedMenu.id!,
        category: updatedMenuCategory,
      })
    );
    return updatedMenuCategory;
  };

  const onDeleteMenuCategory = async (request: TDeleteMenuCategoryRequest) => {
    const resultAction = await dispatch(deleteMenuCategory(request));

    if (deleteMenuCategory.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(
      menuActions.deleteMenuCategory({
        menuId: props.selectedMenu.id!,
        categoryId: request.category.id!,
      })
    );

    return true;
  };

  return { onUpdateMenuCategory, onDeleteMenuCategory };
}
