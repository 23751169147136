import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import CategoryPreview from "../CategoryPreview/CategoryPreview";
import PersonalizeItemModal from "../Modals/PersonalizeItemModal";
import ShowCartButton from "../ShowCartButton/ShowCartButton";
import StickyHeader from "../StickyHeader/StickyHeader";
import SelectCategoryModal from "../Modals/SelectCategoryModal";
import Logo from "../../../../common/images/logo.png";

export default function MenuPreviewPage() {
  console.log("dans le empty");
  const dispatch = useAppDispatch();
  const menu = useAppSelector((state) => state.menuPreview.menu);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const filteredMenu = useAppSelector(
    (state) => state.menuPreview.filteredMenu
  );
  const selectedCategoryId = useAppSelector(
    (state) => state.menuPreview.selectedCategoryId
  );

  const categoryRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [currentCategory, setCurrentCategory] = useState<number | null>(null);

  const scrollToCategory = (categoryId: number) => {
    const element = categoryRefs.current[categoryId];
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (selectedCategoryId && categoryRefs.current[selectedCategoryId]) {
      scrollToCategory(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    dispatch(menuPreviewActions.setSearch(""));
  }, []);

  console.log("menu", menu);
  return (
    menu && (
      <>
        <SelectCategoryModal menu={menu} />
        <PersonalizeItemModal />
        <Box
          sx={{ paddingLeft: "12px", paddingTop: "27px", marginBottom: "26px" }}
        >
          <img src={Logo} width={"132px"} height={"38px"} />
        </Box>
        <StickyHeader menu={menu} userRef={userRef} table={table} />
        <Grid container mb={6} mt={1}>
          <Container>
            <CategoryPreview
              categoryRefs={categoryRefs}
              menu={filteredMenu ? filteredMenu : menu}
            />
          </Container>
        </Grid>
        <ShowCartButton userRef={userRef} table={table} menuId={menu.id!} />
      </>
    )
  );
}
