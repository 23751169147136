import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthRepository from "../../../modules/Auth/repository/useAuthRepository";
import { findAvailableCart } from "../../../modules/Cart/services/cartService";
import useMenuPreviewRepository from "../../../modules/MenuPreview/repository/useMenuPreviewRepository";
import { menuPreviewActions } from "../../../modules/MenuPreview/slices/menuPreviewSlice";
import { loadCartState } from "../../../utils/cart/cartLocalStorage";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { TProtectedRouteProps } from "./types/TProtectedRouteProps";
import useCartRepository from "../../../modules/Cart/repository/useCartRepository";
import LoadingBox from "../LoadingBox/LoadingBox";
import MenuPayment from "../../../modules/MenuPreview/containers/MenuState/MenuPayment";
import InsideMenu from "../../../modules/MenuPreview/containers/MenuState/InsideMenu";
import MenuSelection from "../../../modules/Cart/modules/MenuSelection/MenuSelection";
import OutsideMenu from "../../../modules/MenuPreview/containers/MenuState/OutsideMenu";
import { appActions } from "../../store/appSlice";
import SubscribeToNotificationModal from "../SubscribeToNotificationModal/SubscribeToNotificationModal";

const MenuProtectedRoute = ({ children }: TProtectedRouteProps) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const menuId = urlParams.get("menuId") || "";
  const menu = useAppSelector((state) => state.menuPreview.menu);
  const dispatch = useAppDispatch();
  const [hasAccesToPage, setHasAccessToPage] = useState<boolean>(false);
  const authenticatedUser = useAppSelector(
    (state) => state.menuPreview.authenticatedUser
  );
  const backToMenu = useAppSelector((state) => state.menuPreview.backToMenu);
  const { onFindAvailableCart } = useCartRepository();
  const navigate = useNavigate();
  const availableMenus = useAppSelector(
    (state) => state.menuPreview.availableMenus
  );
  const paymentAvailable = useAppSelector(
    (state) => state.menuPreview.paymentAvailable
  );
  const table = urlParams.get("table") || "";
  const cartStateInLocatStorage = loadCartState();
  const { onFindAvailableMenus, onFindMenu, onVerifyUserRef } =
    useMenuPreviewRepository();
  const handleAuthenticateUser = async () => {
    await onVerifyUserRef({ userRef: userRef });
  };
  const handleFindAvailableCart = async () => {
    await onFindAvailableCart({ table: table, userRef: userRef });
  };

  const handleFindMenu = async () => {
    await onFindMenu({ menuId: parseInt(menuId) });
  };

  useEffect(() => {
    dispatch(appActions.setIsSubscribeToNotificationModalOpen(true));
    //Update refs
    dispatch(menuPreviewActions.setUserRef(userRef));
    dispatch(menuPreviewActions.setTable(table));
    //Authenticate restaurant and get restaurant name and other required details
    if (!authenticatedUser) {
      handleAuthenticateUser();
    }
    //Get info on if there is a payment awaiting
    if (table && userRef && menuId) {
      handleFindAvailableCart();
    }
  }, []);

  useEffect(() => {
    //Fetch menus once user is authenticated
    //If there is menuId in url it fetch specific menu
    //If this menu dont exist it goes back to the url to select the menu(without menuId)
    //If there is no menuId and userAuthenticated(user always needs to be authenticated) it fetches all the available menus
    if (authenticatedUser) {
      if (!menuId || menuId === "") {
        onFindAvailableMenus({ userRef: authenticatedUser.userRef });
        return;
      }
      if (menuId) {
        if (availableMenus.length > 0) {
          const foundMenu = availableMenus.find(
            (menu) => menu.id === parseInt(menuId)
          );
          if (foundMenu) {
            dispatch(menuPreviewActions.setMenu(foundMenu));
            return;
          }
        }
        handleFindMenu();
      }
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (authenticatedUser && table && table !== "" && menu) {
      //Get info on if there is a payment awaiting
      handleFindAvailableCart();
    }
  }, [paymentAvailable, menu]);

  const pendingCarts = useAppSelector((state) => state.cart.pendingCarts);

  useEffect(() => {
    if (authenticatedUser && table && table !== "" && menu) {
      if (cartStateInLocatStorage?.currentCart) {
        navigate(
          `validation?userRef=${authenticatedUser.userRef}&table=${table}&menuId=${menu.id}`
        );
      } else {
        if (paymentAvailable) {
          navigate(
            `payment?userRef=${userRef}&table=${table}&menuId=${menu.id!}`
          );
        } else {
          if (pendingCarts && pendingCarts.length > 0) {
            navigate(
              `orderSent?userRef=${userRef}&table=${table}&menuId=${menu.id!}`
            );
          } else {
            navigate(
              `empty?userRef=${userRef}&table=${table}&menuId=${menu.id!}`
            );
          }
        }
      }
    }
  }, [paymentAvailable, JSON.stringify(menu), JSON.stringify(pendingCarts)]);

  const loading = useAppSelector((state) => state.menuPreview.loading);
  const authenticationFailed = useAppSelector(
    (state) => state.menuPreview.authenticationFailed
  );
  const authenticationSucceeded = useAppSelector(
    (state) => state.menuPreview.authenticationSucceeded
  );

  const pageState = (): JSX.Element => {
    //Chargement des menus
    //Chargement du menu spécifique
    //Erreur pas trouver le menu on est redirigé vers la page principale
    //Erreur pas trouver l'utilisateur
    //Pas de menus disponibles at all
    //De base la page charge et on attend soit error soit succès

    //[] => Loading start
    //[] => On authentifie lutilisateur(chargement)
    //[] => Si ca échoue erreur pas accès à la page
    //[] => Si ca réussi =>
    //[] => On regarde si il y a menuId
    //[] => On charge le menu spécifique
    //[] => SI ca réussis on affiche les children
    //[] => Si ca échoue on renvoie vers la page principale
    //[] => On charge tous les menus
    //[] => Si ca échoue pas de menus ou alors on affiche les menus(on affiche les children dans les deux cas)
    console.log("loading", loading);
    if (!loading) {
      if (authenticationFailed) {
        return (
          <>
            <Grid container justifyContent="center">
              <Typography>Vous n'avez pas accès à cette page</Typography>
            </Grid>
          </>
        );
      } else if (authenticationSucceeded) {
        if (table && paymentAvailable && !backToMenu) {
          return <MenuPayment />;
        } else if (
          (table && !paymentAvailable) ||
          (table && paymentAvailable && backToMenu)
        ) {
          return <InsideMenu />;
        } else {
          return <OutsideMenu />;
        }
      }
    }

    return (
      <>
        <LoadingBox />
      </>
    );
  };

  return (
    <>
      <SubscribeToNotificationModal userRef={userRef} table={table} />
      {pageState()}
    </>
  );
};

export default MenuProtectedRoute;
