import { Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import "../../../../common/containers/styles/customFonts.css";

import { ReactComponent as LogoutIcon } from "../../../../common/images/logout_icon.svg";

import SideBarButtonList from "../SideBarButtons/SideBarButtonList";
import SignoutButton from "../../../Auth/containers/SignoutButton/SignoutButton";

export default function DashboardSideBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const sidebarWidth = isMobile ? "180px" : isTablet ? "180px" : "231px";
  const fontSize = isMobile ? "14px" : "16px";
  const padding = isMobile ? "15px" : "25px";

  return (
    <Grid container sx={{ width: "100%", height: "100%" }}>
      <Grid
        item
        sx={{
          backgroundColor: "white",
          height: "100%",
          left: "0",
          borderRight: "1px solid",
          borderBottom: "1px solid",
          borderColor: "rgba(0, 0, 0, 0.13)",
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // This will push the logout to the bottom
          padding: "15px",
        }}
      >
        <SideBarButtonList />

        <Grid
          item
          container
          alignItems={"center"}
          pl={2}
          pt={2}
          sx={{
            width: "100%",
            borderTop: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.13)",
            cursor: "pointer",
          }}
        >
          <SignoutButton />
        </Grid>
      </Grid>
    </Grid>
  );
}
