import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { TItemCustomizationOptionsProps } from "./types/TItemCustomizationOptionsProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { menuPreviewActions } from "../../../slices/menuPreviewSlice";
import { useEffect, useState } from "react";

export default function ItemCustomizationOptions(
  props: TItemCustomizationOptionsProps
) {
  const dispatch = useAppDispatch();
  const itemToAdd = useAppSelector((state) => state.menuPreview.itemToAdd);

  // Calculate the selected quantity for the specific option
  const calculateSelectedQuantity = (): number => {
    const found = itemToAdd?.cartCustomizations.find(
      (c) => c.menuItemCustomizationId === props.customization.id
    );
    if (found) {
      return found.cartItemOptions.reduce(
        (previous, current) =>
          current.menuItemCustomizationOptionId === props.option.id
            ? previous + current.quantity
            : previous,
        0
      );
    }
    return 0;
  };

  // Check if max quantity has been reached for the customization
  const checkIfDisabled = () => {
    const found = itemToAdd?.cartCustomizations.find(
      (c) => c.menuItemCustomizationId === props.customization.id
    );
    if (found) {
      const totalQuantity = found.cartItemOptions.reduce(
        (previous, current) => previous + current.quantity,
        0
      );
      return totalQuantity >= props.customization.maxQuantity;
    }
    return false;
  };

  const [selectedQuantity, setSelectedQuantity] = useState<number>(
    calculateSelectedQuantity()
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(checkIfDisabled());

  // Update selected quantity and disabled status whenever itemToAdd changes
  useEffect(() => {
    setSelectedQuantity(calculateSelectedQuantity());
    setIsDisabled(checkIfDisabled());
  }, [itemToAdd]);

  const iconColor = isDisabled ? "#D9D9D9" : "#273551";

  const addQuantity = () => {
    if (!isDisabled) {
      dispatch(menuPreviewActions.addCustomizationOption(props.option));
    }
  };

  const removeQuantity = () => {
    dispatch(menuPreviewActions.removeCustomizationOption(props.option));
  };

  return (
    <Grid item paddingLeft="16px" paddingRight="16px">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Grid item>
          <Typography
            sx={{ color: "black", fontWeight: "400", fontSize: "18px" }}
          >
            {props.option.name}
          </Typography>
        </Grid>
        <Grid item>
          {selectedQuantity === 0 ? (
            <AddCircleRounded
              sx={{ color: isDisabled ? "#D9D9D9" : "#818181" }}
              onClick={addQuantity}
            />
          ) : (
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <RemoveCircleRounded
                  sx={{ color: "#818181" }}
                  onClick={removeQuantity}
                />
              </Grid>
              <Grid item>{selectedQuantity}</Grid>
              <Grid item>
                <AddCircleRounded
                  sx={{ color: iconColor }}
                  onClick={addQuantity}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
