import { Grid, Typography } from "@mui/material";
import React from "react";
import KitchenTable from "../KitchenTable/KitchenTable";
import { calculateTotalCartPrice } from "../../../../utils/cart/shared";
import { TKitchenTicketFooterTotalsProps } from "./types/TKitchenTicketFooterTotalsProps";

export default function KitchenTicketFooterTotals(
  props: TKitchenTicketFooterTotalsProps
) {
  return (
    <Grid container mt={1} mb={2} justifyContent="space-between">
      <Grid item>
        <Typography
          className="font-avenir"
          sx={{
            color: "black",
            fontWeight: "bold",
            fontSize: `${props.fontSize ? props.fontSize : `14px`}`,
          }}
        >
          €{calculateTotalCartPrice(props.cart)}
        </Typography>
      </Grid>

      <KitchenTable tableNumber={Number(props.cart.table)} />
    </Grid>
  );
}
