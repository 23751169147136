import { Divider, Grid } from "@mui/material";
import { TItemCustomizationDetailsProps } from "./types/TItemCustomizationDetailsProps";
import ItemCustomizationCategory from "../ItemCustomization/Cateogry/ItemCustomizationCategory";

export default function ItemCustomizationDetails(
  props: TItemCustomizationDetailsProps
) {
  return (
    <Grid container width="100%" xs={12} md={12}>
      <Grid item width="100%" mb={3} mt={1} position="relative">
        <Divider
          sx={{
            position: "absolute", // Take out of document flow
            left: 0, // Align with the left edge of the viewport
            right: 0, // Align with the right edge of the viewport
            borderBottomWidth: 4,
            borderColor: "rgba(117, 117, 117, 0.09)",
            margin: 0, // Remove any margin gaps
          }}
        />
      </Grid>
      {props.menuItemCustomizations.map((m) => {
        return <ItemCustomizationCategory menuItemCustomization={m} />;
      })}
      <Grid item width="100%" mt={3} position="relative">
        <Divider
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            borderBottomWidth: 4,
            borderColor: "rgba(117, 117, 117, 0.09)",
            margin: 0,
          }}
        />
      </Grid>
    </Grid>
  );
}
