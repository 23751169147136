// cartLocalStorage.ts

import { TCartState } from "../../modules/Cart/slices/types/TCartState";

const CART_STORAGE_KEY = "cartState";

export const saveCartState = (cartState: TCartState) => {
  try {
    const serializedState = JSON.stringify(cartState);
    localStorage.setItem(CART_STORAGE_KEY, serializedState);
  } catch (err) {
    console.error("Error saving cart state to localStorage", err);
  }
};

export const loadCartState = (): TCartState | undefined => {
  try {
    const serializedState = localStorage.getItem(CART_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState) as TCartState;
  } catch (err) {
    console.error("Error loading cart state from localStorage", err);
    return undefined;
  }
};

export const clearCartState = () => {
  try {
    localStorage.removeItem(CART_STORAGE_KEY);
  } catch (err) {
    console.error("Error clearing cart state from localStorage", err);
  }
};
