import React, { useEffect } from "react";
import { io } from "socket.io-client";
import useKitchenRepository from "../../../modules/Kitchen/repository/useKitchenRepository";
import { TCartInitiatedNotification } from "./types/TCartInitiatedNotification";
import { TCloseCartNotification } from "./types/TCloseCartNotification";
import { TCartItemRejectedNotification } from "./types/TCartItemRejectedNotification";
import { TCartItemListRejectedNotification } from "./types/TCartItemListRejectedNotification";
import { TCancelCartNotification } from "./types/TCancelCartNotification";
import useCartRepository from "../../../modules/Cart/repository/useCartRepository";
import { TOrderRejectedNotification } from "./types/TOrderRejectedNotification";
import { TNewOrderNotification } from "./types/TNewOrderNotification";
import useNotificationRepository from "../../modules/Notifications/repository/useNotificationRepository";
import { TFullyPaidNotification } from "./types/TFullyPaidNotification";
import { TPartiallyPaidNotification } from "./types/TPartiallyPaidNotification";
import { TTipNotification } from "./types/TTipNotification";

export enum NotificationEventType {
  CART_INITIATED = "cart.initiated",
  CART_REJECTED = "cart.rejected",
  CART_CLOSED = "cart.closed",
  CART_ITEM_REJECTED = "cart.item.rejected",
  CART_ITEM_LIST_REJECTED = "cart.item.list.rejected",
  CART_CANCELED = "cart.item.list.canceled",
  NOTIFICATION_NEW_ORDER = "notification.new.order",
  NOTIFICATION_ORDER_REJECTED = "notification.order.rejected",
  NOTIFICATION_TIP = "notification.tip",
  NOTIFICATION_PAID_PARTIALLY = "notification.paid.partially",
  NOTIFICATION_PAID_FULLY = "notification.paid.fully",
}

export default function useNotificationService() {
  const { onUpdateCartList } = useKitchenRepository();
  const {
    onCloseCartNotification,
    onRejectCartItemNotification,
    onCancelCartNotification,
  } = useCartRepository();
  const {
    onAddNewOrderNotificaiton,
    onAddOrderRejectedNotificaiton,
    onAddPaidFullyNotification,
    onAddPaidPartiallyNotification,
    onAddTipNotification,
  } = useNotificationRepository();
  useEffect(() => {
    const apiURL = `${process.env.REACT_APP_BASE_URL}`;
    const socket = io(apiURL);

    // Initialize notification service
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    // Handle all notification events in a single handler
    socket.onAny((eventType, payload) => {
      switch (eventType) {
        case NotificationEventType.NOTIFICATION_PAID_FULLY: {
          const response: TFullyPaidNotification = payload;
          console.log("Paid fully notification", payload);
          onAddPaidFullyNotification(response.notification);
          //onUpdateCartList(response);
          break;
        }
        case NotificationEventType.NOTIFICATION_PAID_PARTIALLY: {
          const response: TPartiallyPaidNotification = payload;
          console.log("PAid partially notification", payload);
          onAddPaidPartiallyNotification(response.notification);
          //onUpdateCartList(response);
          break;
        }
        case NotificationEventType.NOTIFICATION_TIP: {
          const response: TTipNotification = payload;
          console.log("Tip notification", payload);
          onAddTipNotification(response.notification);
          //onUpdateCartList(response);
          break;
        }
        case NotificationEventType.NOTIFICATION_NEW_ORDER: {
          const response: TNewOrderNotification = payload;
          console.log("New order notification", payload);
          onAddNewOrderNotificaiton(response.notification);
          //onUpdateCartList(response);
          break;
        }
        case NotificationEventType.NOTIFICATION_ORDER_REJECTED: {
          const response: TOrderRejectedNotification = payload;
          console.log("Order rejected notification", payload);
          onAddOrderRejectedNotificaiton(response.notification);
          //onUpdateCartList(response);
          break;
        }
        case NotificationEventType.CART_INITIATED: {
          const response: TCartInitiatedNotification = payload;
          console.log("Cart initiated:", payload);
          onUpdateCartList(response);
          break;
        }
        case NotificationEventType.CART_CLOSED: {
          const response: TCloseCartNotification = payload;
          onCloseCartNotification(response);
          console.log("Cart closed:", payload);
          break;
        }
        case NotificationEventType.CART_ITEM_REJECTED: {
          const response: TCartItemRejectedNotification = payload;
          onRejectCartItemNotification(response);
          console.log("Cart item rejected:", payload);
          break;
        }
        case NotificationEventType.CART_ITEM_LIST_REJECTED: {
          const response: TCartItemListRejectedNotification = payload;
          console.log("Cart item list rejected:", payload);
          break;
        }
        case NotificationEventType.CART_CANCELED: {
          const response: TCancelCartNotification = payload;
          onCancelCartNotification(response);
          console.log("Cart canceled:", payload);
          break;
        }
        default: {
          console.log("Unhandled event type:", eventType);
        }
      }
    });

    return () => {
      socket.disconnect();
      console.log("Disconnected from Socket.IO server");
    };
  }, []);
}
