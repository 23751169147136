import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAppSelector } from "../../../../../../common/store/redux";
import PagesNavigation from "../../../../../../common/containers/PagesNavigation/PagesNavigation";

export const TransferList = () => {
  const transferList = useAppSelector(
    (state) => state.fundsManagement.transferList
  );
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;

  const paginatedTransfers = () => {
    if (!transferList || !transferList.data) return [];
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return transferList.data.slice(startIndex, endIndex);
  };

  const maxPages = Math.ceil((transferList?.data.length || 0) / rowsPerPage);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <Table aria-label="transfer list table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "none",
                  fontSize: "12px",
                  color: "#B5B5C3",
                }}
              >
                Transaction ID
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "none",
                  fontSize: "12px",
                  color: "#B5B5C3",
                }}
              >
                Date added
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "none",
                  fontSize: "12px",
                  color: "#B5B5C3",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTransfers().map((payout) => (
              <TableRow
                key={payout.id}
                sx={{
                  backgroundColor: "white",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "#464E5F",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {payout.id}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "#464E5F",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {new Date(payout.created * 1000).toLocaleDateString()}
                  <br />
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#B5B5C3",
                      fontWeight: "normal",
                    }}
                  >
                    {new Date(payout.created * 1000).toLocaleTimeString()}
                  </span>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "#464E5F",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {payout.amount / 100} €
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PagesNavigation
        currentPage={currentPage}
        setPage={setCurrentPage}
        pagesNumber={maxPages}
      />
    </div>
  );
};
