import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IMenuAllergenEntity } from "../../../../../../common/Entities/IMenuAllergenEntity";
import { IMenuItemAllergenEntity } from "../../../../../../common/Entities/IMenuItemAllergenEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { isMenuItemAllergenListsSame } from "../../../../../../utils/menus/shared";
import { menuItemAllergenActions } from "../../slices/menuItemAllergen";
import SelectMenuItemAllergenDropdownField from "./SelectMenuItemAllergenDropdownField";
import { TAddMenuAllergenFieldsProps } from "./types/TAddMenuAllergenFieldsProps";

export default function AddMenuItemAllergenFields(
  props: TAddMenuAllergenFieldsProps
) {
  const { watch, setValue } = useFormContext();
  const menuItemAllergens = watch("menuItemAllergens");
  const dispatch = useAppDispatch();

  const menuAllergenListToStringList = (
    menuAllergenList: IMenuAllergenEntity[]
  ): string[] => {
    return menuAllergenList.map((menuAllergen) => menuAllergen.name);
  };

  const menuItemAllergenListToStringList = (
    menuItemAllergenList: IMenuItemAllergenEntity[]
  ): string[] => {
    return menuItemAllergenList.map(
      (menuItemAllergen) => menuItemAllergen.menuAllergen.name
    );
  };

  const stringListToMenuAllergenList = (
    stringList: string[],
    menuAllergenList: IMenuAllergenEntity[],
    menuItemId: number
  ): IMenuItemAllergenEntity[] => {
    const menuItemAllergenList: IMenuItemAllergenEntity[] = [];
    menuAllergenList.forEach((menuAllergen) => {
      if (stringList.includes(menuAllergen.name)) {
        menuItemAllergenList.push({
          menuAllergen: menuAllergen,
          menuItemId: menuItemId,
        });
      }
    });
    return menuItemAllergenList;
  };

  useEffect(() => {
    // Initialize the form with default values
    const defaultAllergens = menuItemAllergenListToStringList(
      props.menuItem.menuItemAllergen
    );
    setValue("menuItemAllergens", defaultAllergens);
  }, []);

  useEffect(() => {
    if (menuItemAllergens && menuItemAllergens.length > 0) {
      const menuItemAllergenList = stringListToMenuAllergenList(
        menuItemAllergens,
        props.menuAllergenList,
        props.menuItem.id!
      );
      const sameAllergens = isMenuItemAllergenListsSame(
        menuItemAllergenList,
        props.menuItem.menuItemAllergen
      );
      props.setHasMenuItemAllergenChanged(!sameAllergens);
      dispatch(
        menuItemAllergenActions.setMenuItemAllergenList(menuItemAllergenList)
      );
    } else {
      props.setHasMenuItemAllergenChanged(
        props.menuItem.menuItemAllergen.length > 0
      );
      dispatch(menuItemAllergenActions.setMenuItemAllergenList([]));
    }
  }, [menuItemAllergens]);

  return (
    <div>
      <SelectMenuItemAllergenDropdownField
        name="menuItemAllergens"
        defaultValue={menuItemAllergenListToStringList(
          props.menuItem.menuItemAllergen
        )}
        placeholder="Ajouter"
        label="Allergènes"
        allergens={menuAllergenListToStringList(props.menuAllergenList)}
      />
    </div>
  );
}
