import { SearchOutlined } from "@mui/icons-material";
import { Grid, InputBase } from "@mui/material";
import { useAppDispatch } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { useEffect, useRef } from "react";
import { TSearchBarProps } from "./types/TSearchBarProps";

export default function SearchBar(props: TSearchBarProps) {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(menuPreviewActions.setSearch(e.target.value));
  };

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      e.stopPropagation();
    };

    if (inputRef.current) {
      inputRef.current.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("touchstart", handleTouchStart);
      }
    };
  }, []);

  return (
    <div style={props.style}>
      <Grid item container xs={12} className="search-bar">
        <Grid item xs={3} className="grid-center">
          <SearchOutlined />
        </Grid>
        <Grid container item xs={8}>
          <InputBase
            sx={{ fontSize: "16px" }}
            onChange={handleSearch}
            inputRef={inputRef}
            inputProps={{ type: "search" }} // Ensures proper styling on mobile
            placeholder="Rechercher"
          />
        </Grid>
      </Grid>
    </div>
  );
}
