import React from "react";
import CustomPopup from "../../../../common/containers/Popup/CustomPopup";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { Divider, Grid, Typography } from "@mui/material";
import { TSelectCategoryModalProps } from "./types/TSelectCategoryModalProps";
import { IMenuEntity } from "../../../../common/Entities/IMenuEntity";
import { format } from "date-fns/format";
import { fr } from "date-fns/locale/fr";
import { IMenuCategoryEntity } from "../../../../common/Entities/IMenuCategoryEntity";
import { CloseOutlined } from "@mui/icons-material";

export default function SelectCategoryModal(props: TSelectCategoryModalProps) {
  const isSelectCategoryModalOpen = useAppSelector(
    (state) => state.menuPreview.isSelectCategoryModalOpen
  );
  const selectedCategoryId = useAppSelector(
    (state) => state.menuPreview.selectedCategoryId
  );
  const dispatch = useAppDispatch();

  const handleClosePopup = () => {
    dispatch(menuPreviewActions.setIsSelectCategoryModalOpen(false));
  };

  const handleSelectCategory = (category: IMenuCategoryEntity) => {
    dispatch(menuPreviewActions.setSelectedCategory(category));
    dispatch(menuPreviewActions.setSelectedCategoryId(category.id!));
    handleClosePopup();
  };

  const showMenuTimeFrame = (menu: IMenuEntity): JSX.Element => {
    let timeFrameText: string = "";

    if (!menu.activeFrom && !menu.activeTo) {
      timeFrameText = "Toute la journée";
    }

    const formatTime = (date: Date | null): string => {
      if (!date) return "";
      return format(date, "HH:mm", { locale: fr });
    };

    const fromTime = formatTime(menu.activeFrom);
    const toTime = formatTime(menu.activeTo);

    if (fromTime && toTime) {
      timeFrameText = `${fromTime} - ${toTime}`;
    } else if (fromTime) {
      timeFrameText = `À partir de ${fromTime}`;
    } else if (toTime) {
      timeFrameText = `Jusqu'à ${toTime}`;
    }

    if (menu.menuType.type === "DEFAULT") {
      timeFrameText = "";
    }

    return (
      <>
        <Typography>{timeFrameText}</Typography>
      </>
    );
  };
  return (
    <CustomPopup
      open={isSelectCategoryModalOpen}
      title=""
      onClose={handleClosePopup}
      disablePadding={true}
    >
      <Grid container padding={"16px"} xs={12} md={12}>
        {/*<Grid
          item
          sx={{ position: "absolute", top: 15, right: 15 }}
          border={1}
          xs={12}
          md={12}
        >
          <CloseOutlined />
        </Grid>*/}
        <Grid item xs={12} md={12}>
          <Grid
            container
            justifyContent={"center"}
            xs={12}
            md={12}
            direction={"column"}
          >
            <Grid item textAlign={"center"}>
              <Typography
                sx={{ fontSize: "27px", fontWeight: "bold", color: "black" }}
              >
                {props.menu.name}
              </Typography>
            </Grid>
            <Grid item textAlign={"center"}>
              <Typography>{showMenuTimeFrame(props.menu)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item>
          {props.menu.categories.map((category) => {
            return (
              <div
                onClick={() => {
                  handleSelectCategory(category);
                }}
              >
                <Typography
                  mb={2}
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    fontWeight: `${selectedCategoryId === category.id ? "bold" : "400"}`,
                  }}
                >
                  {category.name}
                </Typography>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </CustomPopup>
  );
}
