import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAppState } from "./types/TAppState";

const initialState: TAppState = {
  customerMenuLoading: false,
  appLoading: false,
  showErrorModal: false,
  errorMessage: "",
  successMessage: "",
  showSuccessModal: false,
  showRequiredInformationModal: false,
  requiredInformationMessage: "",
  informationMessage: "",
  showInformationModal: false,
  isSubscribeToNotificationModalOpen: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCustomerMenuLoading: (state, action: PayloadAction<boolean>) => {
      state.customerMenuLoading = action.payload;
    },
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setShowErrorModal: (state, action: PayloadAction<boolean>) => {
      state.showErrorModal = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.showErrorModal = true;
      state.customerMenuLoading = false;
      state.appLoading = false;
    },
    setShowSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.showSuccessModal = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
      state.showSuccessModal = true;
      state.customerMenuLoading = false;
      state.appLoading = false;
    },
    setShowRequiredInformationModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showRequiredInformationModal = action.payload;
    },
    setRequiredInformationMessage: (state, action: PayloadAction<string>) => {
      state.requiredInformationMessage = action.payload;
      state.showRequiredInformationModal = true;
      state.customerMenuLoading = false;
      state.appLoading = false;
    },
    setInformationMessage: (state, action: PayloadAction<string>) => {
      state.informationMessage = action.payload;
      state.showInformationModal = true;
      state.customerMenuLoading = false;
      state.appLoading = false;
    },
    setShowInformationModal: (state, action: PayloadAction<boolean>) => {
      state.showInformationModal = action.payload;
    },
    setIsSubscribeToNotificationModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSubscribeToNotificationModalOpen = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
