import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { customFormActions } from "../../../../common/store/customFormSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { cartActions } from "../../slices/cartSlice";
import ScanQrcodeForm from "../Payment/Form/ScanQrcodeForm";

export default function ScanQrcodePage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef");
  const menuId = urlParams.get("menuId");
  const forms = useAppSelector((state) => state.form.forms);

  return (
    <div
      style={{
        height: "calc(100vh - 86px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {userRef && menuId ? (
        <Grid
          container
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ScanQrcodeForm userRef={userRef} menuId={parseInt(menuId)} />
        </Grid>
      ) : (
        <Typography variant="h6" color={"red"}>
          Impossible d'accéder à cette page
        </Typography>
      )}
    </div>
  );
}
