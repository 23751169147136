import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TGetMenuItemRequest } from "./type/Requests/TGetMenuItemRequest";
import { TGetMenuItemResponse } from "./type/Responses/TGetMenuItemResponse";
import { TDeleteMenuItemRequest } from "./type/Requests/TDeleteMenuItemRequest";
import { TDeleteMenuItemResponse } from "./type/Responses/TDeleteMenuItemResponse";
import { TUpdateMenuItemRequest } from "./type/Requests/TUpdateMenuItemRequest";
import { TCreateMenuItemRequest } from "./type/Requests/TCreateMenuItemRequest";
import { TCreateMenuItemResponse } from "./type/Responses/TCreateMenuItemResponse";
import { TUpdateMenuItemResponse } from "./type/Responses/TUpdateMenuItemResponse";

export const getMenuItem = createAsyncThunk(
  "menu-item/find",
  async (request: TGetMenuItemRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetMenuItemResponse>(
      dispatch,
      `/api/menu-item/find/${request.itemId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
    } else {
      return rejectWithValue(response);
    }
  }
);
export const deleteMenuItem = createAsyncThunk(
  "menu-item/delete",
  async (request: TDeleteMenuItemRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TDeleteMenuItemResponse>(
      dispatch,
      `/api/menu-item/delete`,
      MethodEnum.DELETE,
      request
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenuItem = createAsyncThunk(
  "menu-item/update",
  async (request: TUpdateMenuItemRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TUpdateMenuItemResponse>(
      dispatch,
      `/api/menu-item/update`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      return response.data.item;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const createMenuItem = createAsyncThunk(
  "menu-item/create",
  async (request: TCreateMenuItemRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TCreateMenuItemResponse>(
      dispatch,
      `/api/menu-item/create`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data.item;
    } else {
      return rejectWithValue(response);
    }
  }
);
