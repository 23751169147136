import React, { useEffect, useRef, useState } from "react";
import { appActions } from "../../../../../../common/store/appSlice";
import { useAppDispatch } from "../../../../../../common/store/redux";
import BlankImageBox from "../BlankImageBox/BlankImageBox";
import PreviewImageBox from "../PreviewImageBox/PreviewImageBox";
import { TUploadImageBoxProps } from "./types/TUploadImageBoxProps";

export default function UploadImageBox(props: TUploadImageBoxProps) {
  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  //Quand il n'y as pas d'image il y a le cadre image
  //Quand il n'y as pas image et on hover sa se grise un peu
  //Quand il y a image on affiche l'image
  //Quand il y as pas image et on hover sa se grise un peu
  //En plus ca affiche en haut a droite une icone pour modifier ou supprimer l'image

  const handleUpdatePreviewImage = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const validateImage = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          const maxWidth = 390; // Example max width
          const maxHeight = 405; // Example max height
          const maxSizeInBytes = 2 * 1024 * 1024; // 5MB
          if (file.size > maxSizeInBytes) {
            dispatch(
              appActions.setErrorMessage(
                `La taille de l'image ne doit pas dépasser ${maxSizeInBytes / (1024 * 1024)}MB.`
              )
            );

            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
    });
  };

  const handleSavePreviewImageUrl = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        const isValid = await validateImage(file);
        if (isValid) {
          const reader = new FileReader();
          reader.onload = (e) => {
            props.setPreviewImage(e.target?.result as string);
          };
          reader.readAsDataURL(file);

          props.setFileToUpload(file);
        } else {
          event.target.value = "";
        }
      } else {
        dispatch(
          appActions.setErrorMessage("Veuillez entrer un fichier de type image")
        );
        event.target.value = "";
      }
    }
  };
  const handleHoverEnter = () => {
    setHovered(true);
  };
  const handleHoverLeave = () => {
    setHovered(false);
  };

  const handleDeletePreviewImage = () => {
    props.setDeleteImage(true);
    props.setPreviewImage(null);
  };
  const handleEditPreviewImage = () => {
    handleUpdatePreviewImage();
    props.setDeleteImage(false);
  };

  return (
    <>
      <div
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
        style={{
          backgroundColor:
            hovered && !props.previewImage ? "#f0f0f0" : "#ffffff",
          border: hovered ? "1px dashed #ccc" : "",
          width: `${props.width ? props.width : "150px"}`,
          height: `${props.height ? props.height : "150px"}`,
          position: "relative",
        }}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleSavePreviewImageUrl}
        />
        {!props.previewImage ? (
          <BlankImageBox
            width={props.width}
            height={props.height}
            fileInputRef={fileInputRef}
            handleUpdatePreviewImage={handleUpdatePreviewImage}
            hovered={hovered}
          />
        ) : (
          <PreviewImageBox
            width={props.width}
            height={props.height}
            previewImage={props.previewImage}
            handleUpdatePreviewImage={handleUpdatePreviewImage}
            hovered={hovered}
            handleDeletePreviewImage={handleDeletePreviewImage}
            handleEditPreviewImage={handleEditPreviewImage}
            mode={props.mode}
            item={props.item}
          />
        )}
      </div>
    </>
  );
}
