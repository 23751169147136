// CustomSelectField.tsx
import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface CustomSelectFieldProps {
  name: string;
  label: string;
  options: { value: string; label: string; disabled?: boolean }[];
  validation?: any;
}

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  name,
  label,
  options,
  validation,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  return (
    <FormControl margin="normal" error={!!errorMessage}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field }) => (
          <Select {...field} labelId={`${name}-label`} label={label}>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default CustomSelectField;
