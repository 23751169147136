import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVerticalMenu from "../MoreVerticalMenu/MoreVerticalMenu";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { TMenuBoxContentProps } from "./types/TMenuBoxContentProps";
import MenuItemListPreview from "../MenuItemList/MenuItemListPreview";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";

export default function MenuBoxContent(props: TMenuBoxContentProps) {
  const [previewCategory, setPreviewCategory] =
    useState<IMenuCategoryEntity | null>(null);
  const findCategoryWithItems = (
    menu: IMenuEntity
  ): IMenuCategoryEntity | null => {
    return (
      menu.categories.find((category) => category.itemList.length > 0) ?? null
    );
  };
  useEffect(() => {
    setPreviewCategory(findCategoryWithItems(props.menu));
  }, []);
  const formatDateTime = (date: Date) => {
    if (!date) return "";
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const showMenuBoxTimeframeText = (
    activeFrom: Date | null,
    activeTo: Date | null
  ): string => {
    if (activeFrom && activeTo) {
      return `(${formatDateTime(activeFrom)} - ${formatDateTime(activeTo)})`;
    }
    return "(Toute la journée)";
  };
  const showMenuBoxTitle = (menu: IMenuEntity) => {
    {
      return (
        <>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              "&::first-letter": {
                textTransform: "uppercase",
              },
            }}
          >
            {menu.name}{" "}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {showMenuBoxTimeframeText(menu.activeFrom, menu.activeTo)}
            </span>
          </Typography>
        </>
      );
    }
  };
  return (
    <div>
      <Grid
        item
        mt={4}
        key={props.menu.id}
        ref={(el) => (props.menuRefs.current[props.menu.id!] = el)}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item container m={2} pr={2} key={props.menu.id}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            {showMenuBoxTitle(props.menu)}
          </Grid>
          <Grid
            item
            container
            justifyContent={"end"}
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          >
            <MoreVerticalMenu
              selectedMenu={props.menu}
              menuList={props.menuList}
            />
          </Grid>
        </Grid>
        <div
          onClick={() => {
            props.handleGoToMenu(props.menu);
          }}
          style={{ cursor: "pointer" }}
        >
          <Grid
            container
            direction={"row"}
            sx={{
              boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.25)`,
              borderRadius: "6px",
              "&:hover": {
                boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.4)`,
                backgroundColor: "rgba(0, 0, 0, 0.02)",
              },
            }}
          >
            {!previewCategory ? (
              <>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  m={4}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Ajouter des articles
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <MenuItemListPreview
                  selectedCategory={previewCategory}
                  selectedMenu={props.menu}
                />
              </>
            )}
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
