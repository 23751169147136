import { Divider, Grid, Typography } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useEffect } from "react";
import KitchenTicketFooterTotals from "../KitchenTicket/KitchenTicketFooterTotals";
import EditCartTicketItemBox from "./EditCartTicketItemBox";
import { TEditCartModalProps } from "./types/TEditCartModalProps";

export default function EditCartModal(props: TEditCartModalProps) {
  const handleCloseModal = () => {
    props.setIsOpen(false);
  };

  useEffect(() => {
    if (props.cart) {
      console.log("props.cart Changed", props.cart);
    }
  }, [JSON.stringify(props.cart)]);
  return (
    <MuiDialog
      open={props.isOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      slotProps={{
        backdrop: true,
      }}
      PaperProps={{
        sx: {
          borderRadius: "24px", // Adjust this value as needed
          overflow: "hidden", // This ensures the content doesn't overflow the rounded corners
        },
      }}
    >
      <Grid container p={4} width={"100%"} justifyContent={"center"}>
        <Grid
          container
          direction="column"
          alignItems="center"
          maxWidth={"100%"}
        >
          <Grid container direction="row">
            <Grid item>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Mettre à jour la commande
              </Typography>
            </Grid>
            <Grid item ml={3} sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="font-manrope">
                <span style={{ fontWeight: "bold", fontSize: "10px" }}>
                  ID:
                </span>{" "}
                <span style={{ fontWeight: "400", fontSize: "9px" }}>
                  {props.cart.id}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item width={"100%"} mb={3}>
            <Divider />
          </Grid>
          <Grid container sx={{ maxHeight: "300px", overflow: "auto" }}>
            {props.cart.categories.flatMap((category) =>
              category.items.flatMap((item) =>
                Array(item.quantity - item.rejectedQuantity)
                  .fill(null)
                  .map((_, i) => (
                    <Grid item key={`${item.id}-${i}`} xs={12}>
                      <EditCartTicketItemBox
                        cartItem={item}
                        cart={props.cart}
                      />
                    </Grid>
                  ))
              )
            )}
          </Grid>
          <Grid item width={"100%"}>
            <Divider />
          </Grid>

          <KitchenTicketFooterTotals cart={props.cart} fontSize={"24px"} />
        </Grid>
      </Grid>
    </MuiDialog>
  );
}
