import { Grid } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { IUserRoleEntity } from "../../../../common/Entities/IRoleEntity";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import useIAMRepository from "../../respository/useIAMRepository";
import { TCreateIAMFormAttributes } from "./types/TCreateIAMFormAttributes";
import { TCreateIAMFormProps } from "./types/TCreateIAMFormProps";
import { TValidateIAMFormAttributes } from "./types/TValidateIAMFormAttributes";
import CustomEmailField from "../../../Auth/containers/Form/CustomEmailField";
import CustomPasswordField from "../../../Auth/containers/Form/CustomPasswordField";
import CustomTextField from "../../../../common/containers/Form/CustomTextFields";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import { TValidateIAMFormProps } from "./types/TValidateIAMFormProps";
import useAuthRepository from "../../../Auth/repository/useAuthRepository";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function ValidateIAMForm(props: TValidateIAMFormProps) {
  const dispatch = useAppDispatch();
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const { onValidateIAM } = useIAMRepository();
  const { onSignIn } = useAuthRepository();
  const emailRef = useRef<string | null>(null);
  const passwordRef = useRef<string | null>(null);
  const navigate = useNavigate();

  const submitHandler: SubmitHandler<TValidateIAMFormAttributes> = async (
    data
  ) => {
    emailRef.current = data.email;
    passwordRef.current = data.password;
    const userId = props.userToValidate.userId!;
    console.log("userId", userId);
    const isValidated = await onValidateIAM({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      userId: userId,
      password: data.password,
    });
    if (!isValidated) {
      emailRef.current = null;
      passwordRef.current = null;
    }
    return isValidated;
  };
  const successHandler = async () => {
    //Signin user on success
    if (emailRef.current && passwordRef.current) {
      props.setRedirectionMessage("Adresse email validée avec succès !");
      const isConnected = await onSignIn({
        email: emailRef.current,
        password: passwordRef.current,
        remember: false,
      });
      if (isConnected) {
        navigate("/dashboard");
      }
    } else {
      navigate("/auth/signin");
    }
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Créer mon compte"
      onSuccess={successHandler}
      name="validate-iam-form"
      defaultErrorDisplayCollapse={true}
    >
      <Grid item mb={4}>
        <CustomTextField
          name="lastName"
          label="Nom"
          validation={{
            required: "Veuillez renseigner votre nom de famille",
          }}
          firstLetterUppercase={true}
          defaultValue={props.userToValidate.lastName}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="firstName"
          label="Prénom"
          validation={{
            required: "Veuillez renseigner votre prénom",
          }}
          firstLetterUppercase={true}
          defaultValue={props.userToValidate.firstName}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomEmailField
          name="email"
          label="Email"
          defaultValue={props.userToValidate.email}
          disabled={true}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomPasswordField
          name="password"
          label="Mot de passe"
          validation={{
            minLength: {
              value: 8,
              message: "Le mot de passe doit faire au moins 8 caractères",
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message:
                "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
            },
          }}
        />
      </Grid>
    </CustomForm>
  );
}
