import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { TCustomStripeElementsProps } from "./types/TCustomStripeElementsProps";
import { useEffect, useState } from "react";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY}`
);

export default function CustomStripeElements(
  props: TCustomStripeElementsProps
) {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    stripePromise
      .then((stripeInstance) => {
        setStripe(stripeInstance);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    stripe && (
      <Elements options={props.options} stripe={stripe}>
        <StripeCheckoutForm
          totalPrice={props.totalPrice}
          userRef={props.userRef}
          tipAmount={props.tipAmount}
          amountRemaining={props.amountRemaining}
          table={props.table}
        />
      </Elements>
    )
  );
}
