import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { TCustomButtonProps } from "./types/TCustomButtonProps";

export default function CustomButton(props: TCustomButtonProps) {
  return (
    <Button
      className={
        props.variant === "primary" ? `button-primary` : `button-secondary`
      }
      onClick={props.handleOnClick}
      disabled={props.isLoading}
    >
      {!props.isLoading ? (
        props.buttonText
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}
    </Button>
  );
}
