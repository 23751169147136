import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TOnUpdateMenuItemImageRequest } from "./types/Requests/TOnUpdateMenuItemImageRequest";
import { TOnUpdateMenuItemImageResponse } from "./types/Responses/TOnUpdateMenuItemImageResponse";

export const updateMenuItemImage = createAsyncThunk(
  "menu-item-image/update",
  async (
    request: TOnUpdateMenuItemImageRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TOnUpdateMenuItemImageResponse>(
      dispatch,
      `/api/menu-item-image/update`,
      MethodEnum.PUT,
      request,
      true
    );
    if (response.success) {
      return response.data.menuItemImage;
    } else {
      return rejectWithValue(response);
    }
  }
);
