import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMenuEntity } from "../../../common/Entities/IMenuEntity";
import { TMenuPreviewState } from "./types/TMenuPreviewState";
import { IMenuItemEntity } from "../../../common/Entities/IMenuItemEntity";
import { IMenuSupplementEntity } from "../../../common/Entities/IMenuSupplementEntity";
import { IMenuCategoryEntity } from "../../../common/Entities/IMenuCategoryEntity";
import { IUserEntity } from "../../../common/Entities/IUserEntity";
import { IMenuItemCustomizationOption } from "../../../common/Entities/IMenuItemCustomizationOption";

const initialState: TMenuPreviewState = {
  menu: null,
  userRef: "",
  table: "",
  selectedCategoryId: -1,
  openItemModal: false,
  selectedItem: null,
  selectedCategory: null,
  itemToAdd: null,
  filteredMenu: null,
  paymentAvailable: false,
  authenticatedUser: null,
  availableMenus: [],
  success: false,
  error: false,
  loading: true,
  authenticationFailed: false,
  authenticationSucceeded: false,
  backToMenu: false,
  isSelectCategoryModalOpen: false,
  unfilledCustomizationIds: [],
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<IMenuEntity>) => {
      state.menu = action.payload;
    },
    setBackToMenu: (state, action: PayloadAction<boolean>) => {
      state.backToMenu = action.payload;
    },
    setIsSelectCategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSelectCategoryModalOpen = action.payload;
    },
    setAuthenticationFailed: (state, action: PayloadAction<boolean>) => {
      state.authenticationFailed = action.payload;
    },
    setAuthenticationSucceeded: (state, action: PayloadAction<boolean>) => {
      state.authenticationSucceeded = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setAuthenticatedUser: (state, action: PayloadAction<IUserEntity>) => {
      state.authenticatedUser = action.payload;
    },
    setUserRef: (state, action: PayloadAction<string>) => {
      state.userRef = action.payload;
    },
    setAvailableMenus: (state, action: PayloadAction<IMenuEntity[]>) => {
      state.availableMenus = action.payload;
    },
    setTable: (state, action: PayloadAction<string>) => {
      state.table = action.payload;
    },
    setSelectedCategoryId: (state, action: PayloadAction<number>) => {
      state.selectedCategoryId = action.payload;
    },
    setOpenItemModal: (state, action: PayloadAction<boolean>) => {
      state.openItemModal = action.payload;
    },
    setSelectedItem: (state, action: PayloadAction<IMenuItemEntity>) => {
      state.selectedItem = action.payload;
    },
    setSelectedCategory: (
      state,
      action: PayloadAction<IMenuCategoryEntity>
    ) => {
      state.selectedCategory = action.payload;
    },
    initiateMenuItemToAdd: (state, action: PayloadAction<IMenuItemEntity>) => {
      state.itemToAdd = {
        cartSupplements: [],
        item: action.payload,
        quantity: 1,
        rejectedQuantity: 0,
        cartCustomizations: [],
      };
    },
    addCustomizationOption: (
      state,
      action: PayloadAction<IMenuItemCustomizationOption>
    ) => {
      console.log("Adding customization option...");
      const itemToAdd = state.itemToAdd;
      if (!itemToAdd) return;

      const customization = itemToAdd.cartCustomizations.find(
        (custo) =>
          custo.menuItemCustomizationId ===
          action.payload.menuItemCustomizationId
      );

      // Si la personnalisation n'existe pas, on l'ajoute
      if (!customization) {
        itemToAdd.cartCustomizations.push({
          menuItemCustomizationId: action.payload.menuItemCustomizationId!,
          cartItemOptions: [
            {
              menuItemCustomizationOptionId: action.payload.id!,
              quantity: 1,
            },
          ],
        });
        console.log("Customization did not exist, added new customization.");
      } else {
        // Si la personnalisation existe, on met à jour la quantité de l'option
        const option = customization.cartItemOptions.find(
          (opt) => opt.menuItemCustomizationOptionId === action.payload.id
        );
        if (option) {
          option.quantity += 1;
        } else {
          // Si l'option n'existe pas, on l'ajoute avec une quantité de 1
          customization.cartItemOptions.push({
            menuItemCustomizationOptionId: action.payload.id!,
            quantity: 1,
          });
        }
      }
    },

    addToUnfilledCustomizationIds: (state, action: PayloadAction<number>) => {
      if (!state.unfilledCustomizationIds.find((u) => u === action.payload)) {
        state.unfilledCustomizationIds.push(action.payload);
      }
    },
    removeFromUnfilledCustomizationIds: (
      state,
      action: PayloadAction<number>
    ) => {
      if (state.unfilledCustomizationIds.find((u) => u === action.payload)) {
        state.unfilledCustomizationIds = state.unfilledCustomizationIds.filter(
          (u) => u !== action.payload
        );
      }
    },

    removeCustomizationOption: (
      state,
      action: PayloadAction<IMenuItemCustomizationOption>
    ) => {
      console.log("Removing customization option...");
      const itemToAdd = state.itemToAdd;
      if (!itemToAdd) return;

      const customization = itemToAdd.cartCustomizations.find(
        (custo) =>
          custo.menuItemCustomizationId ===
          action.payload.menuItemCustomizationId
      );

      if (customization) {
        const option = customization.cartItemOptions.find(
          (opt) => opt.menuItemCustomizationOptionId === action.payload.id
        );

        if (option && option.quantity > 0) {
          option.quantity -= 1;
          // Supprimer l'option si la quantité est à zéro
          if (option.quantity === 0) {
            customization.cartItemOptions =
              customization.cartItemOptions.filter(
                (opt) => opt.menuItemCustomizationOptionId !== action.payload.id
              );
          }
        }
        // Supprimer la personnalisation si elle n'a plus d'options
        if (customization.cartItemOptions.length === 0) {
          itemToAdd.cartCustomizations = itemToAdd.cartCustomizations.filter(
            (custo) =>
              custo.menuItemCustomizationId !==
              action.payload.menuItemCustomizationId
          );
        }
      }
      console.log("Updated state:", state.itemToAdd);
    },

    clearItemToAdd: (state) => {
      state.itemToAdd = null;
      state.selectedCategory = null;
      state.selectedItem = null;
    },
    addSupplement: (state, action: PayloadAction<IMenuSupplementEntity>) => {
      let foundSupplement = state.itemToAdd?.cartSupplements?.find(
        (cartSupplement) => cartSupplement.supplement.id === action.payload.id
      );
      if (
        foundSupplement &&
        foundSupplement.quantity < foundSupplement.supplement.maxQuantity
      ) {
        foundSupplement.quantity += 1;
      }

      if (!foundSupplement) {
        state.itemToAdd?.cartSupplements?.push({
          supplement: action.payload,
          quantity: 1,
        });
      }
    },
    removeSupplement: (state, action: PayloadAction<IMenuSupplementEntity>) => {
      let foundSupplement = state.itemToAdd?.cartSupplements?.find(
        (cartSupplement) => cartSupplement.supplement.id === action.payload.id
      );
      if (foundSupplement) {
        if (
          foundSupplement.quantity <= foundSupplement.supplement.maxQuantity &&
          foundSupplement.quantity > 0
        ) {
          foundSupplement.quantity -= 1;
        }
        if (
          foundSupplement.quantity === 0 &&
          state.itemToAdd?.cartSupplements
        ) {
          state.itemToAdd.cartSupplements =
            state.itemToAdd?.cartSupplements?.filter(
              (cartSupplement) =>
                cartSupplement.supplement.id !== action.payload.id
            );
        }
      }
    },
    increaseItemToAddQuantity: (state) => {
      if (state.itemToAdd) {
        state.itemToAdd.quantity += 1;
      }
    },
    lowerItemToAddQuantity: (state) => {
      if (state.itemToAdd?.cartSupplements) {
        if (state.itemToAdd && state.itemToAdd.quantity != 1) {
          state.itemToAdd.quantity -= 1;
        }
      }
    },

    setPaymentAvailable: (state, action: PayloadAction<boolean>) => {
      state.paymentAvailable = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      //Search through menu the name of item that correspond the most to the action payload

      if (state.menu) {
        const deepCopyMenu = {
          ...state.menu,
          categories: state.menu.categories.map((category) => ({
            ...category,
            itemList: category.itemList.map((item) => ({ ...item })),
          })),
        };
        const filteredCategories = deepCopyMenu.categories.map((category) => {
          if (
            category.itemList.some((item) =>
              item.name.toLowerCase().includes(action.payload.toLowerCase())
            )
          ) {
            category.itemList = category.itemList.filter((item) =>
              item.name.toLowerCase().includes(action.payload.toLowerCase())
            );
            return category;
          }
          return null;
        });
        const final = filteredCategories.filter(
          (category): category is IMenuCategoryEntity => category !== null
        );

        state.filteredMenu =
          action.payload === "" ? null : { ...state.menu!, categories: final };
      }
    },
  },
});

export const menuPreviewReducer = slice.reducer;

export const menuPreviewActions = slice.actions;
