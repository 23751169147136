import { IIAMTokenEntity } from "../../../common/Entities/IIAMTokenEntity";
import { IRestaurantUserEntity } from "../../../common/Entities/IRestaurantUserEntity";
import { appActions } from "../../../common/store/appSlice";
import { useAppDispatch } from "../../../common/store/redux";
import useAuthRepository from "../../Auth/repository/useAuthRepository";
import { TAuthenticateUserRequest } from "../../Auth/services/types/Requests/TAuthenticateUserRequest";
import {
  createIAM,
  deleteIAM,
  findAllIAM,
  generateNewChefPassword,
  updateIAMRole,
  validateIAM,
} from "../services/iamService";
import { TCreateIAMRequest } from "../services/types/Requests/TCreateIAMRequest";
import { TDeleteIAMRequest } from "../services/types/Requests/TDeleteIAMRequest";
import { TFindAllIAMRequest } from "../services/types/Requests/TFindAllIAMRequest";
import { TGenerateChefPasswordRequest } from "../services/types/Requests/TGenerateChefPasswordRequest";
import { TUpdateIAMRoleRequest } from "../services/types/Requests/TUpdateIAMRoleRequest";
import { TValidateIAMRequest } from "../services/types/Requests/TValidateIAMRequest";
import { iamActions } from "../slices/iamSlices";

export default function useIAMRepository() {
  const dispatch = useAppDispatch();
  const { onAuthenticateUser } = useAuthRepository();

  const onFindAllIAM = async (
    request: TFindAllIAMRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(findAllIAM(request));

    if (findAllIAM.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const iamList = resultAction.payload.iams;

    const validUsers = iamList.filter(
      (iam) => iam.emailValidated === true
    ) as IRestaurantUserEntity[];
    const awaitingUsers = iamList.filter(
      (iam) => iam.emailValidated === false
    ) as IRestaurantUserEntity[];

    dispatch(iamActions.setValidUsers(validUsers));
    dispatch(iamActions.setAwaitingUsers(awaitingUsers));
    return true;
  };

  const onCreateIAM = async (request: TCreateIAMRequest): Promise<boolean> => {
    const resultAction = await dispatch(createIAM(request));

    if (createIAM.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const createdIAM = resultAction.payload.iam as IRestaurantUserEntity;
    dispatch(iamActions.addToAwaitingUser(createdIAM));
    return true;
  };

  const onDeleteIAM = async (request: TDeleteIAMRequest): Promise<boolean> => {
    const resultAction = await dispatch(deleteIAM(request));

    if (deleteIAM.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(iamActions.deleteAwaitingUser(request.userId));
    dispatch(iamActions.deleteValidUser(request.userId));
    return true;
  };

  const onUpdateIAMRole = async (
    request: TUpdateIAMRoleRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(updateIAMRole(request));

    if (updateIAMRole.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(
      iamActions.updateValidUserRole({
        userId: request.userId,
        newRole: request.newRole,
      })
    );
    return true;
  };

  const onValidateIAM = async (
    request: TValidateIAMRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(validateIAM(request));

    if (validateIAM.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    return true;
  };

  const onGenerateNewChefPassword = async (
    request: TGenerateChefPasswordRequest
  ): Promise<boolean> => {
    const resultAction = await dispatch(generateNewChefPassword(request));

    if (validateIAM.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(
      appActions.setSuccessMessage(
        "Les identifiants ont été créés avec succès. Vous les reçevrez par email dans quelques instants..."
      )
    );
    return true;
  };

  const onFindUserToValidate = async (
    request: TAuthenticateUserRequest
  ): Promise<boolean> => {
    const authenticatedUser = await onAuthenticateUser(request);
    if (!authenticatedUser) {
      dispatch(iamActions.setInvalidUserRef(true));
      return false;
    }

    dispatch(iamActions.setUserToValidate(authenticatedUser));
    return true;
  };
  return {
    onUpdateIAMRole,
    onDeleteIAM,
    onCreateIAM,
    onFindAllIAM,
    onValidateIAM,
    onFindUserToValidate,
    onGenerateNewChefPassword,
  };
}
