import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICartEntity } from "../../../common/Entities/ICartEntity";
import { TKitchenSliceState } from "./types/TKitchenSliceState";

const initialState: TKitchenSliceState = {
  kitchenCarts: null,
};

const kitchenSlice = createSlice({
  name: "kitchen",
  initialState,
  reducers: {
    setKitchenCarts: (state, action: PayloadAction<ICartEntity[]>) => {
      state.kitchenCarts = action.payload;
    },
    addKitchenCart: (state, action: PayloadAction<ICartEntity>) => {
      state.kitchenCarts && state.kitchenCarts.push(action.payload);
    },
    removeKitchenCart: (state, action: PayloadAction<{ cartId: number }>) => {
      const cartId = action.payload.cartId;
      if (state.kitchenCarts) {
        state.kitchenCarts = state.kitchenCarts.filter(
          (cart) => cart.id !== cartId
        );
      }
    },
    closeCart: (state, action: PayloadAction<{ cartId: number }>) => {
      const cartId = action.payload.cartId;
      if (state.kitchenCarts) {
        state.kitchenCarts = state.kitchenCarts.map((cart) =>
          cart.id === cartId ? { ...cart, status: "PAID" } : cart
        );
      }
    },
    cancelCart: (state, action: PayloadAction<{ cartId: number }>) => {
      const cartId = action.payload.cartId;
      if (state.kitchenCarts) {
        state.kitchenCarts = state.kitchenCarts.map((cart) =>
          cart.id === cartId ? { ...cart, status: "CANCELED" } : cart
        );
      }
    },
    updateCartList: (state, action: PayloadAction<{ cart: ICartEntity }>) => {
      if (state.kitchenCarts) {
        state.kitchenCarts = state.kitchenCarts.concat(action.payload.cart);
      }
    },

    rejectKitchenCartItem: (
      state,
      action: PayloadAction<{
        cartId: number;
        cartItemId: number;
      }>
    ) => {
      const { cartId, cartItemId } = action.payload;
      if (state.kitchenCarts) {
        state.kitchenCarts = state.kitchenCarts
          .map((cart) => {
            if (cart.id === cartId) {
              return {
                ...cart,
                categories: cart.categories
                  .map((category) => {
                    return {
                      ...category,
                      items: category.items
                        .map((item) =>
                          item.id === cartItemId
                            ? {
                                ...item,
                                rejectedQuantity: item.rejectedQuantity + 1,
                              }
                            : item
                        )
                        .filter(
                          (item) => item.quantity - item.rejectedQuantity > 0
                        ),
                    };
                    return category;
                  })
                  .filter((category) => category.items.length > 0), // Remove empty categories
              };
            }
            return cart;
          })
          .filter((cart) => cart.categories.length > 0); // Remove carts with no categories
      }
    },
  },
});

export const kitchenReducer = kitchenSlice.reducer;
export const kitchenActions = kitchenSlice.actions;
