import { AddCircleRounded } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { TAddTipButtonsProps } from "./types/TAddTipButtonsProps";
import { useAppDispatch } from "../../../../../common/store/redux";
import { cartActions } from "../../../slices/cartSlice";

export default function AddTipButton(props: TAddTipButtonsProps) {
  const dispatch = useAppDispatch();
  const [selectedTip, setSelectedTip] = useState(-1);
  const handleAddCustomTip = () => {
    dispatch(cartActions.setOpenAddCustomTipPopup(true));
  };
  const handleSelectTip = (index: number, value: number) => {
    if (index === selectedTip) {
      setSelectedTip(-1);
      dispatch(cartActions.setTip(0));
    } else {
      setSelectedTip(index);
      dispatch(cartActions.setTip(value));
    }
  };
  return (
    <>
      {props.tips.map((tip, index) => {
        return (
          <Grid margin={1}>
            <Button
              className={
                index === selectedTip ? "tip-button-selected" : "tip-button"
              }
              onClick={() => {
                handleSelectTip(index, parseFloat(tip));
              }}
            >
              {tip} €
            </Button>
          </Grid>
        );
      })}

      <Grid margin={1} className="grid-center" marginLeft={2}>
        <AddCircleRounded
          style={{ color: "#062e4b" }}
          onClick={handleAddCustomTip}
        />
      </Grid>
    </>
  );
}
