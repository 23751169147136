import { createAsyncThunk } from "@reduxjs/toolkit";
import { TSubscribeRequest } from "./types/Requests/TSubscribeRequest";
import { TSubscribeResponse } from "./types/Responses/TSubscribeResponse";
import { apiRequest } from "../../temporary/api";
import { MethodEnum } from "../../enums/MethodEnum";
import { TFindAllNotificationsResponse } from "./types/Responses/TFindAllNotificationsResponse";

export const subscribeToNotifications = createAsyncThunk(
  "web-push/subscribe",
  async (request: TSubscribeRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TSubscribeResponse>(
      dispatch,
      "/api/web-push/subscribe",
      MethodEnum.POST,
      request,
      false
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const findAllNotifications = createAsyncThunk(
  "notification/find-all",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindAllNotificationsResponse>(
      dispatch,
      "/api/notification/find-all",
      MethodEnum.GET,
      null,
      false
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
