import CancelIcon from "@mui/icons-material/Cancel";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TDeleteSupplementIcon } from "./types/TDeleteSupplementIcon";
import { supplementActions } from "../../slices/supplementSlice";

export default function DeleteSupplementIcon(props: TDeleteSupplementIcon) {
  const dispatch = useAppDispatch();
  const handleOnDeleteSupplementIcon = () => {
    dispatch(supplementActions.removeSupplementFromList(props.supplementIndex));
    props.setHasSupplementsChanged(true);
  };
  return (
    <div>
      <CancelIcon
        onClick={handleOnDeleteSupplementIcon}
        sx={{ cursor: "pointer", color: "#273551" }}
      />
    </div>
  );
}
