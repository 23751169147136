import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { TCustomTimePickerProps } from "./types/TCustomTimePickerProps";

export default function CustomTimePicker(props: TCustomTimePickerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={props.defaultValue && dayjs(props.defaultValue)}
      rules={props.validation}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          {...field}
          label={props.label}
          slotProps={{
            textField: {
              error: !!error,
              fullWidth: true,
            },
          }}
        />
      )}
    ></Controller>
  );
}
