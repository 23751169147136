import { Grid, Typography } from "@mui/material";
import React from "react";
import { TSettingsBoxItemProps } from "./types/TSettingsBoxItemProps";

export default function SettingsBoxItem(props: TSettingsBoxItemProps) {
  return (
    <Grid
      container
      xs={12}
      md={12}
      lg={12}
      xl={12}
      justifyContent={"space-between"}
    >
      <Grid item>
        <Typography
          sx={{
            color: "black",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            color: "#091E42",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {props.value}{" "}
          {props.subValue && (
            <span style={{ fontSize: "8px", color: "#757575" }}>
              ({props.subValue})
            </span>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}
