import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TCreateMenuSupplementRequest } from "./types/Requests/TCreateMenuSupplementRequest";
import { TDeleteMenuSupplementRequest } from "./types/Requests/TDeleteMenuSupplementRequest";
import { TGetAllMenuSupplementRequest } from "./types/Requests/TGetAllMenuSupplementRequest";
import { TGetMenuSupplementRequest } from "./types/Requests/TGetMenuSupplementRequest";
import { TUpdateMenuSupplementRequest } from "./types/Requests/TUpdateMenuSupplementRequest";
import { TCreateMenuSupplementResponse } from "./types/Responses/TCreateMenuSupplementResponse";
import { TGetAllMenuSupplementResponse } from "./types/Responses/TGetAllMenuSupplementResponse";
import { TGetMenuSupplementResponse } from "./types/Responses/TGetMenuSupplementResponse";
import { TUpdateMenuSupplementsResponse } from "./types/Responses/TUpdateMenuSupplementsResponse";
import { TUpdateMenuSupplementFromListRequest } from "./types/Requests/TUpdateMenuSupplementFromListRequest";

export const getMenuSupplement = createAsyncThunk(
  "menu-supplement/find",
  async (request: TGetMenuSupplementRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetMenuSupplementResponse>(
      dispatch,
      `/api/menu-supplement/find/${request.supplementId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
    } else {
      return rejectWithValue(response);
    }
  }
);

export const getAllMenuSupplements = createAsyncThunk(
  "menu-supplement/find",
  async (
    request: TGetAllMenuSupplementRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TGetAllMenuSupplementResponse>(
      dispatch,
      `/api/menu-supplement/find-all/${request.itemId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const deleteMenuSupplements = createAsyncThunk(
  "menu-supplement/delete",
  async (
    request: TDeleteMenuSupplementRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      `/api/menu-supplement/delete`,
      MethodEnum.DELETE,
      request
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenuSupplements = createAsyncThunk(
  "menu-supplement/update",
  async (
    request: TUpdateMenuSupplementRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TUpdateMenuSupplementsResponse>(
      dispatch,
      `/api/menu-supplement/update`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      return response.data.supplement;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenuSupplementFromList = createAsyncThunk(
  "menu-supplement/update-from-list",
  async (
    request: TUpdateMenuSupplementFromListRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      `/api/menu-supplement/update-from-list`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      return request.newList;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const createMenuSupplement = createAsyncThunk(
  "menu-supplement/create",
  async (
    request: TCreateMenuSupplementRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TCreateMenuSupplementResponse>(
      dispatch,
      `/api/menu-supplement/create`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data.supplement;
    } else {
      return rejectWithValue(response);
    }
  }
);
