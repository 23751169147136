import { Button, Grid } from "@mui/material";
import React from "react";
import { TUpdateDefaultMenuProps } from "./types/TUpdateDefaultMenuProps";
import { MenuTimeframeCalculator } from "../../../../../../common/Entities/MenuTimeframeCalculator";
import { useAppDispatch } from "../../../../../../common/store/redux";
import useMenuRepository from "../../repository/useMenuRepository";

export default function UpdateDefaultMenu(props: TUpdateDefaultMenuProps) {
  const dispatch = useAppDispatch();
  const { onUpdateMenu } = useMenuRepository();
  const menuTimeFramCalculator = new MenuTimeframeCalculator(
    props.selectedMenu
  );
  const morningMenu = props.menuList.find(
    (menu) => menu.menuType.type === "MORNING"
  );
  const updateDefaultMenuTimeframe = async (
    timeframe: "ALL_DAY" | "AFTER_MORNING_MENU"
  ) => {
    switch (timeframe) {
      case "ALL_DAY": {
        await onUpdateMenu({
          menu: { ...props.selectedMenu, activeFrom: null, activeTo: null },
        });
        props.onClose();
        break;
      }
      case "AFTER_MORNING_MENU": {
        if (morningMenu) {
          await onUpdateMenu({
            menu: {
              ...props.selectedMenu,
              activeFrom: morningMenu.activeTo,
              activeTo: morningMenu.activeFrom,
            },
          });
          props.onClose();
          break;
        }
      }
    }
  };

  return (
    <div>
      <>
        <Grid item xs={12} m={2}>
          <Grid item pb={2}>
            <Button
              className="popup-button-blue"
              onClick={() => {
                updateDefaultMenuTimeframe("ALL_DAY");
              }}
              disabled={menuTimeFramCalculator.isAvailableAllDay()}
            >
              Activer toute la journée
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="popup-button-blue"
              onClick={() => {
                updateDefaultMenuTimeframe("AFTER_MORNING_MENU");
              }}
              disabled={
                morningMenu
                  ? menuTimeFramCalculator.startAtSameTimeThan(morningMenu)
                  : true
              }
            >
              Activer après le menu matin
            </Button>
          </Grid>
        </Grid>
      </>
    </div>
  );
}
