import { Grid } from "@mui/material";
import React from "react";
import CategoryCarousel from "../Caroussel/CategoryCaroussel";
import GradientDivider from "../../../../common/containers/GradientDivider/GradientDivider";
import SearchBar from "../SearchBar/SearchBar";
import { TStickyHeaderProps } from "./types/TStickyHeaderProps";
import ReturnToPaymentButton from "../ReturnToPaymentButton/ReturnToPaymentButton";

export default function StickyHeader(props: TStickyHeaderProps) {
  return (
    <Grid
      container
      xs={12}
      sx={{
        whiteSpace: "nowrap",
        backgroundColor: "white",
        position: "sticky",
        zIndex: 1000,
        top: 0, // Ensure it sticks to the top
        overflowX: "hidden", // Prevent horizontal
        marginBottom: "50px",
      }}
    >
      <Grid item xs={12} sx={{ overflowX: "hidden", overflowY: "hidden" }}>
        <Grid xs={12} item mt={2}>
          <CategoryCarousel categories={props.menu.categories} />
        </Grid>
        <Grid item xs={12} mt={1}>
          <GradientDivider />
        </Grid>
        <Grid item container>
          <Grid item container justifyContent={"start"} xs={6}>
            <ReturnToPaymentButton
              userRef={props.userRef}
              table={props.table}
              menuId={props.menu.id!}
            />
          </Grid>

          <Grid item container justifyContent={"end"} xs={6} mb={1}>
            <Grid item xs={10} mr={1}>
              <SearchBar />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
