import { MoreVertOutlined } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Menu, Typography } from "@mui/material";
import React, { useState } from "react";
import { TMoreMenuProps } from "./types/TMoreMenuProps";
import useMenuItemCustomizationRepository from "../../repository/useMenuItemCustomizationRepository";
import UpdateMenuItemCustomizationModal from "../UpdateMenuItemCustomizationModal/UpdateMenuItemCustomizationModal";

export default function MoreMenu(props: TMoreMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const { onDeleteMenuItemCustomization } =
    useMenuItemCustomizationRepository();

  const handleDeleteCustomization = () => {
    onDeleteMenuItemCustomization({
      customizationIndex: props.customizationIndex,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUpdateCustomizationModal = () => {
    setIsUpdateModalOpen(true);
  };

  return (
    <>
      <UpdateMenuItemCustomizationModal
        menuItemCustomization={props.menuItemCustomization}
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        custoIndex={props.customizationIndex}
      />
      <div onClick={handleClick}>
        <MoreVertOutlined sx={{ paddingTop: "5px", cursor: "pointer" }} />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Grid
            container
            direction="column"
            spacing={1}
            justifyContent={"center"}
          >
            <Grid item>
              <Button
                sx={{ color: "#ba000d", width: "100%" }}
                onClick={handleDeleteCustomization}
              >
                Supprimer
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ width: "100%" }}
                onClick={handleOpenUpdateCustomizationModal}
              >
                Modifier
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
}
