import { Checkbox, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { TPickSupplementProps } from "./types/TPickSupplementProps";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";

export default function PickSupplement(props: TPickSupplementProps) {
  const itemToAdd = useAppSelector((state) => state.menuPreview.itemToAdd);
  const calculateAddedSupplementQuantity = () => {
    const foundSupplement = itemToAdd?.cartSupplements?.find(
      (supplement) => supplement.supplement.id === props.supplement.id
    );

    return foundSupplement?.quantity || 0;
  };
  const dispatch = useAppDispatch();
  const handleAddSupplement = () => {
    dispatch(menuPreviewActions.addSupplement(props.supplement));
  };
  const handleRemoveSupplement = () => {
    dispatch(menuPreviewActions.removeSupplement(props.supplement));
  };
  return (
    <Grid
      container
      xs={6}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Grid m={1}>
        <RemoveCircleRounded onClick={handleRemoveSupplement} />
      </Grid>
      <Grid container sx={{ maxWidth: "50%" }}>
        <Grid xs={12}>
          <Typography
            textAlign={"center"}
            sx={{ wordBreak: "break-word", color: "black" }}
          >
            {props.supplement.name}({calculateAddedSupplementQuantity()})
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            textAlign={"center"}
            className="listItem-heading-medium-secondary"
          >
            {props.supplement.price}€
          </Typography>
        </Grid>
      </Grid>
      <Grid m={1}>
        <AddCircleRounded onClick={handleAddSupplement} />
      </Grid>
    </Grid>
  );
}
