import React, { useEffect, useState } from "react";
import { TCreateIAMFieldsProps } from "./types/TCreateIAMFieldsProps";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import SelectRoleField from "./SelectRoleField";
import CustomTextField from "../../../../common/containers/Form/CustomTextFields";
import CustomEmailField from "../../../Auth/containers/Form/CustomEmailField";
import { IUserRoleEntity } from "../../../../common/Entities/IRoleEntity";

export default function CreateIAMFields(props: TCreateIAMFieldsProps) {
  const { watch, setValue } = useFormContext();
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false);

  const selectedRole: IUserRoleEntity = watch("role");

  useEffect(() => {
    if (selectedRole) {
      console.log("selectedRole", selectedRole);
      if (selectedRole.name === "CHEF") {
        setValue("email", props.rootUserEmail);
        setEmailDisabled(true);
      } else {
        setEmailDisabled(false);
      }
    }
  }, [selectedRole]);
  return (
    <>
      <Grid item mb={4}>
        <SelectRoleField
          name="role"
          label="Choisir un rôle"
          placeholder="Choisir un rôle"
          roles={props.roles}
          validation={{
            required: "Veuillez choisir un rôle",
          }}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="lastName"
          label="Nom"
          validation={{
            required: "Veuillez renseigner le nom de l'utilisateur",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="firstName"
          label="Prénom"
          validation={{
            required: "Veuillez renseigner le prénom de l'utilisateur",
          }}
          firstLetterUppercase={true}
        />
      </Grid>

      <Grid item mb={4}>
        <CustomEmailField name="email" label="Email" disabled={emailDisabled} />
      </Grid>
    </>
  );
}
