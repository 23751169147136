import { IMenuAllergenEntity } from "../../../../../common/Entities/IMenuAllergenEntity";
import { useAppDispatch } from "../../../../../common/store/redux";
import { getAllMenuAllergen } from "../services/menuAllergenService";
import { menuAllergenActions } from "../slices/menuAllergen";

export default function useMenuAllergenRepository() {
  const dispatch = useAppDispatch();

  async function onGetAllMenuAllergen(): Promise<IMenuAllergenEntity[]> {
    const resultAction = await dispatch(getAllMenuAllergen());

    if (getAllMenuAllergen.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const menuAllergenList = resultAction.payload.menuAllergens;
    dispatch(menuAllergenActions.setMenuAllergenList(menuAllergenList));
    return menuAllergenList;
  }
  return {
    onGetAllMenuAllergen,
  };
}
