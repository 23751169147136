import { Typography } from "@mui/material";
import { ICartItemEntity } from "../../../../common/Entities/ICartItemEntity";
import { TCartCategoryPriceProps } from "./types/TCartCategoryPriceProps";

export default function CartItemPrice(props: TCartCategoryPriceProps) {
  const calculateTotalCategoryPrice = (
    cartItems: ICartItemEntity[]
  ): number => {
    const totalPrice = parseFloat(
      cartItems
        .reduce((itemAccumulator, itemCurrentVal) => {
          let totalSupplement =
            itemCurrentVal.cartSupplements?.reduce(
              (supAccumulator, supCurrent) => {
                return (
                  supAccumulator +
                  supCurrent.supplement.price * supCurrent.quantity
                );
              },
              0
            ) || 0;
          return (
            itemAccumulator +
            (itemCurrentVal.item.price + totalSupplement) *
              itemCurrentVal.quantity
          );
        }, 0)
        .toFixed(2)
    );
    return totalPrice;
  };
  return (
    <Typography
      className={true ? `priceTag-medium-green` : `priceTag-medium-grey`}
    >
      {calculateTotalCategoryPrice(props.cartCategory.items)} €
    </Typography>
  );
}
