import { Grid, Typography } from "@mui/material";
import manageIcon from "../../../../common/images/stripe_logo.png";
import { useAppDispatch } from "../../../../common/store/redux";
import {
  createConnectAccount,
  getUpdateStripeAccoutLink,
} from "../../../Account/services/stripeAccountService";
import { TDashboardStripeAccountButtonProps } from "./types/TDashboardStripeAccountButtonProps";

export default function DashboardStripeAccountButton(
  props: TDashboardStripeAccountButtonProps
) {
  const dispatch = useAppDispatch();

  const generateConnectAccountLink = () => {
    props.accountCreated
      ? dispatch(getUpdateStripeAccoutLink())
      : dispatch(createConnectAccount({ country: "FR" }));
  };

  return (
    <Grid
      container
      width={"350px"}
      height={"151px"}
      p={2}
      onClick={() => {
        generateConnectAccountLink();
      }}
      sx={{
        cursor: "pointer",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
      }}
    >
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="grid-center">
        <img
          src={manageIcon}
          style={{
            width: "149px",
            height: "84px",
          }}
          alt="Manage Icon"
        ></img>
      </Grid>
      <Grid
        item
        container
        xs={8}
        sm={8}
        md={8}
        lg={8}
        xl={8}
        pl={4}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography sx={{ fontSize: "14px", color: "#ACACAC" }}>
            {!props.accountCreated
              ? "Créer votre compte connecté Stripe pour débloquer vos statistiques"
              : "Complétez votre profil pour activer toutes les fonctionnalitées"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
