import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../../../common/containers/styles/customFonts.css";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import MenuCategoryNavigationBar from "../../../category/containers/MenuCategoryNavigationBar/MenuCategoryNavigationBar";
import MenuItemList from "../../../item/container/MenuItemList/MenuItemList";
import { itemActions } from "../../../item/slice/itemSlice";
import { TSelectMenuPageProps } from "./types/TSelectMenuPageProps";
export default function SelectedMenuPage(props: TSelectMenuPageProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedMenu, setSelectedMenu] = useState<IMenuEntity | null>(null);
  const [selectedMenuCategory, setSelectedMenuCategory] =
    useState<IMenuCategoryEntity | null>(null);

  const { menuId } = useParams();

  useEffect(() => {
    if (menuId) {
      const foundMenu = props.menuList.find(
        (menu) => menu.id === Number(menuId)
      );
      foundMenu && setSelectedMenu(foundMenu);
    }

    if (
      selectedMenu &&
      !selectedMenuCategory &&
      selectedMenu.categories.length > 0
    ) {
      setSelectedMenuCategory(selectedMenu.categories[0]);
    }
  }, [JSON.stringify(props.menuList)]);

  useEffect(() => {
    if (selectedMenu) {
      if (selectedMenu.categories.length > 0) {
        setSelectedMenuCategory(selectedMenu.categories[0]);
      } else {
        setSelectedMenuCategory(null);
      }
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (!menuId) {
      navigate("/dashboard/menus");
    }
  }, []);

  const handleOpenCreateMenuItemModal = () => {
    dispatch(itemActions.setIsCreateMenuItemModalOpen(true));
  };

  return selectedMenu ? (
    <>
      <Grid container pb={10}>
        <MenuCategoryNavigationBar
          selectedMenu={selectedMenu}
          selectedCategory={selectedMenuCategory}
          setSelectedMenuCategory={setSelectedMenuCategory}
        />
        {selectedMenuCategory && selectedMenuCategory.itemList.length > 0 && (
          <Grid item mt={2} ml={3}>
            <Button
              onClick={handleOpenCreateMenuItemModal}
              className="font-avenir"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor: "#273551",
                color: "white",
                borderRadius: "25px ",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#273551",
                },
              }}
            >
              Ajouter un article
            </Button>
          </Grid>
        )}
        {selectedMenuCategory && (
          <MenuItemList
            selectedCategory={selectedMenuCategory}
            selectedMenu={selectedMenu}
          />
        )}
      </Grid>
    </>
  ) : (
    <></>
  );
}
