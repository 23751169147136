import { Grid } from "@mui/material";
import CartItem from "../CartItem/CartItem";
import CartItemPrice from "./CartItemPrice";
import { TCartItemNoCategoryProps } from "./types/TCartItemNoCategoryProps";
import { calculateCartItemPrice } from "../../../../utils/cart/shared";

export default function CartItemNoCategory(props: TCartItemNoCategoryProps) {
  const getItemPrice = (): number => {
    if (props.rejected) {
      return calculateCartItemPrice({
        ...props.item,
        quantity: props.item.quantity - props.item.rejectedQuantity,
      });
    }
    if (props.iconType === "checkbox") {
      return calculateCartItemPrice({ ...props.item, quantity: 1 });
    }
    return calculateCartItemPrice({
      ...props.item,
      quantity: props.item.quantity - props.item.rejectedQuantity,
    });
  };
  return (
    <>
      {props.iconType === "checkbox" ? (
        Array.from({ length: props.item.quantity }).map((_, i) => (
          <Grid container key={i}>
            <Grid item xs={9}>
              <CartItem
                fontType="item"
                cartIndexes={{
                  categoryIndex: props.categoryIndex,
                  itemIndex: props.itemIndex,
                }}
                cartItem={props.item}
                iconType={props.iconType}
                showQuantityBox={props.showQuantityBox}
              />
            </Grid>
            <Grid container item xs={3} className="grid-center">
              <Grid item>
                <CartItemPrice
                  iconType={props.iconType}
                  price={getItemPrice()}
                />
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid container>
          <Grid item xs={9}>
            <CartItem
              fontType={`${props.rejected ? "rejected" : "item"}`}
              cartIndexes={{
                categoryIndex: props.categoryIndex,
                itemIndex: props.itemIndex,
              }}
              cartItem={props.item}
              iconType={props.iconType}
              showQuantityBox={props.showQuantityBox}
              cartItemQuantity={
                props.rejected
                  ? props.item.rejectedQuantity
                  : props.item.quantity - props.item.rejectedQuantity
              }
              rejected={props.rejected}
            />
          </Grid>
          <Grid container item xs={3} className="grid-center">
            <Grid item>
              <CartItemPrice
                iconType={props.iconType}
                price={getItemPrice()}
                rejected={props.rejected}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
