import { Grid } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import CustomNumberField from "../../../../../../common/containers/Form/CustomNumberField";
import CustomTextField from "../../../../../../common/containers/Form/CustomTextFields";
import { useAppDispatch } from "../../../../../../common/store/redux";
import useMenuItemRepository from "../../repository/useMenuItemRepository";
import { TCreateMenuItemFormAttributes } from "./types/TCreateMenuItemFormAttributes";
import { TCreateMenuItemFormProps } from "./types/TCreateMenuItemFormProps";

export default function CreateMenuItemForm(props: TCreateMenuItemFormProps) {
  const { onCreateMenuItem } = useMenuItemRepository({
    selectedMenu: props.selectedMenu,
    selectedCategory: props.selectedCategory,
  });
  const dispatch = useAppDispatch();

  const submitHandler: SubmitHandler<TCreateMenuItemFormAttributes> = async (
    data
  ) => {
    return await onCreateMenuItem({
      name: data.name,
      description: data.description,
      price: data.price,
      categoryId: props.selectedCategory.id!,
    });
  };
  const successHandler = () => {
    props.onClose();
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Créer un article"
      onSuccess={successHandler}
      name="create-menu-item-form"
      defaultErrorDisplayCollapse={true}
    >
      <Grid item mb={4}>
        <CustomTextField
          name="name"
          label="Nom"
          validation={{
            required: "Veuillez renseigner le nom de votre article",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="description"
          label="Description"
          validation={{
            required: "Veuillez renseigner la description de votre article",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomNumberField
          name="price"
          label="Prix"
          validation={{
            required: "Veuillez renseigner le prix de votre atricle",
          }}
          currency="€"
        />
      </Grid>
    </CustomForm>
  );
}
