import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TCartRecapStateBoxProps } from "./types/TCartRecapStateBoxProps";

export type TCartRecapStateBoxAttributes = {
  backgroundColor: string;
  text: string;
};
export const boxStates = {
  PENDING: {
    backgroundColor: "#273551",
    text: "En préparation",
  },
  CANCELED: {
    backgroundColor: "#F80000",
    text: "Rejetté",
  },
  PAID: {
    backgroundColor: "#00AC4F",
    text: "Payé",
  },
  READY: {
    backgroundColor: "#00AC4F",
    text: "Bon appétit",
  },
};

export default function CartRecapStateBox(props: TCartRecapStateBoxProps) {
  const defaultBox: TCartRecapStateBoxAttributes = {
    backgroundColor: "#273551",
    text: "En préparation",
  };
  const [boxState, setBoxState] =
    useState<TCartRecapStateBoxAttributes>(defaultBox);
  useEffect(() => {
    switch (props.cartState) {
      case "PENDING": {
        setBoxState(boxStates.PENDING);
        break;
      }
      case "CANCELED": {
        setBoxState(boxStates.CANCELED);
        break;
      }
      case "PAID": {
        setBoxState(boxStates.PAID);
        break;
      }
      case "READY":
      default:
        setBoxState(boxStates.READY);
        break;
    }
  }, [props.cartState]);
  return (
    <Grid
      item
      sx={{
        backgroundColor: boxState.backgroundColor,
        height: "22px",
        padding: "4px",
        alignItems: "center",
        display: "flex",
        borderRadius: "6px",
      }}
    >
      <Typography sx={{ fontSize: "13px", fontWeight: "500", color: "white" }}>
        {boxState.text}
      </Typography>
    </Grid>
  );
}
