import { Grid } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import { IUserRoleEntity } from "../../../../common/Entities/IRoleEntity";
import { useAppDispatch } from "../../../../common/store/redux";
import SelectRoleField from "./SelectRoleField";
import { TUpdateIAMFormAttributes } from "./types/TUpdateIAMFormAttributes";
import { TUpdateIAMFormProps } from "./types/TUpdateIAMFormProps";
import useIAMRepository from "../../respository/useIAMRepository";
import UpdateIAMFields from "./UpdateIAMFields";
import { useState } from "react";

export default function UpdateIAMForm(props: TUpdateIAMFormProps) {
  const dispatch = useAppDispatch();
  const { onUpdateIAMRole } = useIAMRepository();

  const submitHandler: SubmitHandler<TUpdateIAMFormAttributes> = async (
    data
  ) => {
    const foundRole: IUserRoleEntity | undefined = props.roles.find(
      (role) => role.id === data.role.id
    );
    return await onUpdateIAMRole({ userId: props.userId, newRole: foundRole! });
  };
  const successHandler = () => {
    props.handleCloseModal();
  };

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true);

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Modifier le rôle"
      onSuccess={successHandler}
      name="create-iam-form"
      defaultErrorDisplayCollapse={true}
      submitDisabled={isFormDisabled}
    >
      <UpdateIAMFields
        previousRole={props.previousRole}
        roles={props.roles}
        setIsFormDisabled={setIsFormDisabled}
      />
    </CustomForm>
  );
}
