import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";
import { useEffect } from "react";
import { TCartItemDeleteIconProps } from "./types/TCartItemDeleteIconProps";
export const CartItemDeleteIcon = (props: TCartItemDeleteIconProps) => {
  const dispatch = useAppDispatch();
  const handleIconButtonClick = () => {
    dispatch(
      cartActions.setSelectedCurrentCartItem({
        cartIndexes: props.cartIndexes,
        selectState: false,
      })
    );
    dispatch(cartActions.setOpenDeletePopup(true));
  };
  return (
    <div>
      <IconButton aria-label="delete" onClick={handleIconButtonClick}>
        <DeleteIcon sx={{ color: "black" }} />
      </IconButton>
    </div>
  );
};
