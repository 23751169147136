import React, { useState } from "react";
import CloseCartModal from "../Modal/CloseCartModal";
import EditCartModal from "../Modal/EditCartModal";
import RejectCartModal from "../Modal/RejectCartModal";
import KitchenTicket from "./KitchenTicket";
import { TKitchenTicketContainerProps } from "./types/TKitchenTicketContainerProps";

const KitchenTicketContainer: React.FC<TKitchenTicketContainerProps> = (
  props
) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false);

  const handleOpenRejectModal = () => setIsRejectModalOpen(true);
  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleOpenCloseModal = () => setIsCloseModalOpen(true);

  return (
    <>
      <CloseCartModal
        setIsOpen={setIsCloseModalOpen}
        isOpen={isCloseModalOpen}
        cart={props.cart}
      />
      <EditCartModal
        setIsOpen={setIsEditModalOpen}
        isOpen={isEditModalOpen}
        cart={props.cart}
      />
      <RejectCartModal
        setIsOpen={setIsRejectModalOpen}
        isOpen={isRejectModalOpen}
        cart={props.cart}
      />
      {props.cart.status === "PENDING" && (
        <KitchenTicket
          cart={props.cart}
          handleOpenRejectModal={handleOpenRejectModal}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenCloseModal={handleOpenCloseModal}
        />
      )}
    </>
  );
};

export default KitchenTicketContainer;
