import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import GradientDivider from "../../../../common/containers/GradientDivider/GradientDivider";
import { useAppSelector } from "../../../../common/store/redux";
import { IRestaurantUserEntity } from "../../../../common/Entities/IRestaurantUserEntity";
import { useNavigate } from "react-router-dom";
import CurvedBackground from "../../../../common/images/curved_background.png";
import WaiterIcon from "../../../../common/images/waiter_icon.png";
import WhiteLogo from "../../../../common/images/white_logo.png";

export default function MenuSelection() {
  const navigate = useNavigate();
  const authenticatedUser = useAppSelector(
    (state) => state.menuPreview.authenticatedUser
  );
  const availableMenus = useAppSelector(
    (state) => state.menuPreview.availableMenus
  );
  useEffect(() => {
    if (availableMenus.length === 1) {
      navigate(
        `/customerMenu/scan?userRef=${authenticatedUser?.userRef}&menuId=${availableMenus[0].id!}`
      );
    }
  }, [availableMenus]);
  return (
    <Container>
      <Grid container justifyContent={"center"} mt={2}>
        <Grid
          item
          sx={{
            position: "absolute",
            top: 10,
            left: 5,
            zIndex: 9999,
          }}
        >
          <img src={WhiteLogo} width={"130px"} height={"37px"} />
        </Grid>
        <Grid
          item
          sx={{
            position: "absolute",
            transform: "translateY(-15%)",
          }}
        >
          <img src={CurvedBackground} width={"544px"} height={"218px"} />
        </Grid>
        <Grid
          item
          sx={{
            position: "absolute",
            top: 85,
          }}
        >
          <img src={WaiterIcon} width={"227px"} height={"185px"} />
        </Grid>
        <Grid container mt={"265px"}>
          <Grid container justifyContent={"center"}>
            <Grid item textAlign={"center"}>
              <Typography
                sx={{ fontSize: "28px", fontWeight: "bold" }}
                className={"font-istok-web"}
              >
                Choisisez votre menu
              </Typography>
            </Grid>
            <Grid item mt={2} textAlign={"center"}>
              <Typography sx={{ color: "#273551" }}>
                Attention, pas d'alcool si tu es mineur.
              </Typography>
              <Typography sx={{ color: "#273551" }}>C'est la loi!</Typography>
            </Grid>
          </Grid>
          <Grid container mt={5} direction={"column"}>
            {availableMenus.length === 0 ? (
              <Grid item textAlign={"center"} mt={5}>
                <Typography sx={{ fontWeight: "bold", color: "red" }}>
                  Aucun menu n'est disponible pour l'instant.
                </Typography>
              </Grid>
            ) : (
              availableMenus.map((menu) => (
                <Button
                  sx={{
                    backgroundColor: "#273551",
                    color: "white",
                    borderRadius: "15px",
                    height: "55px",
                    fontSize: "24px",
                    marginBottom: "25px",
                    textTransform: "none",
                  }}
                  className={"font-istok-web"}
                  onClick={() => {
                    navigate(
                      `/customerMenu/scan?userRef=${authenticatedUser?.userRef}&menuId=${menu.id!}`
                    );
                  }}
                >
                  {menu.name}
                </Button>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
