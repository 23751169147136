import CryptoJS from "crypto-js";

const secretKey = `${process.env.REACT_APP_CRYPTO_SECRET_KEY}`;

export const encryptToken = (token: string) => {
  return CryptoJS.AES.encrypt(token, secretKey).toString();
};

export const decryptToken = (encryptedToken: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// Gestion de l'accessToken (sessionStorage)
export const retrieveAccessToken = () => {
  const storedToken = sessionStorage.getItem("accessToken");
  if (storedToken) {
    return decryptToken(storedToken);
  }
  return null;
};

export const storeAccessToken = (token: string) => {
  const encryptedToken = encryptToken(token);
  sessionStorage.setItem("accessToken", encryptedToken);
};

// Gestion du refreshToken (localStorage)
export const retrieveRefreshToken = () => {
  const storedToken = localStorage.getItem("refreshToken");
  if (storedToken) {
    return decryptToken(storedToken);
  }
  return null;
};

export const storeRefreshToken = (token: string) => {
  const encryptedToken = encryptToken(token);
  localStorage.setItem("refreshToken", encryptedToken);
};

export const clearAccessToken = () => {
  try {
    sessionStorage.removeItem("accessToken");
  } catch (err) {
    console.error("Error clearing access token", err);
  }
};

export const clearRefreshToken = () => {
  try {
    localStorage.removeItem("refreshToken");
  } catch (err) {
    console.error("Error clearing access token", err);
  }
};

export const clearTokens = () => {
  try {
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  } catch (err) {
    console.error("Error clearing tokens", err);
  }
};
