import { Button, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { createMenu } from "../../service/menusServices";
import { TCreateMenuRequest } from "../../service/Requests/TCreateMenuRequest";
import { menuActions } from "../../slice/menuSlice";
import CreateMenuForm from "../Forms/CreateMenuForm";
import { TCreateMenuModalProps } from "./types/TCreateMenuModalProps";
import { useNavigate } from "react-router-dom";
import HoverButton from "../HoveredButton/HoveredButton";
import useMenuRepository from "../../repository/useMenuRepository";

export default function CreateMenuModal(props: TCreateMenuModalProps) {
  const { onCreateMenu } = useMenuRepository();
  const [showOtherMenuForm, setShowOtherMenuForm] = useState(false);
  const isCreateMenuModalOpen = useAppSelector(
    (state) => state.menu.isCreateMenuModalOpen
  );

  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(menuActions.setIsCreateMenuModalOpen(false));
    setShowOtherMenuForm(false);
  };
  const navigate = useNavigate();
  const handleCreateMorningMenu = async () => {
    // Define the starting time for the morning
    let morningStart = new Date();
    morningStart.setHours(6, 0, 0, 0); // Set to 6:00:00.000 AM

    // Define the ending time for the morning
    let morningEnd = new Date();
    morningEnd.setHours(12, 0, 0, 0); // Set to 12:00:00.000 PM (noon)
    const requestData = {
      name: "Menu Matin",
      activeFrom: morningStart,
      activeTo: morningEnd,
      type: "MORNING",
    } as TCreateMenuRequest;

    await onCreateMenu(requestData);
  };
  const handleCreateDefaultMenu = async () => {
    const requestData = {
      name: "Menu par défaut",
      activeFrom: null,
      activeTo: null,
      type: "DEFAULT",
    } as TCreateMenuRequest;

    await dispatch(createMenu(requestData));
  };
  const handleCreateOtherMenu = () => {
    setShowOtherMenuForm(true);
  };
  const isAnyDefaultMenu = (): boolean => {
    return props.menuList.some((menu) => menu.menuType.type === "DEFAULT");
  };
  const isAnyMorningMenu = (): boolean => {
    let returnValue = false;
    props.menuList.map((menu) => {
      const activeFrom = menu.activeFrom ? new Date(menu.activeFrom) : null;
      const activeTo = menu.activeTo ? new Date(menu.activeTo) : null;
      let noon = new Date();
      noon.setHours(12, 0, 0, 0); // Set time to 12:00 PM

      if (activeFrom && activeTo) {
        if (activeTo.getTime() <= noon.getTime()) {
          returnValue = true;
        }
      }
    });
    return returnValue;
  };

  return (
    <>
      <MuiDialog
        open={isCreateMenuModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
      >
        <DialogTitle>Création d'un menu</DialogTitle>

        <Grid container className="grid-center" p={2}>
          {!showOtherMenuForm && (
            <>
              <Grid item xs={12} m={2}>
                <HoverButton
                  buttonText="Menu matin"
                  hoverMessage="Vous possédez déjà un menu matin"
                  disabled={isAnyMorningMenu()}
                  onClick={handleCreateMorningMenu}
                />
              </Grid>
              <Grid item xs={12} m={2}>
                <HoverButton
                  buttonText="Menu par défaut"
                  hoverMessage="Vous possédez déjà un menu par défaut"
                  disabled={isAnyDefaultMenu()}
                  onClick={handleCreateDefaultMenu}
                />
              </Grid>
              <Grid item xs={12} m={2}>
                <Button
                  className="popup-button-blue"
                  onClick={handleCreateOtherMenu}
                >
                  Autre
                </Button>
              </Grid>
            </>
          )}
          {showOtherMenuForm && <CreateMenuForm menuList={props.menuList} />}
        </Grid>
      </MuiDialog>
    </>
  );
}
