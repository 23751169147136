import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TDeleteMenuConfirmationModalProps } from "./types/TDeleteMenuConfirmationModalProps";
import { deleteMenu } from "../../service/menusServices";
import useMenuRepository from "../../repository/useMenuRepository";

export default function DeleteMenuConfirmationModal(
  props: TDeleteMenuConfirmationModalProps
) {
  const { onDeleteMenu } = useMenuRepository();
  const dispatch = useAppDispatch();

  const isMenuShownByDefault = () => {
    return props.selectedMenu.menuType.type === "DEFAULT" || false;
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const handleDeleteMenu = async () => {
    const deleted = await onDeleteMenu({ menu: props.selectedMenu });
    if (deleted) {
      handleCloseModal();
    }
    return deleted;
  };

  return (
    <>
      <MuiDialog
        open={props.isOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        // Handle backdrop click to close modal using slotProps
        slotProps={{
          backdrop: true,
        }}
      >
        <DialogTitle>Suppression du menu</DialogTitle>
        {isMenuShownByDefault() ? (
          <DialogContent>
            Vous ne pouvez pas supprimer le menu par défaut. Veuillez en choisir
            un autre.
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              Êtes-vous sûr de vouloir supprimer ce menu?
            </DialogContent>
            <Grid container className="grid-center" mb={2} spacing={2}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  className="blue-round-button"
                  onClick={handleDeleteMenu}
                >
                  Oui
                </Button>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                onClick={handleCloseModal}
              >
                <Button className="blue-round-button">Non</Button>
              </Grid>
            </Grid>
          </>
        )}
      </MuiDialog>
    </>
  );
}
