import { Button } from "@mui/material";
import React from "react";
import { TBlueRoundButtonProps } from "./types/TBlueRoundButtonProps";

export default function BlueRoundButton(props: TBlueRoundButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        backgroundColor: props.disabled
          ? "rgba(39, 53, 81, 0.5)"
          : "rgba(39, 53, 81, 1)",
        color: "white",
        width: "100%",
        borderRadius: "25px",
        boxShadow: " 0px 4px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "18px",
        fontWeight: "600",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "rgba(39, 53, 81, 0.9)",
        },
      }}
    >
      <span style={{ color: "white" }}>{props.title}</span>
    </Button>
  );
}
