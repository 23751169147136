import { Box, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useAppDispatch } from "../../../../common/store/redux";
import UpdateIAMForm from "../Forms/UpdateIAMForm";
import { TUpdateIAMModalProps } from "./types/TUpdateIAMModalProps";

export default function UpdateIAMModal(props: TUpdateIAMModalProps) {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    props.setIsOpen(false);
  };

  return (
    <>
      <MuiDialog
        open={props.isOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        // Handle backdrop click to close modal using slotProps
        slotProps={{
          backdrop: true,
        }}
      >
        <DialogTitle>
          Modifier le rôle de {props.firstName} {props.lastName}
        </DialogTitle>
        <Box p={2}>
          <UpdateIAMForm
            previousRole={props.previousRole}
            roles={props.roles}
            userId={props.userId}
            handleCloseModal={handleCloseModal}
          />
        </Box>
      </MuiDialog>
    </>
  );
}
