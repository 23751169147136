import { IMenuEntity } from "./IMenuEntity";

export class MenuTimeframeCalculator {
  private _menu: IMenuEntity;

  constructor(menu: IMenuEntity) {
    this._menu = menu;
  }

  isAvailableAllDay(): boolean {
    if (this._menu.activeFrom === null && this._menu.activeTo === null) {
      return true;
    }
    return false;
  }

  startAtSameTimeThan(menu: IMenuEntity): boolean {
    if (this._menu.activeFrom?.getTime() === menu.activeTo?.getTime()) {
      return true;
    }
    return false;
  }
}
