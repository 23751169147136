import { CancelOutlined } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import useMenuItemCustomizationRepository from "../../repository/useMenuItemCustomizationRepository";
import { TDeleteIconProps } from "./types/TDeleteIconProps";

export default function DeleteIcon(props: TDeleteIconProps) {
  const { onDeleteMenuItemCustomizationOption } =
    useMenuItemCustomizationRepository();
  const handleDelete = () => {
    onDeleteMenuItemCustomizationOption({
      optionIndex: props.optionIndex,
      customizationIndex: props.customizationIndex,
    });
  };
  return (
    <CancelIcon
      onClick={handleDelete}
      sx={{
        cursor: "pointer",
        color: "#273551",
        fontSize: "16px",
      }}
    />
  );
}
