import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { THoverButtonProps } from "./types/THoverButtonProps";

const HoverButton = (props: THoverButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip title={props.hoverMessage} open={isHovered}>
      <Button
        className="popup-button-blue-2"
        style={{
          backgroundColor: props.disabled
            ? "rgba(39, 53, 81, 0.5)"
            : "rgba(39, 53, 81, 1)",
          cursor: `${props.disabled ? "not-allowed" : ""}`,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          !props.disabled && props.onClick();
        }}
      >
        {props.buttonText}
      </Button>
    </Tooltip>
  );
};

export default HoverButton;
