import { Box, Grid } from "@mui/material";
import { useAppSelector } from "../../../../common/store/redux";
import SettingsBoxItem from "../SettingsBoxItem/SettingsBoxItem";

export default function SettingsBox() {
  const monthOrders = useAppSelector((state) => state.cart.monthOrders);
  const availableBalance = useAppSelector(
    (state) => state.fundsManagement.availableBalance
  );
  const averageReviewScore = useAppSelector(
    (state) => state.reviews.averageReviewScore
  );
  return (
    <Box
      sx={{
        border: 2,
        borderColor: "black",
        borderRadius: 1,
        p: 1,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction={"row"}
      >
        <SettingsBoxItem
          title="Solde du compte"
          value={`€ ${availableBalance}`}
        />
        <SettingsBoxItem
          title="Total des commandes"
          value={String(monthOrders ?? "")}
        />
        <SettingsBoxItem
          title="Avis moyen"
          value={`${averageReviewScore} / 5`}
        />
      </Grid>
    </Box>
  );
}
