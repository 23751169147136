import { Grid, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TCustomTextFieldProps } from "./types/TCustomTextFieldProps";
import { capitalizeFirstLetter } from "./CustomForm";

const CustomTextField = (props: TCustomTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[props.name]?.message;

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validation}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          value={
            props.firstLetterUppercase
              ? capitalizeFirstLetter(field.value || "")
              : field.value || ""
          }
          onChange={(e) => field.onChange(e.target.value)}
          label={props.label}
          variant="outlined"
          type="text"
          error={!!errorMessage}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      )}
    />
  );
};

export default CustomTextField;
