import {
  CancelRounded,
  CheckCircleRounded,
  DeleteRounded,
} from "@mui/icons-material";
import { ClickAwayListener, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState, useCallback } from "react";
import { TuseEditableTitleProps } from "./types/TuseEditableTitleProps";

export const useEditableTitle = (props: TuseEditableTitleProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editableValue, setEditableValue] = useState<string>(
    props.defaultValue
  );
  const [hasValueChanged, setHasValueChanged] = useState<boolean>(false);
  const [hasClickedAway, setHasClickedAway] = useState<boolean>(false);
  const longPressTimer = useRef<NodeJS.Timeout | null>(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const lastClickTime = useRef<number>(0);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value === props.defaultValue) {
      setHasValueChanged(false);
    } else {
      setHasValueChanged(true);
    }
    setEditableValue(event.target.value);
  };

  const handleClickAway = () => {
    setIsEditing(false);
    setEditableValue(props.defaultValue);
    if (hasValueChanged) {
      //Save value
      setHasClickedAway(true);
    }
  };

  const handleCancelEdition = () => {
    setEditableValue(props.defaultValue);
    handleClickAway();
  };

  const handleSaveEdit = async () => {
    if (hasValueChanged) {
      await props.saveEdit();
      setIsEditing(false);
      setHasValueChanged(false);
    }
  };

  const handleDeleteEdit = async () => {
    props.deleteEdit();
  };

  const handleCloseEdition = () => {
    setIsEditing(false);
    setHasValueChanged(false);
  };

  const handleMouseDown = useCallback(() => {
    longPressTimer.current = setTimeout(() => {
      setIsLongPress(true);
      setIsEditing(true);
    }, 500); // 500ms for long press
  }, []);

  const handleMouseUp = useCallback(() => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
    if (!isLongPress) {
      const currentTime = new Date().getTime();
      const timeSinceLastClick = currentTime - lastClickTime.current;

      if (timeSinceLastClick < 300) {
        // 300ms threshold for double-click
        setIsEditing(true);
      }

      lastClickTime.current = currentTime;
    }
    setIsLongPress(false);
  }, [isLongPress]);

  const handleMouseLeave = useCallback(() => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
    setIsLongPress(false);
  }, []);

  const getEditableTitle = () => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div key={props.key}>
          {isEditing ? (
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <TextField
                  value={editableValue}
                  onChange={handleChange}
                  autoFocus
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item mr={1}>
                <CheckCircleRounded
                  sx={{
                    fontSize: "20px",
                    color: `${!hasValueChanged || editableValue === "" ? "rgba(0,0,0,0.25)" : "rgba(0,0,0,1)"}`,
                    cursor: `${!hasValueChanged || editableValue === "" ? "default" : "pointer"}`,
                  }}
                  onClick={handleSaveEdit}
                />
              </Grid>
              <Grid item mr={1}>
                <CancelRounded
                  sx={{ fontSize: "20px" }}
                  onClick={handleCancelEdition}
                />
              </Grid>
              <Grid item>
                <DeleteRounded
                  sx={{ fontSize: "20px", color: "red" }}
                  onClick={handleDeleteEdit}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography
              sx={props.style}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
            >
              {editableValue}
            </Typography>
          )}
        </div>
      </ClickAwayListener>
    );
  };

  return {
    getEditableTitle,
    hasClickedAway,
    editableValue,
    handleCloseEdition,
  };
};
