import { DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { appActions } from "../../store/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/redux";

export default function ErrorModal() {
  const openErrorModal = useAppSelector((state) => state.app.showErrorModal);
  const errorMessage = useAppSelector((state) => state.app.errorMessage);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(appActions.setErrorMessage(""));
    dispatch(appActions.setShowErrorModal(false));
  };

  useEffect(() => {
    dispatch(appActions.setCustomerMenuLoading(false));
    dispatch(appActions.setAppLoading(false));
  }, []);

  return (
    <>
      <MuiDialog open={openErrorModal} onClose={handleCloseModal} maxWidth="sm">
        <DialogTitle className="grid-center">Erreur</DialogTitle>
        <Grid container mb={2}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <ErrorOutlineIcon
              sx={{
                color: "red",
                fontSize: "35px",
                display: "block",
                margin: "0 auto",
                animation: `fadeIn 0.5s ease-in-out`,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            className="grid-center"
            pt={1}
            pl={1}
            pr={1}
          >
            <Typography sx={{ textAlign: "center" }}>{errorMessage}</Typography>
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
}
