import React from "react";
import { useAppDispatch } from "../../../../../common/store/redux";
import { TUpdateMenuSupplementFromListRequest } from "../services/types/Requests/TUpdateMenuSupplementFromListRequest";
import { updateMenuSupplementFromList } from "../services/supplementService";
import { IMenuSupplementEntity } from "../../../../../common/Entities/IMenuSupplementEntity";
import { menuActions } from "../../menu/slice/menuSlice";
import { TUseMenuSupplementRepositoryProps } from "./types/TUseMenuSupplementRepositoryProps";

export default function useMenuSupplementRepository(
  props: TUseMenuSupplementRepositoryProps
) {
  const dispatch = useAppDispatch();

  async function onUpdateMenuSupplementFromList(
    requestData: TUpdateMenuSupplementFromListRequest
  ): Promise<IMenuSupplementEntity[]> {
    const resultAction = await dispatch(
      updateMenuSupplementFromList(requestData)
    );

    if (updateMenuSupplementFromList.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const updatedSupplement = resultAction.payload;
    dispatch(
      menuActions.updateMenuSupplementList({
        menuId: props.selectedMenu.id!,
        categoryId: props.selectedCategory.id!,
        itemId: props.selectedItem.id!,
        newSupplementList: updatedSupplement,
      })
    );
    return updatedSupplement;
  }
  return {
    onUpdateMenuSupplementFromList,
  };
}
