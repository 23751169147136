import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import GradientDivider from "../../../../../common/containers/GradientDivider/GradientDivider";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import CartHeader from "../../../containers/Header/CartHeader";
import TotalPrice from "../../../containers/TotalPrice/TotalPrice";
import AddItemsButton from "../containers/AddItemsButton/AddItemsButton";
import CartValidationRecapBox from "../containers/CartRecapBox/CartValidationRecapBox";
import CarItemQuantityPopup from "../containers/Popup/CartItemQuantityPopup";
import DeleteCartItemPopup from "../containers/Popup/DeleteCartItemPopup";
import SendOrderButton from "../containers/SendOrderButton/SendOrderButton";
import { useEffect } from "react";
import { clearCartState } from "../../../../../utils/cart/cartLocalStorage";
import { cartActions } from "../../../slices/cartSlice";
import { TCartValidationPageProps } from "./types/TCartValidationPageProps";

const CartValidationPage = (props: TCartValidationPageProps) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const menuId = urlParams.get("menuId") || "";
  const cartState = useAppSelector((state) => state.cart.currentCart);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (cartState?.categories.length === 0) {
      clearCartState();
      dispatch(cartActions.clearCart());
      window.location.href = `/customerMenu/payment?userRef=${userRef}&table=${table}&menuId=${menuId}`;
    }
  }, [JSON.stringify(cartState), dispatch]);
  return cartState ? (
    <>
      <DeleteCartItemPopup />
      <CarItemQuantityPopup />
      <Grid
        container
        marginTop={3}
        marginBottom={3}
        padding={2}
        justifyContent={"center"}
      >
        <Grid xs={12} md={8} sm={12} lg={8}>
          <CartHeader
            userRef={userRef}
            table={table}
            menuId={parseInt(menuId)}
            title="Nom du Restaurant"
            subtitle="Commandez et recevez votre commande directement à table"
          />
        </Grid>
        <Grid xs={12} md={8} sm={12} lg={8}>
          <CartValidationRecapBox cart={cartState} />
        </Grid>
        <Grid xs={12} md={8} sm={12} lg={8} mt={4}>
          <GradientDivider />
          <TotalPrice variant="normal" cart={cartState} />
        </Grid>
        <Grid container justifyContent={"center"} marginTop={6}>
          <Grid xs={10} md={10} sm={10} className="grid-center">
            <SendOrderButton
              cart={cartState}
              userRef={userRef}
              table={table}
              menuId={parseInt(menuId)}
            />
          </Grid>
          <Grid marginTop={2} xs={10} md={10} sm={10} className="grid-center">
            <AddItemsButton
              userRef={userRef}
              table={table}
              menuId={parseInt(menuId)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <>Votre panier est vide</>
  );
};

export default CartValidationPage;
