import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { TEditCartTicketItemBoxProps } from "./types/TEditCartTicketItemBoxProps";
import EditCartTicketSupplementBox from "./EditCartTicketSupplementBox";
import useKitchenRepository from "../../repository/useKitchenRepository";

export default function EditCartTicketItemBox(
  props: TEditCartTicketItemBoxProps
) {
  const { onRejectCartItem } = useKitchenRepository();
  const handleDecline = async (
    cartItemId: number,
    cartId: number,
    table: string
  ) => {
    await onRejectCartItem({
      cartId: cartId,
      cartItemId: cartItemId,
      table: table,
    });
  };
  return (
    <Grid
      container
      justifyContent={"space-between"}
      p={2}
      sx={{
        backgroundColor: "#273551",
        borderRadius: "12px",
      }}
      mb={3}
    >
      <Grid container direction={"row"} xs={8} md={8}>
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
            }}
            className="font-manrope"
          >
            {props.cartItem.item.name}
          </Typography>
        </Grid>
        {props.cartItem.cartSupplements.length > 0 && (
          <EditCartTicketSupplementBox
            cartSupplements={props.cartItem.cartSupplements}
          />
        )}
      </Grid>

      <Grid
        item
        xs={4}
        md={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#F80000",
            color: "white",
            fontSize: "12px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            heigt: "25px",
            width: "84px",
            "&:hover": {
              backgroundColor: "#F80000",
            },
          }}
          onClick={() => {
            handleDecline(props.cartItem.id!, props.cart.id!, props.cart.table);
          }}
        >
          Rejetter
        </Button>
      </Grid>
    </Grid>
  );
}
