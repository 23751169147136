import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserRoleEntity } from "../../../common/Entities/IRoleEntity";
import { TRoleState } from "./types/TRoleState";
import { getVisibleRoles } from "../../../utils/roles/shared";

const initialState: TRoleState = {
  listOfRoles: null,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setListOfRoles: (state, action: PayloadAction<IUserRoleEntity[]>) => {
      state.listOfRoles = getVisibleRoles(action.payload);
    },
  },
});

export const rolesReducer = rolesSlice.reducer;
export const rolesActions = rolesSlice.actions;
