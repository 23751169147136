export interface IGrowthDataEntity {
  date: Date;
  value: number;
}

export class GrowthCalculator {
  private data: IGrowthDataEntity[];

  constructor(data: IGrowthDataEntity[]) {
    this.data = data;
  }

  private getSumForMonth(month: number, year: number): number {
    return this.data
      .filter(
        (item) =>
          item.date.getMonth() === month && item.date.getFullYear() === year
      )
      .reduce((sum, item) => sum + item.value, 0);
  }

  public calculateGrowth(): number {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    const sumLastMonth = this.getSumForMonth(lastMonth, lastMonthYear);
    const sumCurrentMonth = this.getSumForMonth(currentMonth, currentYear);

    if (sumLastMonth === 0) {
      return sumCurrentMonth === 0 ? 0 : 100; // Assume 100% growth if there was no value last month but there is this month
    }

    return ((sumCurrentMonth - sumLastMonth) / sumLastMonth) * 100;
  }
}
