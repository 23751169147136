import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TUpdateMenuItemTextFieldProps } from "../../../item/container/Forms/types/TUpdateMenuItemTextFieldProps";

const YesNoSelectField: React.FC<TUpdateMenuItemTextFieldProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[props.name]?.message;

  return (
    <Grid item m={2}>
      <FormControl fullWidth variant="outlined" error={!!errorMessage}>
        <InputLabel>{props.label}</InputLabel>
        <Controller
          name={props.name}
          control={control}
          rules={props.validation}
          defaultValue={props.defaultValue ?? false} // Par défaut à false si non défini
          render={({ field }) => (
            <Select
              {...field}
              label={props.label}
              displayEmpty
              onChange={(e) => field.onChange(e.target.value === "true")} // Convertit la chaîne en booléen
            >
              <MenuItem value="true">Oui</MenuItem>
              <MenuItem value="false">Non</MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default YesNoSelectField;
