import { Button, Checkbox, Grid, Typography } from "@mui/material";
import {
  AddCircleOutline,
  AddCircleOutlineRounded,
  AddCircleOutlineSharp,
  AddCircleRounded,
  RemoveCircleRounded,
} from "@mui/icons-material";
import CustomPopup from "../../../../common/containers/Popup/CustomPopup";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import ItemPreview from "../ItemPreview/ItemPreview";
import GradientDivider from "../../../../common/containers/GradientDivider/GradientDivider";
import PickSupplement from "../PickSupplement/PickSupplement";
import { useEffect } from "react";
import { cartActions } from "../../../Cart/slices/cartSlice";
import ItemPreviewModal from "./ItemPreviewModal";

export default function PersonalizeItemModal() {
  const unfilledCustomizationIds = useAppSelector(
    (state) => state.menuPreview.unfilledCustomizationIds
  );
  const dispatch = useAppDispatch();
  const openItemModal = useAppSelector(
    (state) => state.menuPreview.openItemModal
  );
  const selectedItem = useAppSelector(
    (state) => state.menuPreview.selectedItem
  );
  const selectedCategory = useAppSelector(
    (state) => state.menuPreview.selectedCategory
  );
  const itemToAdd = useAppSelector((state) => state.menuPreview.itemToAdd);
  const handleClosePopup = () => {
    dispatch(menuPreviewActions.clearItemToAdd());
    dispatch(menuPreviewActions.setOpenItemModal(false));
  };
  const handleIncreaseQuantity = () => {
    dispatch(menuPreviewActions.increaseItemToAddQuantity());
  };
  const handleLowerQuantity = () => {
    dispatch(menuPreviewActions.lowerItemToAddQuantity());
  };
  const handleAddToCart = () => {
    itemToAdd &&
      selectedCategory &&
      dispatch(
        cartActions.addCurrentCartItem({
          category: selectedCategory,
          item: itemToAdd,
        })
      );
    dispatch(menuPreviewActions.setOpenItemModal(false));
  };
  const calculateTotalItemToAddPrice = () => {
    return parseFloat(
      (
        (itemToAdd?.item?.price || 0) * (itemToAdd?.quantity || 0) +
        (itemToAdd?.cartSupplements?.reduce(
          (acc, supplement) =>
            acc + supplement.supplement.price * supplement.quantity,
          0
        ) || 0) *
          (itemToAdd?.quantity || 0)
      ).toFixed(2)
    );
  };
  useEffect(() => {
    if (openItemModal && selectedItem) {
      dispatch(menuPreviewActions.initiateMenuItemToAdd(selectedItem));
    }
  }, [openItemModal, selectedItem]);
  return (
    selectedItem &&
    itemToAdd &&
    selectedCategory && (
      <CustomPopup
        open={openItemModal}
        title=""
        onClose={handleClosePopup}
        disablePadding={true}
      >
        <Grid container xs={12} sx={{ margin: 0, padding: 0 }}>
          {selectedItem.menuItemImage.image.url && (
            <Grid container xs={12}>
              <Grid xs={12} md={12}>
                <img
                  src={`${selectedItem.menuItemImage.image.url}`}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <ItemPreviewModal
              item={selectedItem}
              modalItem={true}
              category={selectedCategory}
            />

            {selectedItem.supplements &&
              selectedItem.supplements.length > 0 && (
                <>
                  <Grid mt={4} padding={"16px"}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#000000",
                        fontWeight: "600",
                      }}
                    >
                      Suppléments{"  "}
                      <span
                        className="listItem-heading-medium-secondary"
                        style={{ paddingLeft: "7px" }}
                      >
                        Optionnel
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={12} mt={1} mb={2} padding={"16px"}>
                    <GradientDivider />
                  </Grid>
                  <Grid container xs={12} padding={"16px"}>
                    {selectedItem.supplements.map((supplement) => {
                      return <PickSupplement supplement={supplement} />;
                    })}
                  </Grid>
                </>
              )}
            <Grid container xs={12} mt={4} mb={4} padding={"16px"}>
              <Grid xs={3} className="grid-center" paddingRight={2}>
                <Typography
                  sx={{ fontSize: "18px", color: "#000000", fontWeight: "600" }}
                >
                  Quantité
                </Typography>
              </Grid>
              <Grid xs={1} className="grid-center">
                <RemoveCircleRounded onClick={handleLowerQuantity} />
              </Grid>
              <Grid xs={1} className="grid-center">
                {itemToAdd.quantity}
              </Grid>
              <Grid xs={1} className="grid-center">
                <AddCircleRounded onClick={handleIncreaseQuantity} />
              </Grid>
            </Grid>
            <Grid container xs={12} className="grid-center" padding={"16px"}>
              <Grid item sx={{ maxWidth: "80%" }}>
                <Button
                  onClick={handleAddToCart}
                  className="blue-round-button"
                  disabled={unfilledCustomizationIds.length > 0 ? true : false}
                  sx={{
                    "&:disabled": {
                      backgroundColor: "rgba(39, 53, 81, 0.2)",
                    },
                  }}
                >
                  Ajouter au panier ({calculateTotalItemToAddPrice()}€)
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomPopup>
    )
  );
}
