import { SubmitHandler, useForm } from "react-hook-form";
import { CustomForm } from "../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../common/containers/Form/CustomTextFields";
import { customFormActions } from "../../../../../common/store/customFormSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { cartActions } from "../../../slices/cartSlice";
import { TScanQrcodeFormProps } from "./types/TScanQrcodeProps";
import { TScanQrcodeFormAttributes } from "./types/TScanQrcodeFormAttributes";
import { Grid } from "@mui/material";
import ScanQRCodeField from "./ScanQRCodeField";

export default function ScanQrcodeForm(props: TScanQrcodeFormProps) {
  const { watch } = useForm();
  const tableNumber = watch("qrcode_table_number");
  const menu = useAppSelector((state) => state.menuPreview.menu);
  const authenticatedUser = useAppSelector(
    (state) => state.menuPreview.authenticatedUser
  );
  const dispatch = useAppDispatch();
  const handleOnSubmit: SubmitHandler<TScanQrcodeFormAttributes> = (data) => {
    dispatch(
      cartActions.setCurrentCart({
        categories: [],
        userRef: props.userRef,
        table: tableNumber,
      })
    );

    dispatch(customFormActions.setFormSuccess("scan-qrcode-form"));
    window.location.href = `/customerMenu/empty?userRef=${props.userRef}&table=${data.qrcode_table_number}&menuId=${props.menuId}`;
  };

  const handleOnSuccess = () => {};

  return (
    <CustomForm
      onSubmit={handleOnSubmit}
      onSuccess={handleOnSuccess}
      defaultButtonText="Valider"
      name="scan-qrcode-form"
    >
      <Grid item mb={4}>
        <ScanQRCodeField
          name="qrcode_table_number"
          label="Numéro de table"
          validation={{
            required: "Veuillez entrer un numéro de table",
          }}
        />
      </Grid>
    </CustomForm>
  );
}
