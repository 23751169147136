import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import SelectedMenuItemPage from "../../../item/container/Pages/SelectedMenuItemPage";
import { selectMenuList } from "../../selectors/menuSelector";
import { findAllMenus } from "../../service/menusServices";
import MainMenuCreationPage from "./MainMenuCreationPage";
import SelectedMenuPage from "./SelectedMenuPage";

export default function MenuManagementContent() {
  const menuList = useAppSelector(selectMenuList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    !menuList || (menuList.length === 0 && dispatch(findAllMenus()));
  }, [JSON.stringify(menuList)]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<MainMenuCreationPage menuList={menuList} />}
        />
        <Route
          path="/:menuId"
          element={<SelectedMenuPage menuList={menuList} />}
        />
        <Route
          path="/:menuId/category/:categoryId/item/:itemId"
          element={<SelectedMenuItemPage menuList={menuList} />}
        />
      </Routes>
    </div>
  );
}
