import { ICartCategoryEntity } from "../../common/Entities/ICartCategoryEntity";
import { ICartEntity } from "../../common/Entities/ICartEntity";
import { ICartItemEntity } from "../../common/Entities/ICartItemEntity";
import { ICartSupplementEntity } from "../../common/Entities/ICartSupplementEntity";

export const calculateCartSupplementPrice = (
  cartSupplement: ICartSupplementEntity
): number => {
  return Number(
    (cartSupplement.supplement.price * cartSupplement.quantity).toFixed(2)
  );
};

export const calculateTotalCartSupplementsPrice = (
  cartSupplements: ICartSupplementEntity[]
): number => {
  return Number(
    cartSupplements
      .reduce(
        (previous, current) => previous + calculateCartSupplementPrice(current),
        0
      )
      .toFixed(2)
  );
};
export const calculateCartItemPrice = (cartItem: ICartItemEntity): number => {
  return Number(
    (
      cartItem.item.price * cartItem.quantity +
      calculateTotalCartSupplementsPrice(cartItem.cartSupplements) *
        cartItem.quantity
    ).toFixed(2)
  );
};
export const calculateCartCategoryPrice = (
  cartCategory: ICartCategoryEntity
): number => {
  return Number(
    cartCategory.items
      .reduce(
        (previous, current) => previous + calculateCartItemPrice(current),
        0
      )
      .toFixed(2)
  );
};
export const calculateTotalCartPrice = (cart: ICartEntity): number => {
  return Number(
    cart.categories
      .reduce(
        (previous, current) => previous + calculateCartCategoryPrice(current),
        0
      )
      .toFixed(2)
  );
};
