import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TImageSliceState } from "./types/TImageSliceState";

const initialState: TImageSliceState = {
  isUpdateImageModalOpen: false,
  fileToUpload: null,
  previewImage: null,
  deleteImage: false,
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setIsUpdateImageModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isUpdateImageModalOpen = action.payload;
    },
    setFileToUpload: (state, action: PayloadAction<File | null>) => {
      state.fileToUpload = action.payload;
    },
    setPreviewImage: (state, action: PayloadAction<string | null>) => {
      state.previewImage = action.payload;
    },
    setDeleteImage: (state, action: PayloadAction<boolean>) => {
      state.deleteImage = action.payload;
    },
  },
});

export const imageReducer = imageSlice.reducer;

export const imageActions = imageSlice.actions;
