"use client";
import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    blue: true;
    default: true;
    yellow: true;
  }
}

let lightTheme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    h4: {
      color: "#273551",
      fontSize: "1.625rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: "'Lexend Deca', sans-serif",
      color: "#818181",
      fontWeight: 400,
      fontSize: "0.938rem",
    },
    h6: {
      fontFamily: "'Lexend Deca', sans-serif",
      color: "#273551",
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    body2: {
      color: "#062E4B",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.2,
    },
  },
  palette: {
    mode: "light",
    text: {
      primary: "#273551",
    },
    primary: {
      main: "#273551",
      contrastText: "#FEFEFE",
    },
    secondary: {
      main: "#273551",
      contrastText: "#FEFEFE",
    },
    background: {
      default: "#f6f7fb",
      paper: "#ffffff",
    },

    error: {
      main: "#D33939",
    },

    warning: {
      main: "#ff8155",
    },
    info: {
      main: "#15a6a9",
    },
    success: {
      main: "#06b109",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "blue" },
          style: {
            borderRadius: "30px",
            backgroundColor: "#062E4B",
            color: "#fff",
            textDecoration: "none",
            textTransform: "none",
            margin: "5px",
            "&:hover": {
              backgroundColor: "#F1C552",
              color: "#FFF",
            },
          },
        },
        {
          props: { variant: "yellow" },
          style: {
            borderRadius: "30px",
            backgroundColor: "#D9D9D9",
            color: "#939090",
            textDecoration: "none",
            textTransform: "none",
            flexGrow: 0,
            whiteSpace: "nowrap",
            "&:hover": {
              backgroundColor: "#B0B0B0", // Add a hover state if desired
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 1px 1px -1px rgba(53,60,78,0.2), 0px 1px 1px 0px rgba(53,60,78,0.14), 0px 1px 3px 0px rgba(53,60,78,0.12)",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#273551",
          "&:not(.Mui-disabled) .MuiIconButton-root": {
            color: "#273551",
          },
        },
      },
    },
  },
});
lightTheme = responsiveFontSizes(lightTheme);
export { lightTheme };
