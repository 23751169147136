import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const AppMobileHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <Link href="/" color="inherit" underline="none">
            Accueil
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link href="/auth/signin" color="inherit" underline="none">
            Connexion
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link href="/auth/signup" color="inherit" underline="none">
            Inscription
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppMobileHeader;
