import React from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store/redux";
import { TFindAvailableCartResponse } from "../services/types/Responses/TFindAvailableCartResponse";
import { TGetCartRequest } from "../services/types/Requests/TGetCartRequest";
import { ICartEntity } from "../../../common/Entities/ICartEntity";
import {
  findAllCartsByUserRefAndDate,
  findAvailableCart,
  findPaymentCart,
} from "../services/cartService";
import { loadCartState } from "../../../utils/cart/cartLocalStorage";
import { cartActions } from "../slices/cartSlice";
import { menuPreviewActions } from "../../MenuPreview/slices/menuPreviewSlice";
import { TOnFindAllCartsByUserRefAndDateRequest } from "../services/types/Requests/TOnFindAllCartsByUserRefAndDateRequest";
import {
  GrowthCalculator,
  IGrowthDataEntity,
} from "../../../common/Entities/IGrowthDataEntity";
import { TFindPaymentCartResponse } from "../services/types/Responses/TFindPaymentCartResponse";
import { ICartItemEntity } from "../../../common/Entities/ICartItemEntity";
import { TCloseCartNotification } from "../../../common/hooks/useNotificationService/types/TCloseCartNotification";
import { TCartItemRejectedNotification } from "../../../common/hooks/useNotificationService/types/TCartItemRejectedNotification";
import { TCancelCartNotification } from "../../../common/hooks/useNotificationService/types/TCancelCartNotification";

export default function useCartRepository() {
  const dispatch = useAppDispatch();
  const paymentAvailable = useAppSelector(
    (state) => state.menuPreview.paymentAvailable
  );

  async function onFindAvailableCart(
    requestData: TGetCartRequest
  ): Promise<TFindAvailableCartResponse> {
    dispatch(menuPreviewActions.setLoading(true));
    const resultAction = await dispatch(findAvailableCart(requestData));

    if (findAvailableCart.rejected.match(resultAction)) {
      dispatch(menuPreviewActions.setLoading(false));
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const response = resultAction.payload;
    const currentCart = loadCartState();
    if (response.status.status === "PENDING") {
      const pendingCarts = response.cart!;
      !currentCart && dispatch(cartActions.setPendingCarts(pendingCarts));

      const rejectedCartItems: ICartItemEntity[] = [];
      pendingCarts.map((pendingCart) => {
        pendingCart.categories.map((category) => {
          category.items.map((item) => {
            if (item.rejectedQuantity > 0) {
              for (let i = 0; i < item.rejectedQuantity; i++) {
                rejectedCartItems.push(item);
              }
            }
          });
        });
      });
      dispatch(cartActions.setRejectedCartItems(rejectedCartItems));
    }

    dispatch(menuPreviewActions.setLoading(false));
    return response;
  }

  async function onFindPaymentCart(
    requestData: TGetCartRequest
  ): Promise<TFindPaymentCartResponse> {
    dispatch(menuPreviewActions.setLoading(true));
    const resultAction = await dispatch(findPaymentCart(requestData));

    if (findPaymentCart.rejected.match(resultAction)) {
      dispatch(menuPreviewActions.setLoading(false));
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const response = resultAction.payload;
    const currentCart = loadCartState();

    !currentCart && dispatch(cartActions.setPaymentCart(response.paymentCart));

    dispatch(menuPreviewActions.setLoading(false));
    return response;
  }

  async function onFindTodayOrders(): Promise<ICartEntity[]> {
    const now = new Date();
    const startOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    );

    const resultAction = await dispatch(
      findAllCartsByUserRefAndDate({ startDate: startOfDay, endDate: endOfDay })
    );

    if (findAllCartsByUserRefAndDate.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const foundCarts = resultAction.payload.carts;
    dispatch(
      cartActions.setTodayOrders(
        foundCarts.filter((cart) => cart.status === "PAID").length
      )
    );

    if (foundCarts && foundCarts.length > 0) {
      const growthData: IGrowthDataEntity[] = foundCarts.map((foundCarts) => ({
        date: new Date(foundCarts.date!),
        value: 1,
      }));
      const calculator = new GrowthCalculator(growthData);
      const growth = calculator.calculateGrowth();
      dispatch(cartActions.setTodayOrdersGrowthPercentage(growth || 0));
    }

    return foundCarts;
  }

  async function onFindMonthOrders(): Promise<ICartEntity[]> {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0,
      23,
      59,
      59,
      999
    );

    const resultAction = await dispatch(
      findAllCartsByUserRefAndDate({
        startDate: startOfMonth,
        endDate: endOfMonth,
      })
    );

    if (findAllCartsByUserRefAndDate.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const foundCarts = resultAction.payload.carts;
    dispatch(cartActions.setMonthOrders(0));

    if (foundCarts && foundCarts.length > 0) {
      const growthData: IGrowthDataEntity[] = foundCarts.map((foundCart) => ({
        date: new Date(foundCart.date!),
        value: 1,
      }));
      const calculator = new GrowthCalculator(growthData);
      const growth = calculator.calculateGrowth();
      dispatch(cartActions.setMonthOrdersGrowthPercentage(growth || 0));
    }

    return foundCarts;
  }

  async function onFindCartsByUserRefAndDate(
    requestData: TOnFindAllCartsByUserRefAndDateRequest
  ): Promise<ICartEntity[]> {
    const resultAction = await dispatch(
      findAllCartsByUserRefAndDate(requestData)
    );

    if (findAllCartsByUserRefAndDate.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const foundCarts = resultAction.payload.carts;
    dispatch(cartActions.setPreviousCarts(foundCarts));

    return foundCarts;
  }
  function onCloseCartNotification(request: TCloseCartNotification) {
    dispatch(cartActions.closeCart(request.cart));
  }
  function onRejectCartItemNotification(
    request: TCartItemRejectedNotification
  ) {
    dispatch(cartActions.rejectCartItem(request.cartItem));
  }
  function onCancelCartNotification(request: TCancelCartNotification) {
    dispatch(cartActions.cancelCart(request.cart));
  }

  return {
    onFindAvailableCart,
    onFindTodayOrders,
    onFindCartsByUserRefAndDate,
    onFindMonthOrders,
    onFindPaymentCart,
    onCloseCartNotification,
    onRejectCartItemNotification,
    onCancelCartNotification,
  };
}
