import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

interface StyledTextFieldProps {
  width?: string;
  height?: string;
  isBold: boolean;
  multiline?: boolean;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    !["width", "height", "isBold"].includes(prop as string),
})<StyledTextFieldProps>(({ theme, width, height, isBold, multiline }) => ({
  width: width || "425px",
  height: multiline ? "auto" : height || "65px",
  "& .MuiOutlinedInput-root": {
    height: multiline ? "auto" : "100%",
    "& fieldset": {
      borderColor: "#273551",
      borderWidth: "2px",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#273551",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#273551",
      borderWidth: "2px",
    },
    "& .MuiInputBase-input": {
      color: "black",
      fontWeight: isBold ? "bold" : "normal",
      height: multiline ? "auto" : "100%",
      fontSize: "16px",
      padding: "14px",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#181818",
  },
}));
