import { Grid, Link, Typography } from "@mui/material";
import SignInForm from "./Form/SignInForm";
import { Link as RouterLink } from "react-router-dom";

const SignInPage = () => {
  return (
    <div>
      <Grid
        container
        height={"calc(100vh - 86px)"}
        direction={"column"}
        alignItems={"center"}
      >
        <Grid item width={"400px"} mt={2}>
          <Grid item>
            <SignInForm />
          </Grid>
          <Grid container mt={2} justifyContent={"center"}>
            <Typography sx={{ fontSize: "14px" }}>
              Vous n'avez pas de compte ?{" "}
              <Link
                component={RouterLink}
                to="/auth/signup"
                sx={{ textDecoration: "none", color: "#3f51b5" }}
              >
                S'inscrire.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignInPage;
