import { Button, Grid, Typography } from "@mui/material";
import CustomPopup from "../../../../../../common/containers/Popup/CustomPopup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";
import {
  AddCircleOutline,
  AddCircleOutlineRounded,
  AddCircleOutlineSharp,
  AddCircleRounded,
  RemoveCircleRounded,
} from "@mui/icons-material";

export default function CarItemQuantityPopup() {
  const selectedItem = useAppSelector((state) => state.cart.selectedItem);
  const cart = useAppSelector((state) => state.cart.currentCart);
  const openQuantityPopup = useAppSelector(
    (state) => state.cart.openQuantityPopup
  );
  const dispatch = useAppDispatch();

  const displayQuantity = () => {
    let quantity = 0;
    cart?.categories.map((category, categoryIndex) => {
      if (categoryIndex === selectedItem.cartIndexes.categoryIndex) {
        category.items.map((item, itemIndex) => {
          if (itemIndex === selectedItem.cartIndexes.itemIndex) {
            quantity = item.quantity;
          }
        });
      }
    });
    return quantity;
  };
  const addQuantity = () => {
    dispatch(cartActions.addCurrentCartItemQuantity(selectedItem.cartIndexes));
  };
  const removeQuantity = () => {
    dispatch(
      cartActions.removeCurrentCartItemQuantity(selectedItem.cartIndexes)
    );
  };
  const handleClosePopup = () => {
    dispatch(cartActions.setOpenQuantityPopup(false));
  };
  return (
    <CustomPopup open={openQuantityPopup} title="" onClose={handleClosePopup}>
      <Grid container xs={12} className="grid-center" paddingRight={4}>
        <Grid xs={3} className="grid-center" paddingRight={2}>
          <Typography className="listItem-heading-medium-primary">
            Quantité
          </Typography>
        </Grid>
        <Grid xs={1} className="grid-center">
          <AddCircleRounded onClick={addQuantity} />
        </Grid>
        <Grid xs={1} className="grid-center">
          {displayQuantity()}
        </Grid>
        <Grid xs={1} className="grid-center">
          <RemoveCircleRounded onClick={removeQuantity} />
        </Grid>
      </Grid>
    </CustomPopup>
  );
}
