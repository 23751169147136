import { IMenuItemAllergenEntity } from "../../common/Entities/IMenuItemAllergenEntity";

export function isMenuItemAllergenListsSame(
  list1: IMenuItemAllergenEntity[],
  list2: IMenuItemAllergenEntity[]
): boolean {
  const set1 = new Set(list1.map((item) => item.menuAllergen.name));
  const set2 = new Set(list2.map((item) => item.menuAllergen.name));
  console.log("set 1", set1);
  console.log("set 2", set2);

  if (set1.size !== set2.size) {
    return false;
  }

  for (const name of set1) {
    if (!set2.has(name)) {
      return false;
    }
  }

  return true;
}
