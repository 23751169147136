import { Grid } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../../common/containers/Form/CustomTextFields";
import useMenuRepository from "../../repository/useMenuRepository";
import { TUpdateMenuTitleAttributes } from "./types/TUpdateMenuTitleAttributes";
import { TUpdateMenuTitleFormProps } from "./types/TUpdateMenuTitleFormProps";

export default function UpdateMenuTitleForm(props: TUpdateMenuTitleFormProps) {
  const { onUpdateMenu } = useMenuRepository();
  const submitHandler: SubmitHandler<TUpdateMenuTitleAttributes> = async (
    data
  ) => {
    const newMenu: IMenuEntity = { ...props.selectedMenu, name: data.title };
    return await onUpdateMenu({ menu: newMenu });
  };

  const successHandler = () => {
    props.onClose();
  };
  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Modifier le titre"
      onSuccess={successHandler}
      name="create-menu-form"
      useDefaultErrorDisplay={true}
      defaultErrorDisplayCollapse={true}
    >
      <Grid item mb={3}>
        <CustomTextField
          name="title"
          label="Titre du menu"
          validation={{
            required: "Veuillez entrer un titre",
            validate: (value: string) =>
              value !== props.selectedMenu.name ||
              "Veuillez choisir un titre différent de l'original",
          }}
          firstLetterUppercase={true}
          defaultValue={props.selectedMenu.name}
        />
      </Grid>
    </CustomForm>
  );
}
