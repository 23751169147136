import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TBackToMenuButtonProps } from "./types/TBackToMenuButtonProps";
import { useAppDispatch } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../../MenuPreview/slices/menuPreviewSlice";
import { cartActions } from "../../slices/cartSlice";

export default function BackToMenuButton(props: TBackToMenuButtonProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleBackToMenu = () => {
    dispatch(menuPreviewActions.setBackToMenu(true));
    dispatch(cartActions.clearCart());
    navigate(
      `/customerMenu/empty?userRef=${props.userRef}&table=${props.table}&menuId=${props.menuId}`
    );
  };
  return (
    <Grid alignItems={"end"} display={"flex"} justifyContent={"end"} mb={2}>
      <Button
        sx={{
          backgroundColor: "#062e4b",
          color: "white",
          borderRadius: "25px",
          textTransform: "none",
          fontSize: "12px",
          fontWeight: "bold",
          height: "27px",
        }}
        onClick={handleBackToMenu}
      >
        Revenir au Menu
      </Button>
    </Grid>
  );
}
