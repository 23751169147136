import { useEffect, useState } from "react";
import { TOptionsFieldsProps } from "./types/TOptionsFieldsProps";
import UpdateMenuItemTextField from "../../../item/container/Forms/UpdateMenuItemTextField";
import { IMenuItemCustomizationOption } from "../../../../../../common/Entities/IMenuItemCustomizationOption";
import { useFormContext } from "react-hook-form";
import { generateNumericId } from "../../../../../../utils/sharedFunctions";

let uniqueIdCounter = 0; // Utiliser un compteur pour générer des IDs uniques

export default function OptionsFields(props: TOptionsFieldsProps) {
  const [fieldList, setFieldList] = useState<IMenuItemCustomizationOption[]>(
    props.newOptionsList.map((option) => ({
      ...option,
    }))
  );

  const { setValue, setError } = useFormContext();

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    key: number
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const value = (event.target as HTMLInputElement).value;
      console.log(`Enter pressed in field: ${key}, with value: ${value}`);

      if (fieldList[key].name !== value) {
        if (value === "") {
          // Supprimer le champ si la valeur est vide
          const filteredList = fieldList.filter((_, index) => index !== key);
          setFieldList(filteredList);
        } else {
          // Mettre à jour l'option avec la nouvelle valeur
          const updatedList = fieldList.map((f, index) =>
            index === key ? { ...f, name: value } : f
          );
          setFieldList(updatedList);
        }
      }
    }
  };

  useEffect(() => {
    if (fieldList.length === 0 || fieldList[fieldList.length - 1].name !== "") {
      const updatedList = [
        ...fieldList,
        {
          name: "",
          id: generateNumericId(),
          menuItemCustomizationId: props.menuItemCustomizationId,
        },
      ];
      setFieldList(updatedList);
    }
    console.log("fieldList", fieldList);
    props.setNewOptionsList(fieldList);
    fieldList.forEach((field, index) => {
      setValue(`optionName${index}`, field.name || "");
    });
  }, [JSON.stringify(fieldList)]);

  return (
    <>
      {fieldList.map((option, oKey) => (
        <div key={option.id}>
          <UpdateMenuItemTextField
            name={`optionName${oKey}`}
            label="Option"
            isBold={false}
            defaultValue={option.name}
            validation={{ required: "" }}
            onKeyDown={(event) => handleKeyDown(event, oKey)}
          />
        </div>
      ))}
    </>
  );
}
