import { useEffect, useState } from "react";
import {
  GrowthCalculator,
  IGrowthDataEntity,
} from "../../../../common/Entities/IGrowthDataEntity";
import ReviewTotalIcon from "../../../../common/images/reviews_total_icon.png";
import StatsBox from "../../../Account/modules/FundsManagement/containers/StatsBox/StatsBox";
import { TTotalReviewProps } from "./types/TTotalReviewProps";

export default function TotalReview(props: TTotalReviewProps) {
  const [growthPercentage, setGrowthPercentage] = useState<number>(0);

  useEffect(() => {
    let total = 0;
    if (props.reviewsReport) {
      const growthData: IGrowthDataEntity[] =
        props.reviewsReport.reviewsList.map((review) => ({
          date: new Date(review.date),
          value: 1,
        }));
      const calculator = new GrowthCalculator(growthData);
      const growth = calculator.calculateGrowth();

      setGrowthPercentage(growth || 0);
    }
  }, [JSON.stringify(props.reviewsReport)]);
  return (
    <StatsBox
      title="Avis globaux"
      statValue={props.reviewsReport.reviewsList.length}
      width="290px"
      height="119px"
      hideCurrency={true}
      img={{ url: ReviewTotalIcon, height: "65px", width: "65px" }}
      growthPercentage={growthPercentage}
      growthTimeframe={"MONTH"}
    />
  );
}
