import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../../common/containers/Form/CustomTextFields";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TCreateMenuCategoryFormProps } from "./types/TCreateMenuCategoryFormProps";
import { createMenuCategory } from "../../service/categoryServices";
import { TCreateMenuCategoryFormAttributes } from "./types/TCreateMenuCategoryFormAttributes";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { menuActions } from "../../../menu/slice/menuSlice";
import { categoryActions } from "../../slice/categorySlice";
import { Grid } from "@mui/material";

export default function CreateMenuCategoryForm(
  props: TCreateMenuCategoryFormProps
) {
  const dispatch = useAppDispatch();

  const submitHandler: SubmitHandler<
    TCreateMenuCategoryFormAttributes
  > = async (data) => {
    const resultAction = await dispatch(
      createMenuCategory({ ...data, menuId: props.menuId })
    );

    if (createMenuCategory.fulfilled.match(resultAction)) {
      // The update was successful
      const newMenu: IMenuEntity = {
        ...props.selectedMenu,
        categories: [...props.selectedMenu.categories, resultAction.payload],
      };

      dispatch(menuActions.updateMenu(newMenu));
      dispatch(categoryActions.setSelectedCategory(resultAction.payload));
      return resultAction.payload; // Return the updated menu
    } else if (createMenuCategory.rejected.match(resultAction)) {
      // The update failed
      if (resultAction.payload) {
        // We have an error response from the API
        throw new Error(
          "Une erreur est survenue. Veuillez réessayer plus tard."
        );
      } else {
        // We have an error that occurred during the thunk execution
        throw resultAction.error;
      }
    }
  };
  const successHandler = () => {
    props.onClose();
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Créer la catégorie"
      onSuccess={successHandler}
      name="create-menu-category-form"
      defaultErrorDisplayCollapse={true}
    >
      <Grid item pb={4}>
        <CustomTextField
          name="name"
          label="Nom"
          validation={{
            required: "Veuillez renseigner le nom de votre catégorie",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
    </CustomForm>
  );
}
