import { Typography } from "@mui/material";
import "../../../../common/containers/styles/customFonts.css";
import { ReactComponent as LogoutIcon } from "../../../../common/images/logout_icon.svg";
import useAuthRepository from "../../repository/useAuthRepository";

export default function SignoutButton() {
  const { onSignOut } = useAuthRepository();
  const handleSignout = async () => {
    await onSignOut();
  };
  return (
    <>
      <Typography
        className="font-inter"
        sx={{ fontSize: "13px" }}
        pr={1}
        onClick={() => {
          handleSignout();
        }}
      >
        Déconnexion
      </Typography>
      <LogoutIcon />
    </>
  );
}
