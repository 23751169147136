import { Route, Routes } from "react-router-dom";
import ScanQrcodePage from "../../../Cart/modules/Initialization/ScanQrcodePage";
import MenuSelection from "../../../Cart/modules/MenuSelection/MenuSelection";

export default function OutsideMenu() {
  return (
    <Routes>
      <Route path="scan" element={<ScanQrcodePage />} />
      <Route path="menuSelection" element={<MenuSelection />} />
    </Routes>
  );
}
