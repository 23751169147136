import { Box, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import BankTransferPage from "../../../Account/modules/FundsManagement/containers/Pages/BankTransferPage";
import MenuManagementContent from "../../../Menu/modules/menu/containers/Pages/MenuManagementContent";
import MainReviewsPage from "../../../Reviews/containers/Pages/MainReviewsPage";
import DashboardSideBar from "../SideBar/DashboardSideBar";
import DashboardHomePage from "./DashboardHomePage";
import RedirectToMenuPreviewPage from "./RedirectToMenuPreviewPage";
import IAMMainPage from "../../../IAM/containers/Pages/IAMMainPage";
import MainKitchenPage from "../../../Kitchen/containers/Pages/MainKitchenPage";
import { useAppSelector } from "../../../../common/store/redux";
import { useEffect, useRef, useState } from "react";
import WaiterMainPage from "../../../Waiter/containers/Pages/WaiterMainPage";

export default function MainDashboardPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const SIDEBAR_WIDTH = isMobile ? 180 : isTablet ? 180 : 231;
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const [hideSideBar, setHideSidebar] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isChef, setIsChef] = useState<boolean>(false);
  const [isWaiter, setIsWaiter] = useState<boolean>(false);

  useEffect(() => {
    if (authenticatedUser && authenticatedUser.role.name === "CHEF") {
      setIsChef(true);
    } else {
      setIsChef(false);
    }

    if (authenticatedUser && authenticatedUser.role.name === "WAITER") {
      setIsWaiter(true);
    } else {
      setIsWaiter(false);
    }
  }, [authenticatedUser]);

  return (
    <Box
      sx={{ display: !isChef ? "flex" : "unset", height: "calc(100vh - 86px)" }}
    >
      {!isChef && !isMobile && (
        <Box sx={{ height: "100%", flexShrink: 0, width: SIDEBAR_WIDTH }}>
          <DashboardSideBar />
        </Box>
      )}{" "}
      <Box
        sx={
          !isChef
            ? {
                flexGrow: 1,
                width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                overflow: "hidden", // Prevents this box from scrolling
                display: "flex",
                flexDirection: "column",
              }
            : { width: "100%" }
        }
      >
        <Box
          sx={
            !isChef
              ? {
                  flexGrow: 1,
                  overflow: "auto", // Allows this inner box to scroll
                  display: "flex",
                  flexDirection: "column",
                }
              : { width: "100%" }
          }
        >
          {!isChef ? (
            <Container
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                mt: 5,
                mb: 5,
              }}
            >
              <Routes>
                <Route path="menus/*" element={<MenuManagementContent />} />
                <Route
                  path="/"
                  element={
                    isWaiter ? <WaiterMainPage /> : <DashboardHomePage />
                  }
                />
                <Route path="/bank/transfer" element={<BankTransferPage />} />
                <Route path="/reviews" element={<MainReviewsPage />} />
                <Route path="/iam" element={<IAMMainPage />} />
                <Route
                  path="/redirect-to-menupreview"
                  element={<RedirectToMenuPreviewPage />}
                />
              </Routes>
            </Container>
          ) : (
            <Box sx={{ m: 4 }}>
              <Routes>
                <Route path="/" element={<MainKitchenPage />} />
              </Routes>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
