import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { TPagesNavigationProps } from "./types/TPagesNavigationProps";

export default function PagesNavigation(props: TPagesNavigationProps) {
  const { pagesNumber, currentPage, setPage } = props;

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesNumber) {
      setPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    if (pagesNumber <= 7) {
      for (let i = 1; i <= pagesNumber; i++) {
        pages.push(
          <Grid
            key={i}
            item
            width={"40px"}
            height={"40px"}
            sx={
              i === currentPage
                ? { backgroundColor: "#EFF1F4", borderRadius: "8px" }
                : {}
            }
            className="grid-center"
            onClick={() => setPage(i)}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: i === currentPage ? "#091E42" : "#667085",
                cursor: "pointer",
              }}
            >
              {i}
            </Typography>
          </Grid>
        );
      }
    } else {
      pages.push(
        <Grid
          key={1}
          item
          width={"40px"}
          height={"40px"}
          sx={
            currentPage === 1
              ? { backgroundColor: "#EFF1F4", borderRadius: "8px" }
              : {}
          }
          className="grid-center"
          onClick={() => setPage(1)}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: currentPage === 1 ? "#091E42" : "#667085",
              cursor: "pointer",
            }}
          >
            1
          </Typography>
        </Grid>
      );

      if (currentPage > 4) {
        pages.push(
          <Grid
            key="start-ellipsis"
            item
            width={"40px"}
            height={"40px"}
            className="grid-center"
          >
            <Typography sx={{ fontSize: "14px", color: "#667085" }}>
              ...
            </Typography>
          </Grid>
        );
      }

      for (
        let i = Math.max(2, currentPage - 1);
        i <= Math.min(pagesNumber - 1, currentPage + 1);
        i++
      ) {
        pages.push(
          <Grid
            key={i}
            item
            width={"40px"}
            height={"40px"}
            sx={
              i === currentPage
                ? { backgroundColor: "#EFF1F4", borderRadius: "8px" }
                : {}
            }
            className="grid-center"
            onClick={() => setPage(i)}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: i === currentPage ? "#091E42" : "#667085",
                cursor: "pointer",
              }}
            >
              {i}
            </Typography>
          </Grid>
        );
      }

      if (currentPage < pagesNumber - 3) {
        pages.push(
          <Grid
            key="end-ellipsis"
            item
            width={"40px"}
            height={"40px"}
            className="grid-center"
          >
            <Typography sx={{ fontSize: "14px", color: "#667085" }}>
              ...
            </Typography>
          </Grid>
        );
      }

      pages.push(
        <Grid
          key={pagesNumber}
          item
          width={"40px"}
          height={"40px"}
          sx={
            currentPage === pagesNumber
              ? { backgroundColor: "#EFF1F4", borderRadius: "8px" }
              : {}
          }
          className="grid-center"
          onClick={() => setPage(pagesNumber)}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: currentPage === pagesNumber ? "#091E42" : "#667085",
              cursor: "pointer",
            }}
          >
            {pagesNumber}
          </Typography>
        </Grid>
      );
    }
    return pages;
  };

  return (
    <Grid container alignItems="center" mt={3}>
      <Grid item container xs={2} md={2} lg={2} xl={2} alignItems="center">
        <Button
          sx={{
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "#D0D5DD",
            color: "#344054",
            textTransform: "none",
          }}
          onClick={handlePreviousClick}
          disabled={currentPage === 1}
        >
          <ArrowBack />
          Précédent
        </Button>
      </Grid>
      <Grid
        item
        container
        xs={8}
        md={8}
        lg={8}
        xl={8}
        justifyContent="center"
        alignItems="center"
      >
        {renderPageNumbers()}
      </Grid>
      <Grid
        item
        container
        xs={2}
        md={2}
        lg={2}
        xl={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          sx={{
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "#D0D5DD",
            color: "#344054",
            textTransform: "none",
          }}
          onClick={handleNextClick}
          disabled={currentPage === pagesNumber}
        >
          Suivant
          <ArrowForward />
        </Button>
      </Grid>
    </Grid>
  );
}
