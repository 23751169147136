import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../common/store/redux";
import useImageUpload from "../../hooks/useImageUpload";
import { imageActions } from "../../slices/imageSlice";
import UploadImageBox from "../UploadImageBox/UploadImageBox";
import { TUploadImageBoxWithoutModalProps } from "./types/TUploadImageBoxWithoutModal";

export default function UploadImageBoxWithoutModal(
  props: TUploadImageBoxWithoutModalProps
) {
  const {
    fileToUpload,
    setFileToUpload,
    previewImage,
    setPreviewImage,
    showRequirementMessage,
    setDeleteImage,
    deleteImage,
  } = useImageUpload();

  useEffect(() => {
    if (fileToUpload) {
      props.setHasImageChanged(true);
      props.setUploadedImage(fileToUpload);
    }
    if (deleteImage) {
      props.setHasImageChanged(true);
      props.setUploadedImage(null);
    }
  }, [previewImage, fileToUpload, deleteImage]);

  useEffect(() => {
    if (previewImage) {
      setPreviewImage(previewImage);
    } else {
      setPreviewImage(props.selectedItem.menuItemImage.image.url);
    }
  }, []);

  return (
    <Grid container maxWidth={"390px"}>
      <Grid item mb={1}>
        {showRequirementMessage()}
      </Grid>
      <UploadImageBox
        width="390px"
        height="405px"
        previewImage={previewImage}
        fileToUpload={fileToUpload}
        setFileToUpload={setFileToUpload}
        setPreviewImage={setPreviewImage}
        item={props.selectedItem}
        setDeleteImage={setDeleteImage}
      />
    </Grid>
  );
}
