import { DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { TCreateIAMModalProps } from "./types/TCreateIAMModalProps";
import { iamActions } from "../../slices/iamSlices";
import CreateIAMForm from "../Forms/CreateIAMForm";

export default function CreateIAMModal(props: TCreateIAMModalProps) {
  const dispatch = useAppDispatch();
  const isCreateIAMModalOpen = useAppSelector(
    (state) => state.iam.isCreateIAMModalOpen
  );

  const handleCloseModal = () => {
    dispatch(iamActions.setIsCreateIAMModalOpen(false));
  };

  return (
    <>
      <MuiDialog
        open={isCreateIAMModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        // Handle backdrop click to close modal using slotProps
        slotProps={{
          backdrop: true,
        }}
      >
        <DialogTitle>Création d'un utilisateur</DialogTitle>
        <Grid container className="grid-center" mb={2} p={2}>
          <CreateIAMForm
            roles={props.roles}
            handleCloseModal={handleCloseModal}
            rootUserEmail={props.rootUserEmail}
          />
        </Grid>
      </MuiDialog>
    </>
  );
}
