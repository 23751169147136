import { DeleteOutlineRounded, EditOutlined } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { imageActions } from "../../slices/imageSlice";
import { TPreviewImageBoxProps } from "./TPreviewImageBoxProps";

export default function PreviewImageBox(props: TPreviewImageBoxProps) {
  const dispatch = useAppDispatch();

  const handleOpenUpdateImageModal = () => {
    if (props.mode === "NO_ICONS") {
      dispatch(imageActions.setIsUpdateImageModalOpen(true));
    }
  };

  return (
    <Grid
      container
      sx={{
        width: `100%`,
        height: `100%`,
        maxWidth: "100%",
        maxHeight: "100%",
        position: "relative",
        overflow: "hidden",
        cursor: props.mode === "NO_ICONS" ? "pointer" : "default",
      }}
      onClick={handleOpenUpdateImageModal}
    >
      <img
        src={props.previewImage}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
      />
      {props.mode !== "NO_ICONS" && (
        <>
          <IconButton
            sx={{
              position: "absolute",
              top: "5px",
              right: "55px",
              cursor: "pointer",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "#000",
            }}
            onClick={props.handleEditPreviewImage}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "#000",
            }}
            onClick={props.handleDeletePreviewImage}
          >
            <DeleteOutlineRounded />
          </IconButton>
        </>
      )}
    </Grid>
  );
}
