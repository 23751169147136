import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TGetAllMenuAllergenResponse } from "./type/Responses/TGetAllMenuAllergenResponse";

export const getAllMenuAllergen = createAsyncThunk(
  "menu-allergen/find",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetAllMenuAllergenResponse>(
      dispatch,
      `/api/menu-allergen/find-all/`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
