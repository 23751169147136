import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  addDays,
  addMonths,
  format,
  startOfDay,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { fr } from "date-fns/locale";
import { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomBar from "./CustomBar";
import CustomArrowIcon from "./CustomArrowIcon";
import CustomTooltip from "./CustomTooltip";
import { TEarningChartProps } from "./type/TEarningChartProps";
import { TAggregatedData } from "./type/TAggregatedData";

export default function EarningChart({ payments }: TEarningChartProps) {
  const [sortBy, setSortBy] = useState<"Weekly" | "Daily" | "Monthly">(
    "Weekly"
  );
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [barCoordinate, setBarCoordinate] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleSortChange = (
    event: SelectChangeEvent<"Weekly" | "Daily" | "Monthly">
  ) => {
    setSortBy(event.target.value as "Weekly" | "Daily" | "Monthly");
  };

  const handleMouseEnter = (index: number, event: any) => {
    const coordinate = {
      x: event.x - 65,
      y: event.y - 105,
    };
    setActiveIndex(index);
    setBarCoordinate(coordinate);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
    setBarCoordinate(null);
  };

  const data: TAggregatedData[] = useMemo(() => {
    const now = new Date();
    const weekStartsOn = 1; // 1 for Monday
    switch (sortBy) {
      case "Daily": {
        let dailySortedData: TAggregatedData[] = [];

        // Start the week on Monday
        const weekStart = startOfWeek(now, { weekStartsOn: 1 });

        for (let i = 0; i < 7; i++) {
          const week = addDays(weekStart, i);
          const formattedDate = format(week, "EEE", { locale: fr });

          dailySortedData.push({
            date: week,
            label:
              formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
            value: 0,
          });

          payments.forEach((payment) => {
            const paymentDate = new Date(payment.date);
            if (paymentDate.getDay() === week.getDay()) {
              dailySortedData[i].value += parseFloat(`${payment.amount}`);
            }
          });
        }
        return dailySortedData;
      }
      case "Weekly": {
        let weeklySortedData: TAggregatedData[] = [];
        const startOfCurrentWeek = startOfWeek(now, { weekStartsOn });

        for (let i = 0; i < 4; i++) {
          let startOfWeekDate = addDays(startOfCurrentWeek, -7 * (3 - i));
          let endOfWeekDate = addDays(startOfWeekDate, 6);

          weeklySortedData.push({
            date: startOfWeekDate,
            label: `${i === 3 ? `Semaine Actuelle` : `Semaine ${i + 1}`}`,
            value: 0,
          });

          payments.forEach((payment) => {
            const paymentDate = new Date(payment.date);
            if (
              paymentDate >= startOfWeekDate &&
              paymentDate <= endOfWeekDate
            ) {
              weeklySortedData[i].value += parseFloat(`${payment.amount}`);
            }
          });
        }

        return weeklySortedData;
      }
      case "Monthly":
      default:
        let monthlySortedData: TAggregatedData[] = [];
        for (let i = 0; i < 12; i++) {
          const month = addMonths(startOfYear(now), i);
          const formattedDate = format(month, "MMM ", { locale: fr }); // include year in the formatted date
          monthlySortedData.push({
            date: month,
            label:
              formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
            value: 0,
          });
          payments.forEach((payment) => {
            const paymentDate = new Date(payment.date);
            if (
              paymentDate.getMonth() === month.getMonth() &&
              paymentDate.getFullYear() === month.getFullYear()
            ) {
              monthlySortedData[i].value += parseFloat(`${payment.amount}`);
            }
          });
        }
        return monthlySortedData;
    }
  }, [JSON.stringify(payments), sortBy]);

  const renderCustomAxisTick = (props: any) => {
    const { x, y, payload, index } = props;
    const isActive = index === activeIndex;
    return (
      <text
        x={x}
        y={y + 15}
        fill={isActive ? "#0F123F" : "#666"}
        fontWeight={isActive ? "bold" : "normal"}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    );
  };

  return (
    <Box
      sx={{
        padding: 2,
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        borderRadius: 0,
        backgroundColor: "#fff",
        position: "relative", // Make sure the container is positioned relative
        width: "100%", // Use full width of the container
        height: "auto", // Allow height to adjust based on content
        minHeight: "300px", // Set a minimum height
      }}
    >
      <Typography variant="h6">Revenus</Typography>
      <Typography variant="subtitle2" color="textSecondary">
        Revenus Globaux
      </Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <Select
            value={sortBy}
            onChange={handleSortChange}
            sx={{
              borderColor: "#DAE3F8",
              "& .MuiSelect-icon": {
                color: " #3788E5",
              },
            }}
            IconComponent={CustomArrowIcon}
          >
            <MenuItem value="Weekly">Semaine</MenuItem>
            <MenuItem value="Daily">Jour</MenuItem>
            <MenuItem value="Monthly">Mois</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
          barSize={30}
          onMouseLeave={handleMouseLeave}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke="#DAE3F8"
          />
          <XAxis
            dataKey="label"
            axisLine={false}
            tickLine={false}
            tick={renderCustomAxisTick}
          />
          <YAxis axisLine={false} tickLine={false} tick={{ fill: "#80848C" }} />
          <Tooltip
            content={
              <CustomTooltip
                activeIndex={activeIndex}
                sortedData={data}
                sortedBy={sortBy}
              />
            }
            cursor={{ fill: "transparent" }}
            position={{ x: barCoordinate?.x, y: barCoordinate?.y }}
          />
          <Bar
            dataKey="value"
            fill="url(#gradient)"
            shape={<CustomBar />}
            onMouseEnter={(event, index) => handleMouseEnter(index, event)}
            onMouseLeave={handleMouseLeave}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
