import { useState } from "react";
import { TStarRatingProps } from "./types/TStarRatingProps";
import { ReactComponent as EmptyStar } from "../../../../common/images/star_empty.svg";
import { ReactComponent as FullStar } from "../../../../common/images/star_full.svg";
import { Grid } from "@mui/material";

export const StarRating = (props: TStarRatingProps) => {
  const [hover, setHover] = useState<number>(0);

  return (
    <Grid
      container
      spacing={1}
      direction={"row"}
      justifyContent={"center"}
      mt={1}
    >
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <Grid
            item
            key={index}
            onClick={() => props.setRating(ratingValue)}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(0)}
          >
            {ratingValue <= (hover || props.rating) ? (
              <FullStar />
            ) : (
              <EmptyStar />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
