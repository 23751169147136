import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuCategoryEntity } from "../../../../../common/Entities/IMenuCategoryEntity";
import { TCategorySliceState } from "./types/TCategorySliceState";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";

const initialState: TCategorySliceState = {
  selectedCategory: null,
  isCreateCategoryModalOpen: false,
  isDeleteCategoryModalOpen: false,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedCategory: (
      state,
      action: PayloadAction<IMenuCategoryEntity | null>
    ) => {
      state.selectedCategory = action.payload;
    },
    setIsCreateCategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateCategoryModalOpen = action.payload;
    },
    setIsDeleteCategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteCategoryModalOpen = action.payload;
    },
    // New reducer to sync categories with updated menu data
    syncCategories: (state, action: PayloadAction<IMenuEntity[]>) => {},
  },
});

export const categoryReducer = categorySlice.reducer;

export const categoryActions = categorySlice.actions;
