import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TReviewsState } from "./types/TReviewsState";
import { IReviewsReportsEntity } from "../../../common/Entities/IReviewsReportEntity";

const initialState: TReviewsState = {
  reviewsReport: { reviewsIndicatorList: [], reviewsList: [] },
  averageReviewScore: 0,
};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviewsReport: (state, action: PayloadAction<IReviewsReportsEntity>) => {
      state.reviewsReport = action.payload;
    },
    setAverageReviewScore: (state, action: PayloadAction<number>) => {
      state.averageReviewScore = action.payload;
    },
  },
});

export const reviewsReducer = reviewsSlice.reducer;
export const reviewsActions = reviewsSlice.actions;
