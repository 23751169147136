import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuBox from "../MenuBox/MenuBox";
import MenusNavigationBar from "../MenusNavigationBar/MenusNavigationBar";
import CreateMenuModal from "../Modals/CreateMenuModal";
import { TMainMenuCreationPageProps } from "./types/TMainMenuCreationPageProps";
import UploadImageBox from "../../../image/containers/UploadImageBox/UploadImageBox";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { menuActions } from "../../slice/menuSlice";
import { selectSelectedMenu } from "../../selectors/menuSelector";

export default function MainMenuCreationPage(
  props: TMainMenuCreationPageProps
) {
  let menuRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const dispatch = useAppDispatch();
  const [disableScroll, setDisableScroll] = useState(true);
  const selectedMenu = useAppSelector((state) => selectSelectedMenu(state));

  const scrollToMenu = (menuId: number) => {
    const element = menuRefs.current[menuId];
    if (element && !disableScroll) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    if (props.menuList && props.menuList.length > 0) {
      dispatch(menuActions.setSelectedMenu(props.menuList[0]));
    }
  }, [JSON.stringify(props.menuList)]);
  useEffect(() => {
    if (selectedMenu && menuRefs.current[selectedMenu.id!]) {
      scrollToMenu(selectedMenu.id!);
    }
  }, [JSON.stringify(selectedMenu)]);

  useEffect(() => {
    setDisableScroll(false);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <CreateMenuModal menuList={props.menuList} />
      <Grid container pb={10}>
        <MenusNavigationBar menuList={props.menuList} />
        <Grid item md={12} lg={12} xl={12}>
          <MenuBox menuList={props.menuList} menuRefs={menuRefs} />
        </Grid>
      </Grid>
    </>
  );
}
