import React from "react";
import { useAppDispatch } from "../../../../../common/store/redux";
import { getAccountBalance } from "../../../services/fundsManagementService";
import Stripe from "stripe";
import { fundsManagementActions } from "../slices/fundsManagementSlice";

export default function useFundsManagementRepository() {
  const dispatch = useAppDispatch();

  async function onFindAccountBalance(): Promise<Stripe.Balance> {
    const resultAction = await dispatch(getAccountBalance());

    if (getAccountBalance.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const accountBalance = resultAction.payload.balance;
    dispatch(fundsManagementActions.setStripeBalance(accountBalance));
    let totalAvailableBalance = 0;
    accountBalance.available.reduce((previousBalance, stripeBalance) => {
      return (totalAvailableBalance = previousBalance + stripeBalance.amount);
    }, 0);
    dispatch(fundsManagementActions.setAvailableBalance(totalAvailableBalance));
    return accountBalance;
  }
  return {
    onFindAccountBalance,
  };
}
