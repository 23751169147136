import { Autocomplete, Avatar, Chip, Grid, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TSelectMenuItemAllergenDropdownFieldProps } from "./types/TSelectMenuItemAllergenDropdownFieldProps";
import { CloseOutlined, DeleteOutlined } from "@mui/icons-material";
import { ReactComponent as CustomCancelIcon } from "../../../../../../common/images/custom_cancel_icon.svg";

export default function SelectMenuItemAllergenDropdownField(
  props: TSelectMenuItemAllergenDropdownFieldProps
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (value: any, onChange: (value: any) => void) => {
    onChange(value);
  };

  return (
    <Grid item m={2}>
      <Controller
        name={props.name}
        control={control}
        rules={props.validation}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={props.allergens ?? []}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(e, value) => handleChange(value, field.onChange)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    color: "white",
                    backgroundColor: "#273551",
                    fontSize: "10px",
                    height: "22px",
                    "& .MuiChip-deleteIcon": {
                      color: "black",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: "14px",
                      height: "14px",
                    },
                  }}
                  deleteIcon={<CustomCancelIcon />}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={props.label}
                placeholder={props.placeholder}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ opacity: selected ? 0.5 : 1 }}>
                {option}
              </li>
            )}
            getOptionDisabled={(option) =>
              Array.isArray(field.value) && field.value.includes(option)
            }
          />
        )}
      />
    </Grid>
  );
}
