import { MoreVertOutlined } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Menu, Typography } from "@mui/material";
import React, { useState } from "react";
import { TMoreMenuProps } from "./types/TMoreMenuProps";
import DeleteIAMConfirmationModal from "../Modals/DeleteIAMConfirmationModal";
import UpdateIAMModal from "../Modals/UpdateIAMModal";
import CreateIAMModal from "../Modals/CreateIAMModal";
import useIAMRepository from "../../respository/useIAMRepository";
import { isRoleEditable } from "../../../../utils/roles/shared";

export default function MoreMenu(props: TMoreMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onDeleteIAM, onGenerateNewChefPassword } = useIAMRepository();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [
    isDeleteIAMConfirmationModalOpen,
    setIsDeleteIAMConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [isUpdateIAMModalOpen, setIsUpdateIAMModalOpen] =
    useState<boolean>(false);

  const handleOpenDeleteIAMConfirmationModal = () => {
    setIsDeleteIAMConfirmationModalOpen(true);
  };
  const handleOpenUpdateIAMModal = () => {
    setIsUpdateIAMModalOpen(true);
  };
  const handleDeleteIAM = async () => {
    const isIAMDeleted = await onDeleteIAM({ userId: props.iamId });
    if (isIAMDeleted) {
      setIsDeleteIAMConfirmationModalOpen(false);
    }
  };
  const handleUpdateIAM = () => {};
  const handleGenerateNewChefPassword = () => {
    onGenerateNewChefPassword({ userId: props.iamId });
  };

  return (
    <>
      <DeleteIAMConfirmationModal
        setIsOpen={setIsDeleteIAMConfirmationModalOpen}
        isOpen={isDeleteIAMConfirmationModalOpen}
        handleDeleteIAM={handleDeleteIAM}
        firstName={props.firstName}
        lastName={props.lastName}
      />
      <UpdateIAMModal
        setIsOpen={setIsUpdateIAMModalOpen}
        isOpen={isUpdateIAMModalOpen}
        handleUpdateIAM={handleUpdateIAM}
        firstName={props.firstName}
        lastName={props.lastName}
        roles={props.roles}
        previousRole={props.previousRole}
        userId={props.iamId}
      />
      <IconButton
        sx={{ color: "#344054" }}
        onClick={handleClick}
        disabled={isRoleEditable(props.previousRole)}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Grid
            container
            direction="column"
            spacing={1}
            justifyContent={"center"}
          >
            <Grid item>
              <Button
                sx={{ color: "#ba000d", width: "100%" }}
                onClick={handleOpenDeleteIAMConfirmationModal}
              >
                Supprimer
              </Button>
            </Grid>
            {props.validatedUser && props.previousRole.name !== "CHEF" && (
              <Grid item>
                <Button
                  sx={{ width: "100%" }}
                  onClick={handleOpenUpdateIAMModal}
                >
                  Modifier le rôle
                </Button>
              </Grid>
            )}
            {props.previousRole.name === "CHEF" && (
              <Grid item>
                <Button
                  sx={{ width: "100%" }}
                  onClick={handleGenerateNewChefPassword}
                >
                  Générer nouveaux identifiants
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Menu>
    </>
  );
}
