import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStripeAccountEntity } from "../../../../../common/Entities/IStripeAccountEntity";
import { TStripeAccountState } from "./types/TStripeAccountState";
import Stripe from "stripe";

const initialState: TStripeAccountState = {
  stripeAccount: null,
  updateStripeAccountLink: null,
  createStripeAccountLink: null,
  stripeAccountBalance: null,
  isStripeAccount: true,
  openSuccessModal: false,
  openErrorModal: false,
  bankAccountList: null,
  openCreateBankAccountModal: false,
  openVerifyMicroDepotModal: false,
  loginLink: null,
  stripeAccountNotFound: false,
};

const stripeAccountSlice = createSlice({
  name: "stripe-account",
  initialState,
  reducers: {
    setStripeAccount: (state, action: PayloadAction<IStripeAccountEntity>) => {
      state.stripeAccount = action.payload;
    },
    setStripeAccountNotFound: (state, action: PayloadAction<boolean>) => {
      state.stripeAccountNotFound = action.payload;
    },
    setUpdateStripeAccountLink: (state, action: PayloadAction<string>) => {
      state.updateStripeAccountLink = action.payload;
    },
    setStripeAccountBalance: (state, action: PayloadAction<number>) => {
      state.stripeAccountBalance = action.payload;
    },
    setIsStripeAccount: (state, action: PayloadAction<boolean>) => {
      state.isStripeAccount = action.payload;
    },
    openSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.openSuccessModal = action.payload;
    },
    openErrorModal: (state, action: PayloadAction<boolean>) => {
      state.openErrorModal = action.payload;
    },
    setCreateAccountLink: (state, action: PayloadAction<string>) => {
      state.createStripeAccountLink = action.payload;
    },
    accountCreationSuccess: (state, action: PayloadAction<string>) => {
      state.createStripeAccountLink = action.payload;
    },
    setBankAccountList: (
      state,
      action: PayloadAction<Stripe.ApiList<Stripe.BankAccount>>
    ) => {
      state.bankAccountList = action.payload;
    },
    setOpenCreateBankAccountModal: (state, action: PayloadAction<boolean>) => {
      state.openCreateBankAccountModal = action.payload;
    },
    setOpenVerifyMicroDepotModal: (state, action: PayloadAction<boolean>) => {
      state.openVerifyMicroDepotModal = action.payload;
    },
    setLoginLink: (state, action: PayloadAction<Stripe.LoginLink>) => {
      state.loginLink = action.payload;
    },
  },
});

export const stripeAccountReducer = stripeAccountSlice.reducer;
export const stripeAccountActions = stripeAccountSlice.actions;
