import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAccountState } from "./types/TAccountState";

const initialState: TAccountState = {
  activePage: -1,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload;
    },
  },
});

export const accountReducer = accountSlice.reducer;
export const accountActions = accountSlice.actions;
