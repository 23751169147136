import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { THeaderState } from "./types/THeaderState";

const initialState: THeaderState = {
  controls: false,
};

const slice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<boolean>) => {
      state.controls = action.payload;
    },
  },
});

export const headerReducer = slice.reducer;

export const headerActions = slice.actions;
