import { DialogTitle, Grid, Typography } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { appActions } from "../../store/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { useEffect } from "react";
import useNotificationRepository from "../../modules/Notifications/repository/useNotificationRepository";
import { TSubscribeToNotificationModalProps } from "./types/TSubscribeToNotificationModalProps";

export default function SubscribeToNotificationModal(
  props: TSubscribeToNotificationModalProps
) {
  const dispatch = useAppDispatch();
  const isSubscribeToNotificationModalOpen = useAppSelector(
    (state) => state.app.isSubscribeToNotificationModalOpen
  );
  const { onSubscribeUserToPush } = useNotificationRepository();

  const handleCloseModal = () => {
    dispatch(appActions.setIsSubscribeToNotificationModalOpen(false));
  };

  const requestSubscribeToNotification = async () => {
    // Check if the Notification API is available
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
      return;
    }

    if (
      Notification.permission === "default" ||
      Notification.permission === "denied"
    ) {
      try {
        const response = await Notification.requestPermission();
        if (response === "granted") {
          console.log("Granted", response);
          onSubscribeUserToPush({ userRef: props.userRef, table: props.table });
        } else {
          console.log("Denied");
        }
      } catch (error) {
        console.log("Error subscribing to notifications", error);
      }
    }
  };

  useEffect(() => {
    requestSubscribeToNotification();
  }, []);

  return (
    <MuiDialog
      open={isSubscribeToNotificationModalOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      slotProps={{
        backdrop: true,
      }}
    >
      <DialogTitle>Abonnement aux notifications</DialogTitle>
      <Grid container p={2} justifyContent={"center"}>
        <Typography textAlign={"center"}>
          Il est fortement recommandé de s'abonner aux notifications pour
          pouvoir recevoir l'état de vos commandes en temps réel.
        </Typography>
      </Grid>
    </MuiDialog>
  );
}
