import { Box, Paper, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { useEffect, useState } from "react";
import { getStripeAccount } from "../../../Account/services/stripeAccountService";

export default function RedirectToMenuPreviewPage() {
  const connectedUser = useAppSelector((state) => state.auth.user);
  const redirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/customerMenu/menuSelection?userRef=${connectedUser?.userRef}`;
  const stripeAccount = useAppSelector(
    (state) => state.stripeAccount.stripeAccount
  );
  const stripeAccountNotFound = useAppSelector(
    (state) => state.stripeAccount.stripeAccountNotFound
  );
  const [disabledAccountMessage, setDisabledAccountMessage] =
    useState<string>("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    !stripeAccount && dispatch(getStripeAccount());
  }, []);
  useEffect(() => {
    if (
      (stripeAccount && stripeAccount.chargesEnabled) ||
      stripeAccountNotFound
    ) {
      setDisabledAccountMessage(
        "Les paiements ne sont pas activés pour votre compte stripe. Vous pouvez accéder au menu mais vous ne pourrez pas réaliser de paiements"
      );
    }
  }, [stripeAccount]);

  return connectedUser ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        backgroundColor: "none",
      }}
    >
      <Typography mb={4} sx={{ color: "#f44336" }}>
        {disabledAccountMessage}
      </Typography>
      <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Accéder aux menus
        </Typography>
        <Box sx={{ my: 2 }}>
          <QRCodeSVG value={redirectUrl} size={256} />
        </Box>
        <Typography variant="body1" color="text.secondary">
          Veuillez scanner le QRcode avec votre téléphone mobile pour pouvoir
          accéder aux menus
        </Typography>
      </Paper>
    </Box>
  ) : (
    <></>
  );
}
