import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TMenuItemCustomizationTableProps } from "./types/TMenuItemCustomizationTableProps";
import DeleteIcon from "../DeleteIcon/DeleteIcon";

export default function MenuItemCustomizationTable(
  props: TMenuItemCustomizationTableProps
) {
  return (
    <Grid item m={1} mt={2} sx={{ width: "425px" }}>
      <TableContainer
        sx={{
          border: "2px solid #273551",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Table
          aria-label="supplement table"
          sx={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                  color: "#818181",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Choisissez en {props.menuItemCustomization.maxQuantity}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.menuItemCustomization.menuItemCustomizationOptions.map(
              (option, optionIndex) => {
                return (
                  <TableRow key={optionIndex}>
                    <TableCell
                      sx={{
                        border: "none",
                        color: "black",
                        fontWeight: "500",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {option.name}
                      {props.menuItemCustomization.menuItemCustomizationOptions
                        .length > 1 && (
                        <span style={{ marginLeft: "5px", marginTop: "5px" }}>
                          <DeleteIcon
                            optionIndex={optionIndex}
                            customizationIndex={props.customizationIndex}
                          />
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
