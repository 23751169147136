import { ArrowForward } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { TReviewsPreviewProps } from "./types/TReviewsPreviewProps";
import reviewStarFull from "../../../../common/images/review_star_full.png";
import reviewStarEmpty from "../../../../common/images/review_star_empty.png";
import { useNavigate } from "react-router-dom";

export default function ReviewsPreview(props: TReviewsPreviewProps) {
  const navigate = useNavigate();
  const handleGoToReviews = () => {
    navigate("reviews");
  };
  const showRatingStars = (rating: number): JSX.Element[] => {
    let stars: JSX.Element[] = [];
    for (let i = 0; i < rating; i++) {
      stars.push(
        <img src={reviewStarFull} alt="reviewStarFull" width={15} height={15} />
      );
    }
    for (let i = 0; i < 5 - rating; i++) {
      stars.push(
        <img
          src={reviewStarEmpty}
          alt="reviewStarEmpty"
          width={15}
          height={15}
        />
      );
    }
    return stars;
  };
  const showReviews = () => {
    return props.reviewsReport?.reviewsList.map((review, index) => {
      if (index < 4) {
        return (
          <Grid item xs={12} mt={3}>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={2} md={2} lg={2} sm={2} xl={2}></Grid>
              <Grid item xs={6} md={6} lg={6} sm={6} xl={6}>
                <Typography
                  sx={{ color: "#7F7F7F", fontSize: "12px" }}
                  className="truncated-text"
                >
                  {review.message}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4} xl={4} p={1}>
                {showRatingStars(review.rating).map((star) => star)}
              </Grid>
            </Grid>
          </Grid>
        );
      }
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ maxWidth: "320px" }}
      >
        <Grid item>
          <Typography
            sx={{ fontSize: "20px", color: "#181818", fontWeight: "600" }}
          >
            Revues client récentes
          </Typography>
        </Grid>
        <Grid item sx={{ cursor: "pointer" }} onClick={handleGoToReviews}>
          <Grid container alignItems="center">
            <Typography>Voir tout</Typography>
            <ArrowForward />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sx={{ maxWidth: "320px" }}>
        {showReviews()}
      </Grid>
    </>
  );
}
