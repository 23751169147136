import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { categoryActions } from "../../slice/categorySlice";
import { TDeleteMenuCategoryConfirmationModalProps } from "./types/TDeleteMenuCategoryConfirmationModalProps";

export default function DeleteMenuCategoryConfirmationModal(
  props: TDeleteMenuCategoryConfirmationModalProps
) {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    props.setIsDeleteCategoryModalOpen(false);
  };

  return (
    <>
      <MuiDialog
        open={props.open}
        onClose={handleCloseModal}
        maxWidth="sm"
        // Handle backdrop click to close modal using slotProps
        slotProps={{
          backdrop: true,
        }}
      >
        <DialogTitle>Suppression de la catégorie</DialogTitle>

        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cette catégorie?
        </DialogContent>
        <Grid container className="grid-center" mb={2} spacing={2}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button
              className="blue-round-button"
              onClick={props.handleDeleteMenuCategory}
            >
              Oui
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button className="blue-round-button" onClick={handleCloseModal}>
              Non
            </Button>
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
}
