import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CustomArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1295_1101)">
        <path
          d="M4 6.77148L8 10.7715L12 6.77148"
          stroke="#3788E5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_1295_1101">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.771484)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
