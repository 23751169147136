import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { customFormActions } from "../../../common/store/customFormSlice";
import { authActions } from "../slices/authSlice";

import { TOnLogoutRequest } from "./types/Requests/TOnLogoutRequest";
import { TOnRefreshUserByTokenRequest } from "./types/Requests/TOnRefreshUserByTokenRequest";
import { TOnSignInRequest } from "./types/Requests/TOnSignInRequest";
import { TOnSignUpRequest } from "./types/Requests/TOnSignUpRequest";
import { TOnRefreshUserByTokenResponse } from "./types/Responses/TOnRefreshUserByTokenResponse";
import { TOnSignInResponse } from "./types/Responses/TOnSignInResponse";
import { TOnSignupResponse } from "./types/Responses/TOnSignUpResponse";
import {
  clearAccessToken,
  clearRefreshToken,
  clearTokens,
  retrieveAccessToken,
  storeAccessToken,
  storeRefreshToken,
} from "../../../utils/crypto";
import { createMenu } from "../../Menu/modules/menu/service/menusServices";
import { TAuthenticateUserRequest } from "./types/Requests/TAuthenticateUserRequest";
import { TAuthenticateUserResponse } from "./types/Responses/TAuthenticateUserResponse";
import { TApiErrorResponse } from "../../../common/modules/temporary/types/TApiResponse";

export const signInUser = createAsyncThunk(
  "user/signin",
  async (request: TOnSignInRequest, { dispatch }) => {
    const response = await apiRequest<TOnSignInResponse>(
      dispatch,
      "/api/user/signin",
      MethodEnum.POST,
      request
    );

    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const signUpUser = createAsyncThunk(
  "user/signup",
  async (request: TOnSignUpRequest, { dispatch }) => {
    const response = await apiRequest<TOnSignupResponse>(
      dispatch,
      "/api/user/signup",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const refreshUser = createAsyncThunk(
  "user/refresh",
  async (request: TOnRefreshUserByTokenRequest, { dispatch }) => {
    const response = await apiRequest<TOnRefreshUserByTokenResponse>(
      dispatch,
      "/api/user/refresh",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      if (response.data.authToken.refreshToken) {
        clearRefreshToken();
        storeRefreshToken(response.data.authToken.refreshToken);
      }
      clearAccessToken();
      storeAccessToken(response.data.authToken.accessToken);
      dispatch(authActions.refreshUserSuccess(response.data.user));
    } else {
      clearTokens();
      dispatch(authActions.refreshUserFailure());
    }
  }
);

export const signOut = createAsyncThunk(
  "user/logout",
  async (request: TOnLogoutRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest(
      dispatch,
      "/api/user/logout",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const authenticateUser = createAsyncThunk(
  "user/authenticate",
  async (request: TAuthenticateUserRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TAuthenticateUserResponse>(
      dispatch,
      `/api/user/authenticate/${request.userRef}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
function rejectWithValue(response: TApiErrorResponse): any {
  throw new Error("Function not implemented.");
}
