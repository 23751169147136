import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { itemActions } from "../../slice/itemSlice";
import { TDeleteMenuItemConfirmationModalProps } from "./types/TDeleteMenuItemConfirmationModalProps";
import useMenuItemRepository from "../../repository/useMenuItemRepository";

export default function DeleteMenuItemConfirmationModal(
  props: TDeleteMenuItemConfirmationModalProps
) {
  const isDeleteMenuItemModalOpen = useAppSelector(
    (state) => state.item.isDeleteMenuItemModalOpen
  );
  const { onDeleteMenuItem } = useMenuItemRepository({
    selectedCategory: props.selectedCategory,
    selectedMenu: props.selectedMenu,
  });
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(itemActions.setIsDeleteMenuItemModalOpen(false));
  };

  const handleDeleteMenu = async () => {
    const deleted = await onDeleteMenuItem({ menuItem: props.selectedItem });
    if (deleted) {
      handleCloseModal();
    }
    return deleted;
  };

  return (
    <>
      <MuiDialog
        open={isDeleteMenuItemModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        // Handle backdrop click to close modal using slotProps
        slotProps={{
          backdrop: true,
        }}
      >
        <DialogTitle>Suppression de l'article</DialogTitle>

        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cet article?
        </DialogContent>
        <Grid container className="grid-center" mb={2} spacing={2}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button className="blue-round-button" onClick={handleDeleteMenu}>
              Oui
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button className="blue-round-button" onClick={handleCloseModal}>
              Non
            </Button>
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
}
