import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { useNavigate } from "react-router-dom";
import { TShowCartButtonProps } from "./types/TShowCartButtonProps";

export default function ShowCartButton(props: TShowCartButtonProps) {
  const cart = useAppSelector((state) => state.cart.currentCart);
  const [cartQuantity, setCartQuantity] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGoToCart = () => {
    navigate(
      `/customerMenu/validation?userRef=${props.userRef}&table=${props.table}&menuId=${props.menuId}`
    );
  };
  useEffect(() => {
    if (cart?.categories && cart?.categories.length > 0) {
      let quantity = 0;
      cart?.categories.forEach((category) => {
        category.items.forEach((item) => {
          quantity += item.quantity;
        });
      });
      setCartQuantity(quantity);
    } else {
      setCartQuantity(0);
    }
  }, [JSON.stringify(cart), dispatch]);
  return (
    <>
      {cartQuantity > 0 && (
        <>
          <Box
            sx={{
              whiteSpace: "nowrap",
              alignItems: "center",
              textAlign: "center",
              position: "fixed",
              bottom: 0,
              width: "100%",
              zIndex: 1000,
              paddingBottom: "25px",
            }}
          >
            <Grid container xs={12} className="grid-center">
              <Grid item xs={7}>
                <Button onClick={handleGoToCart} className="blue-round-button">
                  Votre Panier ({cartQuantity})
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Grid xs={12} className="grid-center" mb={2}>
            <Box
              sx={{
                whiteSpace: "nowrap",
                alignItems: "center",
                textAlign: "center",
                position: "fixed",
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.88)",
                zIndex: 1000,
                height: "25px",
              }}
            ></Box>
          </Grid>
        </>
      )}
    </>
  );
}
