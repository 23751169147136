import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { appActions } from "../../store/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import AppHeader from "../Header/AppHeader";
import ErrorModal from "./ErrorModal";
import RequiredInformationModal from "./RequiredInformationModal";
import SuccessModal from "./SuccessModal";
import InformationModal from "./InformationModal";
import useNotificationService from "../../hooks/useNotificationService/useNotificationService";
import SubscribeToNotificationModal from "../SubscribeToNotificationModal/SubscribeToNotificationModal";

export default function App({ children }: PropsWithChildren) {
  const errorMessage = useAppSelector((state) => state.app.errorMessage);
  const successMessage = useAppSelector((state) => state.app.successMessage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (errorMessage !== "") {
      dispatch(appActions.setShowErrorModal(true));
    }
    if (successMessage !== "") {
      dispatch(appActions.setShowSuccessModal(true));
    }
  }, [errorMessage, successMessage]);
  const customerMenuLoading = useAppSelector(
    (state) => state.app.customerMenuLoading
  );
  const appLoading = useAppSelector((state) => state.app.appLoading);
  const location = useLocation();
  const isCustomerMenu = location.pathname.startsWith("/customerMenu");
  useNotificationService();
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <RequiredInformationModal />
        <InformationModal />
        <ErrorModal />
        <SuccessModal />
        <Grid container>
          {isCustomerMenu ? (
            <>
              {customerMenuLoading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100vh"
                  width="100%"
                  sx={{
                    backgroundColor: "white",
                    position: "fixed",
                    top: "0",
                    zIndex: "9999999",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          ) : (
            <>
              {appLoading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100vh"
                  width="100%"
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    position: "fixed",
                    top: "0",
                    zIndex: "9999999",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                sx={{
                  display: isMobile ? "none" : "flex",
                }}
              >
                <AppHeader />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12} xl={12}>
            {children}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}
