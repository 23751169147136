import React from "react";
import { useAppDispatch } from "../../../common/store/redux";
import { TFindAvailableMenuRequest } from "../../Menu/modules/menu/service/Requests/TFindAvailableMenuRequest";
import { findAvailableMenus } from "../services/menuPreviewService";
import { IMenuEntity } from "../../../common/Entities/IMenuEntity";
import { menuPreviewActions } from "../slices/menuPreviewSlice";
import { TGetMenuRequest } from "../../Menu/modules/menu/service/Requests/TGetMenuRequest";
import { getMenu } from "../../Menu/modules/menu/service/menusServices";
import useAuthRepository from "../../Auth/repository/useAuthRepository";
import { TAuthenticateUserRequest } from "../../Auth/services/types/Requests/TAuthenticateUserRequest";

export default function useMenuPreviewRepository() {
  const dispatch = useAppDispatch();
  const { onAuthenticateUser } = useAuthRepository();

  const onFindAvailableMenus = async (
    requestData: TFindAvailableMenuRequest
  ): Promise<boolean> => {
    dispatch(menuPreviewActions.setLoading(true));
    const resultAction = await dispatch(findAvailableMenus(requestData));

    if (findAvailableMenus.rejected.match(resultAction)) {
      dispatch(menuPreviewActions.setLoading(false));
      return false;
    }
    const availableMenus: IMenuEntity[] = resultAction.payload.menus;
    dispatch(menuPreviewActions.setAvailableMenus(availableMenus));
    dispatch(menuPreviewActions.setLoading(false));
    return true;
  };

  const onFindMenu = async (requestData: TGetMenuRequest): Promise<boolean> => {
    dispatch(menuPreviewActions.setLoading(true));
    const resultAction = await dispatch(getMenu(requestData));

    if (getMenu.rejected.match(resultAction)) {
      dispatch(menuPreviewActions.setLoading(false));
      return false;
    }
    const foundMenu: IMenuEntity = resultAction.payload.menu;
    dispatch(menuPreviewActions.setMenu(foundMenu));
    dispatch(menuPreviewActions.setLoading(false));
    return true;
  };

  const onVerifyUserRef = async (
    request: TAuthenticateUserRequest
  ): Promise<boolean> => {
    dispatch(menuPreviewActions.setLoading(true));
    const authenticatedUser = await onAuthenticateUser(request);
    if (!authenticatedUser) {
      dispatch(menuPreviewActions.setLoading(false));
      dispatch(menuPreviewActions.setAuthenticationFailed(true));
      return false;
    }
    dispatch(menuPreviewActions.setAuthenticatedUser(authenticatedUser));
    dispatch(menuPreviewActions.setLoading(false));
    dispatch(menuPreviewActions.setAuthenticationSucceeded(true));
    return true;
  };
  return { onFindAvailableMenus, onFindMenu, onVerifyUserRef };
}
