import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TCreateReviewRequest } from "./types/Requests/TCreateReviewRequest";
import { TCreateReviewResponse } from "./types/Responses/TCreateReviewResponse";
import { TOnGetAllReviewsResponse } from "./types/Responses/TOnGetAllReviewsResponse";

export const findAllReviews = createAsyncThunk(
  "reviews/find-all",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TOnGetAllReviewsResponse>(
      dispatch,
      "/api/reviews/find-all",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const createReview = createAsyncThunk(
  "reviews/create",
  async (request: TCreateReviewRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TCreateReviewResponse>(
      dispatch,
      "/api/reviews/create",
      MethodEnum.POST,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
