import { Grid, Typography } from "@mui/material";
import React from "react";
import { TNotificationBoxProps } from "./types/TNotificationBoxProps";

export const notificationTitleStyles = {
  PAID_FULLY: {
    title_part3_color: "#00AC4F",
    title_part2: "a réglé ",
    title_part3: "entièrement l'addition",
  },
  PAID_PARTIALLY: {
    title_part3_color: "#E09C4A",
    title_part2: "a ",
    title_part3: "payé partiellement",
  },
  NEW_ORDER: {
    title_part3_color: "#00AC4F",
    title_part2: "a effectué une ",
    title_part3: "nouvelle commande",
  },
  ORDER_REJECTED: {
    title_part3_color: "#F80000",
    title_part2: "a un ou des ",
    title_part3: "plats rejeté(s)",
  },
  REVIEW: {
    title_part3_color: "#00AC4F",
    title_part2: "a laissé un",
    title_part3: "nouvel avis",
  },
  TIP: {
    title_part3_color: "#00AC4F",
    title_part2: "a laissé un",
    title_part3: "pourboire",
  },
};

export default function NotificationBox(props: TNotificationBoxProps) {
  const getTitle = () => {
    return (
      <Typography sx={{ fontSize: "14px", color: "#1E293B" }}>
        <span style={{ fontWeight: "bold" }}>La table {props.table}</span>{" "}
        {notificationTitleStyles[props.type].title_part2}{" "}
        <span
          style={{
            color: notificationTitleStyles[props.type].title_part3_color,
            fontWeight: "bold",
          }}
        >
          {notificationTitleStyles[props.type].title_part3}
        </span>
      </Typography>
    );
  };
  return (
    <Grid container direction={"row"} p={1} alignItems={"center"}>
      <Grid item xs={3} md={3}>
        {props.table}
      </Grid>
      <Grid item xs={9} md={9}>
        <Grid container direction={"column"} spacing={1}>
          <Grid item>{getTitle()}</Grid>
          <Grid item>{props.message}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
