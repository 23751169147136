import { Grid, Typography } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import reviewStarFull from "../../../../common/images/review_star_full.png";
import reviewStarEmpty from "../../../../common/images/review_star_empty.png";
import { TReviewMessageBoxProps } from "./types/TReviewMessageBoxProps";
import "../../../../common/containers/styles/customFonts.css";
import { ZoomOutMapRounded } from "@mui/icons-material";
import FullReviewMessageModal from "../Modal/FullReviewMessageModal";

export default function ReviewMessageBox(props: TReviewMessageBoxProps) {
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <img
          key={i}
          src={i <= rating ? reviewStarFull : reviewStarEmpty}
          alt={i <= rating ? "full star" : "empty star"}
          width={"12px"}
          height={"12px"}
        />
      );
    }
    return stars;
  };

  const handleScroll = () => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const isScrollable =
        contentElement.scrollHeight > contentElement.clientHeight;
      setIsScrollable(isScrollable);
    }
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      handleScroll(); // Initial check to see if scrollable
      contentElement.addEventListener("scroll", handleScroll);
      return () => {
        contentElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  // Helper function to format minutes with two digits
  const formatTimeWithTwoDigits = (time: number) => {
    return time.toString().padStart(2, "0");
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const showDate = (): JSX.Element => {
    return (
      <Typography sx={{ color: "#464E5F" }} className="font-manrope">
        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
          {" "}
          {`${new Date(props.review.date).toLocaleDateString()}`}
          {` (${new Date(props.review.date).getHours()}:${formatTimeWithTwoDigits(
            new Date(props.review.date).getMinutes()
          )})`}
        </span>
      </Typography>
    );
  };

  return (
    <div>
      <FullReviewMessageModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        review={props.review}
      />
      <Grid
        item
        container
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: "1px 1px 1.5px 0 rgba(0, 0, 0, 0.25)",
          height: "220px",
        }}
        padding={2}
        mb={2}
      >
        <Grid
          container
          sx={{ fontSize: "5px", color: "	#787878", cursor: "pointer" }}
          justifyContent={"end"}
          mb={1}
          onClick={handleOpenModal}
        >
          <ZoomOutMapRounded />
        </Grid>
        <Grid
          item
          sx={{
            border: "1px solid",
            borderColor: "rgba(24, 24, 24, 0.18)",
            height: "1px",
          }}
          xs={12}
          md={12}
          lg={12}
          xl={12}
        ></Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          mt={1}
          ref={contentRef}
          sx={{
            position: "relative",
            height: "50%",
            overflowY: "auto",
            paddingRight: "10px", // Give some padding to avoid scrollbar overlay on text
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "rgba(136, 136, 136, 0.04)", // Make the scrollbar thumb more transparent initially
              borderRadius: "10px",
            },
            "&:hover::-webkit-scrollbar-track": {
              backgroundColor: "rgba(136, 136, 136, 0.1)", // Make the scrollbar thumb more transparent initially
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              transition: "background-color 0.3s ease", // Smooth transition for background color
            },
            "&:hover::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(136, 136, 136, 0.8)", // Make scrollbar thumb more visible on hover
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(136, 136, 136, 1)", // Even more visible when hovering over the scrollbar
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "11px",
              color: "#464E5F",
              position: "relative",
            }}
          >
            {props.review.message}
          </Typography>

          {isScrollable && (
            <div
              style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                right: 0,
                height: "30px", // Height of the fading effect
                background: "linear-gradient(transparent, white)",
                pointerEvents: "none", // Ensures the gradient doesn't interfere with scroll interactions
              }}
            />
          )}
        </Grid>
        <Grid
          item
          sx={{
            border: "1px solid",
            borderColor: "rgba(24, 24, 24, 0.18)",
            height: "1px",
          }}
          xs={12}
          md={12}
          lg={12}
          xl={12}
          mt={1}
          mb={1}
        ></Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {renderStars(props.review.rating)}
        </Grid>
        <Grid container xs={12} md={12} lg={12} xl={12}>
          <Grid item mr={2} xs={8} md={8} lg={8} xl={8}>
            {showDate()}
          </Grid>
          <Grid container xs={3} md={3} lg={3} xl={3} justifyContent={"end"}>
            <Typography sx={{ fontWeight: "bold" }}>
              {props.review.tableNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
