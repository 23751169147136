import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import NotificationBox from "../NotificationBox/NotificationBox";
import { useAppSelector } from "../../../../common/store/redux";
import useNotificationRepository from "../../../../common/modules/Notifications/repository/useNotificationRepository";

export default function WaiterMainPage() {
  const notifications = useAppSelector(
    (state) => state.notification.notifications
  );
  const { onFindAllNotifications } = useNotificationRepository();

  useEffect(() => {
    !notifications && onFindAllNotifications();
  }, [notifications, onFindAllNotifications]);
  return notifications ? (
    <Grid container>
      <Grid item mb={5}>
        <Typography
          sx={{ fontSize: "23px", fontWeight: "600" }}
          className="font-poppins"
        >
          Notifications
        </Typography>
      </Grid>
      {notifications.map((notification) => {
        return (
          <>
            <NotificationBox
              table={notification.table}
              type={notification.type}
              message={notification.content}
            />
            <Box width={"100%"}>
              <Divider />
            </Box>
          </>
        );
      })}
    </Grid>
  ) : (
    <>Chargement des notifications...</>
  );
}
