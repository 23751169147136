import { Box, Grid, Typography } from "@mui/material";
import { TCartRecapHeader } from "./types/TCartRecapHeader";
import CartRecapStateBox from "./CartRecapStateBox";

export default function CartRecapHeader(props: TCartRecapHeader) {
  return (
    <div>
      <Grid
        container
        justifyContent={"space-between"}
        direction={"row"}
        sx={{ display: "flex" }}
      >
        <Grid item>
          <Grid item>
            <Typography
              sx={{
                color: props.cartState === "CANCELED" ? "red" : "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              className="font-lexend-deca"
              gutterBottom
            >
              {props.cartState === "CANCELED"
                ? "Commande rejetée"
                : "Détails de la commande"}
            </Typography>
          </Grid>
          <Grid item>
            {!props.hideNumberOfItems && (
              <Typography
                sx={{ color: "black", fontSize: "18px", fontWeight: "300" }}
                className="font-lexend-deca"
                gutterBottom
              >
                {props.numberOfItems} Articles
              </Typography>
            )}
          </Grid>
        </Grid>
        {props.cartState && <CartRecapStateBox cartState={props.cartState} />}
      </Grid>
    </div>
  );
}
