import React from "react";
import { useAppDispatch } from "../../../../../common/store/redux";
import { TOnCreateMenuItemCustomizationRequest } from "../services/types/Requests/TOnCreateMenuItemCustomizationRequest";
import { TOnCreateMenuItemCustomizationResponse } from "../services/types/Responses/TOnCreateMenuItemCustomizationResponse";
import {
  createMenuItemCustomization,
  updateMenuItemCustomizationFromLists,
} from "../services/menuItemCustomizationServices";
import { menuItemCustomizationActions } from "../slice/menuItemCustomizationSlice";
import { IMenuItemCustomizationEntity } from "../../../../../common/Entities/IMenuItemCustomizationEntity";
import { IMenuItemCustomizationOption } from "../../../../../common/Entities/IMenuItemCustomizationOption";
import { TOnUpdateMenuItemCustomizationFromListRequest } from "../services/types/Requests/TOnUpdateMenuItemCustomizationFromListRequest";

export default function useMenuItemCustomizationRepository() {
  const dispatch = useAppDispatch();

  async function onUpdateMenuItemCustomizationFromLists(
    requestData: TOnUpdateMenuItemCustomizationFromListRequest
  ): Promise<void> {
    const resultAction = await dispatch(
      updateMenuItemCustomizationFromLists(requestData)
    );

    if (updateMenuItemCustomizationFromLists.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
  }
  async function onCreateMenuItemCustomization(
    requestData: TOnCreateMenuItemCustomizationRequest
  ): Promise<IMenuItemCustomizationEntity> {
    const resultAction = await dispatch(
      createMenuItemCustomization(requestData)
    );

    if (createMenuItemCustomization.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const createdMenuItemCustomization = resultAction.payload;
    dispatch(
      menuItemCustomizationActions.addMenuItemCustomization(
        createdMenuItemCustomization
      )
    );
    return createdMenuItemCustomization;
  }

  async function onUpdateItemCustomization(
    requestData: IMenuItemCustomizationEntity[]
  ): Promise<IMenuItemCustomizationEntity[]> {
    //Send the service
    requestData.map((updateMenuItemCustomization, index) => {
      dispatch(
        menuItemCustomizationActions.updateMenuItemCustomizations({
          menuItemCustomizationListIndex: index,
          newMenuItemCustomization: updateMenuItemCustomization,
        })
      );
    });

    return requestData;
  }

  function onAddItemCustomization(
    menuItemCustomiztion: IMenuItemCustomizationEntity
  ): void {
    //Update only the table view
    dispatch(
      menuItemCustomizationActions.addMenuItemCustomization(
        menuItemCustomiztion
      )
    );
  }

  function onUpdateItemCustomizationTable(requestData: {
    index: number;
    newMenuItemCustomization: IMenuItemCustomizationEntity;
  }): void {
    //Update only the table view
    dispatch(
      menuItemCustomizationActions.updateMenuItemCustomizations({
        menuItemCustomizationListIndex: requestData.index,
        newMenuItemCustomization: requestData.newMenuItemCustomization,
      })
    );
  }

  function onSetMenuItemCustomizationList(
    requestData: IMenuItemCustomizationEntity[]
  ): void {
    //Set fake list here
    /* const menuItemCustomizationOptions: IMenuItemCustomizationOption[] = [
      {
        name: "Rouge",
      },
      {
        name: "Vert",
      },
    ];
    const fake: IMenuItemCustomizationEntity[] = [
      {
        name: "Couleur",
        menuItemId: 1,
        required: false,
        menuItemCustomizationOptions: menuItemCustomizationOptions,
        maxQuantity: 2,
      },
    ];*/
    dispatch(
      menuItemCustomizationActions.setMenuItemCustomizations(requestData)
    );
  }
  function onDeleteMenuItemCustomizationOption(requestData: {
    customizationIndex: number;
    optionIndex: number;
  }): void {
    dispatch(
      menuItemCustomizationActions.deleteMenuItemCustomizationOption({
        menuItemCustomizationListIndex: requestData.customizationIndex,
        menuItemCustomizationOptionIndex: requestData.optionIndex,
      })
    );
  }

  function onDeleteMenuItemCustomization(requestData: {
    customizationIndex: number;
  }): void {
    dispatch(
      menuItemCustomizationActions.deleteMenuItemCustomization({
        customizationIndex: requestData.customizationIndex,
      })
    );
  }
  return {
    onCreateMenuItemCustomization,
    onUpdateItemCustomization,
    onUpdateItemCustomizationTable,
    onSetMenuItemCustomizationList,
    onDeleteMenuItemCustomizationOption,
    onDeleteMenuItemCustomization,
    onAddItemCustomization,
    onUpdateMenuItemCustomizationFromLists,
  };
}
