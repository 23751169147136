import React, { useEffect, useState } from "react";
import UploadImageBox from "../UploadImageBox/UploadImageBox";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { imageActions } from "../../slices/imageSlice";
import UpdateImageModal from "../Modals/UpdateImageModal";
import useImageUpload from "../../hooks/useImageUpload";
import { TUploadImageBoxWithModalProps } from "./types/TUploadImageBoxWithModalProps";

export default function UploadImageBoxWithModal(
  props: TUploadImageBoxWithModalProps
) {
  const {
    fileToUpload,
    setFileToUpload,
    previewImage,
    setPreviewImage,
    showRequirementMessage,
    setDeleteImage,
    deleteImage,
  } = useImageUpload();
  const dispatch = useAppDispatch();
  const imageSlicePreviewImage = useAppSelector(
    (state) => state.image.previewImage
  );
  const [isUpdateImageModalOpen, setIsUpdateImageModalOpen] =
    useState<boolean>(false);

  const handleOpenModal = () => {
    setIsUpdateImageModalOpen(true);
  };

  useEffect(() => {
    if (fileToUpload) {
      dispatch(imageActions.setFileToUpload(fileToUpload));
      dispatch(imageActions.setPreviewImage(previewImage));
      handleOpenModal();
    }
  }, [previewImage, fileToUpload]);

  useEffect(() => {
    if (previewImage) {
      setPreviewImage(previewImage);
    } else {
      setPreviewImage(props.item.menuItemImage.image.url);
    }
  }, []);

  return (
    <div>
      <UpdateImageModal
        fileToUpload={fileToUpload}
        previewImage={previewImage}
        selectedCategory={props.selectedCategory}
        selectedMenu={props.selectedMenu}
        setFileToUpload={setFileToUpload}
        setPreviewImage={setPreviewImage}
        showRequirementMessage={showRequirementMessage}
        setDeleteImage={setDeleteImage}
        deleteImage={deleteImage}
        isUpdateImageModalOpen={isUpdateImageModalOpen}
        setIsUpdateImageModalOpen={setIsUpdateImageModalOpen}
        selectedItem={props.item}
      />

      <div onClick={handleOpenModal}>
        <UploadImageBox
          width="220px"
          height="125px"
          previewImage={previewImage}
          fileToUpload={fileToUpload}
          mode={"NO_ICONS"}
          setFileToUpload={setFileToUpload}
          setPreviewImage={setPreviewImage}
          item={props.item}
          setDeleteImage={setDeleteImage}
        />
      </div>
    </div>
  );
}
