// CustomNumberField.tsx
import React from "react";
import {
  Grid,
  InputAdornment,
  TextField as MUITextField,
  TextField,
} from "@mui/material";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

interface CustomNumberFieldProps {
  name: string;
  label: string;
  variant?: "standard" | "filled" | "outlined";
  validation?: RegisterOptions;
  currency: string;
  defaultValue?: number;
  multiline?: boolean;
  rows?: number;
  max?: number;
  onValueChange?: (value: number) => void;
}

const CustomNumberField: React.FC<CustomNumberFieldProps> = ({
  name,
  label,
  validation,
  currency = "€",
  defaultValue,
  onValueChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    value = value.replace(/[^0-9.]/g, "");

    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts[1] && parts[1].length > 2) {
      value = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }

    event.target.value = value;

    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      onValueChange && onValueChange(numValue);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <TextField
            {...field}
            value={field.value || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleNumberInput(event);
              field.onChange(event);
            }}
            label={label}
            variant="outlined"
            type="text"
            error={!!errorMessage}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{currency}</InputAdornment>
              ),
              inputProps: {
                style: { textAlign: "right" },
                inputMode: "decimal",
              },
            }}
            fullWidth
          />
        </>
      )}
    />
  );
};

export default CustomNumberField;
