import { DialogTitle } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import UpdateMenuItemCustomizationForm from "../Forms/UpdateMenuItemCustomizationForm";
import { TUpdateMenuItemCustomizationModalProps } from "./types/TUpdateMenuItemCustomizationModalProps";

export default function UpdateMenuItemCustomizationModal(
  props: TUpdateMenuItemCustomizationModalProps
) {
  const handleCloseModal = () => {
    props.setIsOpen(false);
  };
  return (
    <>
      <MuiDialog open={props.isOpen} onClose={handleCloseModal} maxWidth="sm">
        <DialogTitle>Modification de la personalisation</DialogTitle>
        <UpdateMenuItemCustomizationForm
          menuItemCustomization={props.menuItemCustomization}
          custoIndex={props.custoIndex}
          handleCloseModal={handleCloseModal}
        />
      </MuiDialog>
    </>
  );
}
