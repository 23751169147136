import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import CustomMenuTimePicker from "./CustomMenuTimePicker";
import { TUpdateMEnuTimeframeFormAttributes } from "./types/TUpdateMEnuTimeframeFormAttributes";
import { TUpdateMenuTimeframeFormProps } from "./types/TUpdateMenuTimeframeFormProps";
import useMenuRepository from "../../repository/useMenuRepository";

const UpdateMenuTimeframeForm = (props: TUpdateMenuTimeframeFormProps) => {
  const dispatch = useAppDispatch();
  const { onUpdateMenu } = useMenuRepository();

  const submitHandler: SubmitHandler<
    TUpdateMEnuTimeframeFormAttributes
  > = async (data) => {
    const activeFromDate = data.activeFrom.toDate();
    const activeToDate = data.activeTo.toDate();
    if (props.selectedMenu.activeFrom && props.selectedMenu.activeTo) {
      if (
        activeFromDate.getTime() === props.selectedMenu.activeFrom.getTime() &&
        activeToDate.getTime() === props.selectedMenu.activeTo.getTime()
      ) {
        throw new Error("Veuillez modifier l'heure de début ou l'heure de fin");
      }
    }

    const newMenu: IMenuEntity = {
      ...props.selectedMenu,
      activeFrom: activeFromDate,
      activeTo: activeToDate,
    };
    const updatedMenu = await onUpdateMenu({ menu: newMenu });

    if (updatedMenu.menuType.type === "MORNING") {
      //Update default menu
      const defaultMenu = props.menuList.find(
        (menu) => menu.menuType.type === "DEFAULT"
      );
      if (defaultMenu && defaultMenu.activeFrom && defaultMenu.activeTo) {
        if (
          activeToDate.getTime() !== props.selectedMenu.activeTo?.getTime() ||
          activeFromDate.getTime() !== props.selectedMenu.activeFrom?.getTime()
        ) {
          return await onUpdateMenu({
            menu: {
              ...defaultMenu,
              activeFrom: activeToDate,
              activeTo: activeFromDate,
            },
          });
        }
      }
    }
    return updatedMenu;
  };

  const successHandler = () => {
    props.onClose();
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Modifier la plage horaire"
      onSuccess={successHandler}
      name="update-menu-timeframe-form"
      defaultErrorDisplayCollapse={true}
    >
      <CustomMenuTimePicker selectedMenu={props.selectedMenu} />
    </CustomForm>
  );
};

export default UpdateMenuTimeframeForm;
