import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TFindAvailableMenuRequest } from "./Requests/TFindAvailableMenuRequest";
import { TFindAvailableMenuResponse } from "./Responses/TFindAvailableMenuResponse";

export const findAvailableMenus = createAsyncThunk(
  "menu/find-available",
  async (request: TFindAvailableMenuRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindAvailableMenuResponse>(
      dispatch,
      "/api/menu/find-available",
      MethodEnum.GET,
      request,
      false,
      request.userRef
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
    /*console.log("dans le start", request.userRef);
    if (response.success) {
      console.log("menuFound", response.data.menu);
      dispatch(menuPreviewActions.setMenu(response.data.menu));
    }*/
  }
);
