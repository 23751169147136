import { Grid } from "@mui/material";
import CartItemCheckbox from "../../modules/Payment/containers/CheckBox/CartItemCheckbox";
import { CartItemDeleteIcon } from "../../modules/Validation/containers/DeleteIcon/DeleteIcon";
import { TCartItemLeftIconProps } from "./types/TCartItemLeftIconProps";

export default function CartItemLeftIcon(props: TCartItemLeftIconProps) {
  return (
    <>
      {props.iconType == "checkbox" && (
        <CartItemCheckbox cartItem={props.cartItem} />
      )}
      {props.iconType == "delete" && (
        <Grid className="grid-center" xs={12}>
          <CartItemDeleteIcon cartIndexes={props.cartIndexes} />
        </Grid>
      )}
    </>
  );
}
