import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TCustomCheckboxFieldProps } from "./types/TCustomCheckboxFieldProps";

export default function CustomCheckboxField(props: TCustomCheckboxFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          label={props.label}
          control={
            <Checkbox
              {...field}
              sx={{ color: "#9e9e9e", fontSize: "14px" }}
              checked={field.value}
            />
          }
        />
      )}
    />
  );
}
