import React from "react";
import { TCartItemQuantityIconProps } from "./types/TCartItemQuantityIconProps";
import { Grid } from "@mui/material";

export default function CartItemQuantityIcon(
  props: TCartItemQuantityIconProps
) {
  return (
    <>
      <Grid xs={12} paddingLeft={2}>
        <span
          className={
            props.rejected
              ? "listItem-heading-medium-secondary-red"
              : "listItem-heading-medium-secondary"
          }
        >
          x{props.quantity}
        </span>
      </Grid>
    </>
  );
}
