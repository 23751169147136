import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TCreateMenuItemAllergenRequest } from "./type/Requests/TCreateMenuItemAllergenRequest";
import { TDeleteMenuItemAllegenRequest } from "./type/Requests/TDeleteMenuItemAllegenRequest";
import { TGetAllMenuItemAllergenRequest } from "./type/Requests/TGetAllMenuItemAllergenRequest";
import { TGetMenuItemAllergenRequest } from "./type/Requests/TGetMenuItemAllergenRequest";
import { TOnUpdateMenuItemAllergenFromListRequest } from "./type/Requests/TUpdateMenuItemAllergenFromListRequest";
import { TCreateMenuItemAllergenResponse } from "./type/Responses/TCreateMenuItemAllergenResponse";
import { TGetAllMenuItemAllergenResponse } from "./type/Responses/TGetAllMenuItemAllergenResponse";
import { TGetMenuItemAllergenResponse } from "./type/Responses/TGetMenuItemAllergenResponse";

export const getMenuItemAllergen = createAsyncThunk(
  "menu-item-allergen/find",
  async (
    request: TGetMenuItemAllergenRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TGetMenuItemAllergenResponse>(
      dispatch,
      `/api/menu-item-allergen/find/${request.menuItemAllergenId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
    } else {
      return rejectWithValue(response);
    }
  }
);

export const getAllMenuItemAllergen = createAsyncThunk(
  "menu-item-allergen/find",
  async (
    request: TGetAllMenuItemAllergenRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TGetAllMenuItemAllergenResponse>(
      dispatch,
      `/api/menu-item-allergen/find-all/${request.itemId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const deleteMenuItemAllergen = createAsyncThunk(
  "menu-supplement/delete",
  async (
    request: TDeleteMenuItemAllegenRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      `/api/menu-item-allergen/delete`,
      MethodEnum.DELETE,
      request
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenuItemAllergenFromList = createAsyncThunk(
  "menu-item-allergen/update-from-list",
  async (
    request: TOnUpdateMenuItemAllergenFromListRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      `/api/menu-item-allergen/update-from-list`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      return request.newList;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const createMenuItemAllergen = createAsyncThunk(
  "menu-item-allergen/create",
  async (
    request: TCreateMenuItemAllergenRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TCreateMenuItemAllergenResponse>(
      dispatch,
      `/api/menu-item-allergen/create`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data.menuItemAllergen;
    } else {
      return rejectWithValue(response);
    }
  }
);
