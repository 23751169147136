import React from "react";
import { TUpdateMenuTimeframeContentProps } from "./types/TUpdateMenuTimeframeContentProps";
import UpdateMenuTimeframeForm from "../Forms/UpdateMenuTimeframeForm";

export default function UpdateMenuTimeframeContent(
  props: TUpdateMenuTimeframeContentProps
) {
  return (
    <div>
      <UpdateMenuTimeframeForm
        menuList={props.menuList}
        selectedMenu={props.selectedMenu}
        onClose={props.onClose}
      />
    </div>
  );
}
