import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIAMTokenEntity } from "../../../common/Entities/IIAMTokenEntity";
import { IRestaurantUserEntity } from "../../../common/Entities/IRestaurantUserEntity";
import { IUserRoleEntity } from "../../../common/Entities/IRoleEntity";
import { TIAMState } from "./types/TIAMState";
import { IUserEntity } from "../../../common/Entities/IUserEntity";

const initialState: TIAMState = {
  awaitingUsers: null,
  validUsers: null,
  totalUserNbr: 0,
  isCreateIAMModalOpen: false,
  userToValidate: null,
  invalidUserRef: false,
};

const iamSlice = createSlice({
  name: "iam",
  initialState,
  reducers: {
    setAwaitingUsers: (
      state,
      action: PayloadAction<IRestaurantUserEntity[]>
    ) => {
      state.awaitingUsers = action.payload;
      let totalNbr = 0;
      if (state.validUsers) {
        totalNbr += state.validUsers.length;
      }
      totalNbr += state.awaitingUsers.length;
      state.totalUserNbr = totalNbr;
    },
    addToAwaitingUser: (
      state,
      action: PayloadAction<IRestaurantUserEntity>
    ) => {
      state.awaitingUsers?.push({ ...action.payload });
      state.totalUserNbr += 1;
    },
    setValidUsers: (state, action: PayloadAction<IRestaurantUserEntity[]>) => {
      state.validUsers = action.payload;
      let totalNbr = 0;
      if (state.awaitingUsers) {
        totalNbr += state.awaitingUsers.length;
      }
      totalNbr += state.validUsers.length;
      state.totalUserNbr = totalNbr;
    },
    setTotalUserNbr: (state, action: PayloadAction<number>) => {
      state.totalUserNbr = action.payload;
    },
    setIsCreateIAMModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateIAMModalOpen = action.payload;
    },
    deleteValidUser: (state, action: PayloadAction<number>) => {
      console.log("action.payload,valid", action.payload);
      console.log("validUsers", JSON.stringify(state.validUsers));
      if (state.validUsers) {
        state.validUsers = state.validUsers.filter(
          (user) => user.userId !== action.payload
        );
      }

      state.totalUserNbr -= 1;
    },
    deleteAwaitingUser: (state, action: PayloadAction<number>) => {
      if (state.awaitingUsers) {
        state.awaitingUsers = state.awaitingUsers.filter(
          (user) => user.userId !== action.payload
        );
      }
      state.totalUserNbr -= 1;
    },
    updateValidUserRole: (
      state,
      action: PayloadAction<{ userId: number; newRole: IUserRoleEntity }>
    ) => {
      if (state.validUsers) {
        state.validUsers = state.validUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            return { ...user, role: action.payload.newRole };
          }
          return user;
        });
      }
    },
    setUserToValidate: (state, action: PayloadAction<IUserEntity>) => {
      state.userToValidate = action.payload;
    },
    setInvalidUserRef: (state, action: PayloadAction<boolean>) => {
      state.invalidUserRef = action.payload;
    },
  },
});

export const iamReducer = iamSlice.reducer;
export const iamActions = iamSlice.actions;
