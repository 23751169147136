import { useState } from "react";
import { AddCircleRounded } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { TItemPreviewProps } from "./type/TItemPreviewProps";
import { useAppDispatch } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import LinesEllipsis from "react-lines-ellipsis";

export default function ItemPreview(props: TItemPreviewProps) {
  const dispatch = useAppDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleOpenItemModal = () => {
    dispatch(menuPreviewActions.setOpenItemModal(true));
    dispatch(menuPreviewActions.setSelectedItem(props.item));
    dispatch(menuPreviewActions.setSelectedCategory(props.category));
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setImageError(false);
  };

  const handleImageError = () => {
    setImageLoaded(false);
    setImageError(true);
  };

  return (
    <div onClick={handleOpenItemModal}>
      <Grid container xs={12} mb={2}>
        <Grid container xs={8}>
          <Grid xs={12}>
            <Typography className="cart-item-preview-title">
              {props.item.name}
            </Typography>
          </Grid>
          <Grid xs={12} mt={1} sx={{ height: !props.modalItem ? "44px" : "" }}>
            <Typography className="listItem-heading-medium-secondary">
              <LinesEllipsis
                text={props.item.description}
                maxLine={3}
                ellipsis="..."
              />
            </Typography>
          </Grid>
          <Grid xs={12} mt={1}>
            <Typography className="listItem-heading-medium-secondary">
              €{props.item.price}
            </Typography>
          </Grid>
        </Grid>
        {!props.modalItem && (
          <Grid xs={4} className="grid-center">
            {!props.item.menuItemImage.image.url || imageError ? (
              <AddCircleRounded
                style={{ color: "#062e4b" }}
                onClick={() => {}}
              />
            ) : (
              <img
                src={`${props.item.menuItemImage.image.url}`}
                width={"100%"}
                height={"100%"}
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{
                  display: imageLoaded ? "block" : "none",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
              />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
