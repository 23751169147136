import { Grid } from "@mui/material";
import SignUpForm from "./Form/SignUpForm";
import { useEffect } from "react";
import { useAppSelector } from "../../../common/store/redux";

const SignUpPage = () => {
  const createStripeAccountLink = useAppSelector(
    (state) => state.stripeAccount.createStripeAccountLink
  );
  useEffect(() => {
    if (createStripeAccountLink) {
      window.location.href = createStripeAccountLink;
    }
  }, [createStripeAccountLink]);

  return (
    <div>
      <Grid
        container
        height={"calc(100vh - 86px)"}
        direction={"column"}
        alignItems={"center"}
      >
        <Grid item width={"400px"} mt={2}>
          <Grid item>
            <SignUpForm />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpPage;
