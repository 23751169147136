import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TCreateIAMRequest } from "./types/Requests/TCreateIAMRequest";
import { TCreateIAMResponse } from "./types/Responses/TCreateIAMResponse";
import { TDeleteIAMRequest } from "./types/Requests/TDeleteIAMRequest";
import { TUpdateIAMRoleRequest } from "./types/Requests/TUpdateIAMRoleRequest";
import { TUpdateIAMRoleResponse } from "./types/Responses/TUpdateIAMRoleResponse";
import { TFindAllIAMRequest } from "./types/Requests/TFindAllIAMRequest";
import { TFindAllIAMResponse } from "./types/Responses/TFindAllIAMResponse";
import { TValidateIAMRequest } from "./types/Requests/TValidateIAMRequest";
import { TValidateIAMResponse } from "./types/Responses/TValidateIAMResponse";
import { TGenerateChefPasswordRequest } from "./types/Requests/TGenerateChefPasswordRequest";

export const createIAM = createAsyncThunk(
  "iam/create",
  async (request: TCreateIAMRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TCreateIAMResponse>(
      dispatch,
      "/api/iam/create",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const generateNewChefPassword = createAsyncThunk(
  "iam/generate-password",
  async (
    request: TGenerateChefPasswordRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      "/api/iam/generate-password",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const validateIAM = createAsyncThunk(
  "iam/validate",
  async (request: TValidateIAMRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TValidateIAMResponse>(
      dispatch,
      "/api/iam/validate",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const deleteIAM = createAsyncThunk(
  "iam/delete",
  async (request: TDeleteIAMRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest(
      dispatch,
      `/api/iam/delete`,
      MethodEnum.DELETE,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateIAMRole = createAsyncThunk(
  "iam/update-role",
  async (request: TUpdateIAMRoleRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TUpdateIAMRoleResponse>(
      dispatch,
      `/api/iam/update-role`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const findAllIAM = createAsyncThunk(
  "iam/find-all",
  async (request: TFindAllIAMRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindAllIAMResponse>(
      dispatch,
      `/api/iam/find-all/${request.restaurantId}/${request.userId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
