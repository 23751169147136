import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";
import { TSelectAllCartButtonProps } from "./types/TSelectAllCartButtonProps";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { ReactComponent as AddIcon } from "../../../../../../common/images/add_icon.svg";

export default function SelectAllCartButton(props: TSelectAllCartButtonProps) {
  const [checked, setChecked] = useState(true);
  const finalCart = useAppSelector((state) => state.cart.finalCart);

  const isAllCartSelected = () => {
    let allCartSelected = true;
    props.cart.categories.map((category) => {
      const categoryPaymentCart = finalCart.categories.find(
        (paymentCategory) =>
          paymentCategory.category.id === category.category.id
      );
      if (!categoryPaymentCart) {
        allCartSelected = false;
        return;
      }
      category.items.map((item) => {
        if (
          !categoryPaymentCart.items.find(
            (paymentItem) =>
              paymentItem.id === item.id &&
              paymentItem.quantity === item.quantity
          )
        ) {
          allCartSelected = false;
        }
      });
    });
    setChecked(allCartSelected);
  };

  useEffect(() => {
    isAllCartSelected();
  }, [JSON.stringify(finalCart)]);
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    checked
      ? dispatch(cartActions.resetFinalCart())
      : dispatch(cartActions.selectAllPaymentCart());
  };
  return (
    <Button
      sx={{
        backgroundColor: "#60b777 !important",
        color: "white",
        fontSize: "12px",
        fontWeight: "300",
        borderRadius: "24px",
        textTransform: "none",
        paddingLeft: "10px",
        paddingRight: "15px",
        height: "26px",
      }}
      onClick={handleOnClick}
    >
      {checked ? (
        <>
          <RemoveOutlined
            sx={{
              fontWeight: "400",
              fontSize: "15px",
            }}
          />
          Tout déselectionner
        </>
      ) : (
        <>
          <AddIcon style={{ marginRight: "5px" }} />
          Tout payer
        </>
      )}
    </Button>
  );
}
