import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import MyApp from "./common/containers/App/App";
import MenuProtectedRoute from "./common/containers/ProtectedRoutes/MenuProtectedRoute";
import RestaurantProtectedRoute from "./common/containers/ProtectedRoutes/RestaurantProtectedRoute";
import { lightTheme } from "./common/containers/Theme/lightTheme";
import { store } from "./common/store/store";
import "./index.scss";
import SignInPage from "./modules/Auth/containers/SignInPage";
import SignUpPage from "./modules/Auth/containers/SignUpPage";
import MainDashboardPage from "./modules/Dashboard/containers/Pages/MainDashboardPage";
import ValidateIAMPage from "./modules/IAM/containers/Pages/ValidateIAMPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/serviceWorker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <MyApp>
          <Routes>
            <Route element={<RestaurantProtectedRoute />}>
              <Route path="/dashboard/*" element={<MainDashboardPage />} />
              <Route path="/auth/signin" element={<SignInPage />} />
              <Route path="/auth/signup" element={<SignUpPage />} />
            </Route>
            <Route path={"/"} element={<SignInPage />} />
            <Route path="customerMenu/*" element={<MenuProtectedRoute />} />
            <Route path="validate-iam" element={<ValidateIAMPage />} />
          </Routes>
        </MyApp>
      </ThemeProvider>
    </Provider>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />)
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
