import CartRecapBox from "../../../../containers/OrderRecapBox/CartRecapBox";
import { TCartPaymentRecapBoxProps } from "./types/TCartPaymentRecapBoxProps";

export default function CartPaymentRecapBox(props: TCartPaymentRecapBoxProps) {
  return (
    <CartRecapBox
      showTotalPrice={false}
      cart={props.cart}
      showQuantityBox={false}
      iconType="checkbox"
    />
  );
}
