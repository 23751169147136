import { useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";

import useMenuItemImageRepository from "../../../image/repository/useMenuItemImageRepository";
import { supplementActions } from "../../../supplements/slices/supplementSlice";
import SelectedMenuItemFields from "./SelectedMenuItemFields";
import { TSelectedMenuItemFormAttributes } from "./types/TSelectedMenuItemFormAttributes";
import { TSelectedMenuItemFormProps } from "./types/TSelectedMenuItemFormProps";
import useMenuItemRepository from "../../repository/useMenuItemRepository";
import useMenuSupplementRepository from "../../../supplements/repository/useMenuSupplementRepository";
import useMenuItemAllergenRepository from "../../../allergens/repository/useMenuItemAllergenRepository";
import useMenuItemCustomizationRepository from "../../../itemCustomization/repository/useMenuItemCustomizationRepository";

export default function SelectedMenuItemForm(
  props: TSelectedMenuItemFormProps
) {
  const hasItemCustomizationChanged = useAppSelector(
    (state) => state.menuItemCustomization.hasItemCustomizationChanged
  );
  const newMenuItemCustomizationList = useAppSelector(
    (state) => state.menuItemCustomization.menuItemCustomizations
  );
  const { onUpdateMenuItemCustomizationFromLists } =
    useMenuItemCustomizationRepository();
  const { onUpdateMenuItemImge } = useMenuItemImageRepository({
    selectedCategory: props.selectedCategory,
    selectedItem: props.selectedItem,
    selectedMenu: props.selectedMenu,
  });
  const { onUpdateMenuItem } = useMenuItemRepository({
    selectedCategory: props.selectedCategory,
    selectedMenu: props.selectedMenu,
  });
  const { onUpdateMenuSupplementFromList } = useMenuSupplementRepository({
    selectedCategory: props.selectedCategory,
    selectedMenu: props.selectedMenu,
    selectedItem: props.selectedItem,
  });
  const { onUpdateMenuItemAllergenFromList } = useMenuItemAllergenRepository({
    selectedCategory: props.selectedCategory,
    selectedMenu: props.selectedMenu,
    selectedItem: props.selectedItem,
  });
  const dispatch = useAppDispatch();
  let hasUnsavedChanges =
    props.hasImageChanged ||
    props.hasItemChanged ||
    props.hasSupplementChanged ||
    props.hasMenuItemAllergenChanged ||
    hasItemCustomizationChanged;
  const newSupplementList = useAppSelector(
    (state) => state.supplement.supplementList
  );
  const newAllergenList = useAppSelector(
    (state) => state.menuItemAllergen.menuItemAllergenList
  );

  useEffect(() => {
    dispatch(
      supplementActions.setSupplementList(props.selectedItem.supplements)
    );
  }, []);

  const submitHandler: SubmitHandler<TSelectedMenuItemFormAttributes> = async (
    data
  ) => {
    if (props.hasImageChanged) {
      await onUpdateMenuItemImge({
        menuItemImage: props.selectedItem.menuItemImage,
        file: props.uploadedImage,
      });
      props.setHasImageChanged(false);
    }
    if (props.hasSupplementChanged) {
      await onUpdateMenuSupplementFromList({
        oldList: props.selectedItem.supplements,
        newList: newSupplementList,
      });
      props.setHasSupplementChanged(false);
    }
    if (props.hasMenuItemAllergenChanged) {
      await onUpdateMenuItemAllergenFromList({
        oldList: props.selectedItem.menuItemAllergen,
        newList: newAllergenList,
      });
      props.setHasMenuItemAllergenChanged(false);
    }
    if (props.hasItemChanged) {
      await onUpdateMenuItem({
        menuItem: {
          ...props.selectedItem,
          name: data.name,
          description: data.description,
          price: data.price,
        },
      });
      props.setHasItemChanged(false);
    }
    if (hasItemCustomizationChanged) {
      console.log("newMenuItemCustomization", newMenuItemCustomizationList);
      await onUpdateMenuItemCustomizationFromLists({
        oldList: props.selectedItem.menuItemCustomizations,
        newList: newMenuItemCustomizationList,
      });
    }
  };
  const successHandler = () => {
    window.location.href = `/dashboard/menus/${props.selectedMenu.id!}`;
  };
  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Sauvegarder"
      onSuccess={successHandler}
      name="personalize-menu-item-form"
      useDefaultErrorDisplay={false}
      buttonStyle={{ width: "35%" }}
      submitDisabled={!hasUnsavedChanges}
    >
      <SelectedMenuItemFields
        selectedItem={props.selectedItem}
        hasItemChanged={props.hasItemChanged}
        setHasItemChanged={props.setHasItemChanged}
        hasSupplementChanged={props.hasSupplementChanged}
        setHasSupplementChanged={props.setHasSupplementChanged}
        supplementList={newSupplementList}
        hasMenuItemAllergenChanged={props.hasMenuItemAllergenChanged}
        setHasMenuItemAllergenChanged={props.setHasMenuItemAllergenChanged}
      />
    </CustomForm>
  );
}
