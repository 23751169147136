import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAppSelector } from "../../../../common/store/redux";
import useKitchenRepository from "../../repository/useKitchenRepository";
import KitchenTicketContainer from "../KitchenTicket/KitchenTicketContainer";
import SubscribeToNotificationModal from "../../../../common/containers/SubscribeToNotificationModal/SubscribeToNotificationModal";

export default function MainKitchenPage() {
  const kitchenCarts = useAppSelector((state) => state.kitchen.kitchenCarts);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  console.log("authenticatedUser", authenticatedUser);
  const { onFindAllKitchenCarts } = useKitchenRepository();
  const preferedHeight = "calc(100vh - 89px)";

  const handleFindKitchenCarts = async () => {
    onFindAllKitchenCarts();
  };

  useEffect(() => {
    if (!kitchenCarts) {
      handleFindKitchenCarts();
    }
  }, [kitchenCarts]);

  return kitchenCarts ? (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxHeight: preferedHeight,
        alignItems: "flex-start",
        alignContent: "flex-start", // Add this line
        columnGap: "25px",
        width: "100%",
      }}
    >
      {authenticatedUser && (
        <SubscribeToNotificationModal
          userRef={authenticatedUser.userRef}
          table={null}
        />
      )}
      {kitchenCarts.map((cart, index) => (
        <div key={index}>
          <KitchenTicketContainer key={cart.id!} cart={cart} />
        </div>
      ))}
    </Box>
  ) : (
    <Typography>Chargement des commandes...</Typography>
  );
}
