import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteSupplementIcon from "../DeleteSupplementIcon/DeleteSupplementIcon";
import { TSupplementTableProps } from "./types/TSupplementTableProps";

export default function SupplementTable(props: TSupplementTableProps) {
  return (
    <Grid item m={1} mt={2} sx={{ width: "425px" }}>
      <TableContainer
        sx={{
          border: "2px solid #273551",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Table
          aria-label="supplement table"
          sx={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <TableHead>
            <TableRow>
              {["Nom Garniture", "Qty/max", "Prix/Unité", "Action"].map(
                (header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "14px",
                      borderBottom: "2px solid #273551",
                      color: "#181818",
                    }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.supplementList.map((supplement, supplementIndex) => (
              <TableRow key={supplementIndex}>
                {[
                  supplement.name,
                  supplement.maxQuantity,
                  `€ ${supplement.price}`,
                  "",
                ].map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      textAlign: "center",
                      borderRight: `${cellIndex !== 3 && "1px solid rgba(127, 127, 127, 0.22)"}`,
                      color: `${cellIndex === 2 ? "#091E42" : "black"}`,
                    }}
                  >
                    {cellIndex === 3 ? (
                      <DeleteSupplementIcon
                        supplementIndex={supplementIndex}
                        setHasSupplementsChanged={
                          props.setHasSupplementsChanged
                        }
                      />
                    ) : (
                      cell
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
