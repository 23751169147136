import UpdateMenuTitleForm from "../Forms/UpdateMenuTitleForm";
import { TUpdateMenuTitleContentProps } from "./types/TUpdateMenuTitleContentProps";

export default function UpdateMenuTitleContent(
  props: TUpdateMenuTitleContentProps
) {
  return (
    <>
      <UpdateMenuTitleForm
        onClose={props.onClose}
        selectedMenu={props.selectedMenu}
      />
    </>
  );
}
