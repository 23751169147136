import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { menuActions } from "../../slice/menuSlice";
import { TMenuBoxProps } from "./types/TMenuBoxProps";
import MoreVerticalMenu from "../MoreVerticalMenu/MoreVerticalMenu";
import MenuItemListPreview from "../MenuItemList/MenuItemListPreview";
import { useEffect, useRef } from "react";
import MenuBoxContent from "./MenuBoxContent";

export default function MenuBox(props: TMenuBoxProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoToMenu = (menu: IMenuEntity) => {
    dispatch(menuActions.setSelectedMenu(menu));
    navigate(`/dashboard/menus/${menu.id!}`);
  };

  return props.menuList && props.menuList.length > 0 ? (
    <>
      {props.menuList.map((menu) => {
        return (
          <div key={menu.id!}>
            <MenuBoxContent
              handleGoToMenu={handleGoToMenu}
              menu={menu}
              menuList={props.menuList}
              menuRefs={props.menuRefs}
            />
          </div>
        );
      })}
    </>
  ) : (
    <></>
  );
}
