import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { TKitchenItemBoxProps } from "./types/TKitchenItemBoxProps";
import KitchenItemSupplementBox from "../KitchenItemSupplementBox/KitchenItemSupplementBox";
import KitchenDivider from "../../../../common/images/kitchen_divider.png";

export default function KitchenItemBox(props: TKitchenItemBoxProps) {
  const isLastItem = (currentIndex: number, maxIndex: number): boolean => {
    return maxIndex - currentIndex <= 0;
  };

  return (
    <Grid container justifyContent={"row"} mb={1}>
      <Grid container justifyContent={"space-between"}>
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          {props.cartItem.item.name}
        </Typography>
        {props.isFirstIndex && (
          <Typography>
            <span style={{ fontWeight: "bold", fontSize: "10px" }}>ID:</span>{" "}
            <span style={{ fontWeight: "400", fontSize: "9px" }}>
              {props.cartId}
            </span>
          </Typography>
        )}
      </Grid>
      <Grid container alignItems={"center"}>
        {props.cartItem.cartSupplements &&
          props.cartItem.cartSupplements.length > 0 && (
            <>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "11px", mr: "2px" }}
              >
                Toppings:
              </Typography>
              {props.cartItem.cartSupplements.map((supplement, index) => {
                // Create an array to hold the multiple supplement boxes
                let supplementBoxes = [];
                const lastIndex = isLastItem(
                  index,
                  props.cartItem.cartSupplements.length - 1
                );

                for (let i = 0; i < supplement.quantity; i++) {
                  supplementBoxes.push(
                    <KitchenItemSupplementBox
                      key={`${supplement.id}-${i}`} // Unique key for each supplement instance
                      supplement={supplement}
                      isLastItem={
                        lastIndex && isLastItem(i, supplement.quantity - 1)
                      }
                    />
                  );
                }

                // Return all the supplement boxes for the current supplement
                return supplementBoxes;
              })}
            </>
          )}
      </Grid>

      <Grid item width={"100%"}>
        <img src={KitchenDivider} width={"100%"} height={"1px"} />
      </Grid>
    </Grid>
  );
}
