import React from "react";
import { TKitchenItemSupplementBoxProps } from "./types/TKitchenItemSupplementBoxProps";
import { Typography } from "@mui/material";

export default function KitchenItemSupplementBox(
  props: TKitchenItemSupplementBoxProps
) {
  return (
    <>
      <Typography sx={{ fontWeight: "400", fontSize: "10px", mr: "2px" }}>
        {props.supplement.supplement.name}
        {!props.isLastItem && ","}
      </Typography>
    </>
  );
}
