import { Typography } from "@mui/material";
import React, { useState } from "react";

export default function useImageUpload() {
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [deleteImage, setDeleteImage] = useState<boolean>(false);
  const showRequirementMessage = (): JSX.Element => {
    return !previewImage ? (
      <>
        <Typography sx={{ color: "#f44336" }}>
          Dimensions recommandées : 390px * 405px | Taille maximale : 2MB{" "}
        </Typography>
      </>
    ) : (
      <></>
    );
  };
  return {
    fileToUpload,
    setFileToUpload,
    previewImage,
    setPreviewImage,
    showRequirementMessage,
    setDeleteImage,
    deleteImage,
  };
}
