import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TOnCreateMenuItemCustomizationRequest } from "./types/Requests/TOnCreateMenuItemCustomizationRequest";
import { TOnCreateMenuItemCustomizationResponse } from "./types/Responses/TOnCreateMenuItemCustomizationResponse";
import { TOnUpdateMenuItemCustomizationFromListRequest } from "./types/Requests/TOnUpdateMenuItemCustomizationFromListRequest";

export const createMenuItemCustomization = createAsyncThunk(
  "menu-item-customization/create",
  async (
    request: TOnCreateMenuItemCustomizationRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TOnCreateMenuItemCustomizationResponse>(
      dispatch,
      `/api/menu-item-customization/create`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data.menuItemCustomization;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenuItemCustomizationFromLists = createAsyncThunk(
  "menu-item-customization/update-from-lists",
  async (
    request: TOnUpdateMenuItemCustomizationFromListRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      `/api/menu-item-customization/update-from-lists`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);
