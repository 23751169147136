import { MoreVertOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch } from "../../../../../../common/store/redux";
import DeleteMenuConfirmationModal from "../Modals/DeleteMenuConfirmationModal";
import UpdateMenuModal from "../Modals/UpdateMenuModal";
import { TMoreVerticalMenuProps } from "./types/TMoreVerticalMenuProps";

const MoreVerticalMenu = (props: TMoreVerticalMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for anchor element (position for menu)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const handleOpenMoreVerticalMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreVerticalMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    setIsDeleteModalOpen(true);
    handleCloseMoreVerticalMenu();
  };

  const handleUpdateMenu = () => {
    setIsUpdateModalOpen(true);
    handleCloseMoreVerticalMenu();
  };

  return (
    <>
      <DeleteMenuConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        selectedMenu={props.selectedMenu}
      />
      <UpdateMenuModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        menuList={props.menuList}
        selectedMenu={props.selectedMenu}
      />

      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMoreVerticalMenu}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreVerticalMenu}
      >
        <MenuItem onClick={handleUpdateMenu}>Modifier</MenuItem>
        <MenuItem onClick={handleDeleteMenu} sx={{ color: "red" }}>
          Supprimer
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreVerticalMenu;
