import React, { useState, useEffect } from "react";
import CustomButton from "../../../../containers/Button/CustomButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { initiateCart } from "../../../../services/cartService";
import { TSendOrderButtonProps } from "./types/TSendOrderButtonProps";
import { cartActions } from "../../../../slices/cartSlice";
import { useNavigate } from "react-router-dom";
import { clearCartState } from "../../../../../../utils/cart/cartLocalStorage";
import { appActions } from "../../../../../../common/store/appSlice";

export default function SendOrderButton(props: TSendOrderButtonProps) {
  const cartState = useAppSelector((state) => state.cart.currentCart);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoading) {
      timer = setTimeout(() => {
        dispatch(cartActions.setShouldSendOrder(true));
        dispatch(initiateCart({ cart: cartState }));
        navigate(
          `/customerMenu/orderSent?userRef=${props.userRef}&table=${props.table}&menuId=${props.menuId}`
        );
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isLoading, dispatch, cartState, navigate, props]);

  const handleOnClick = () => {
    setIsLoading(true);
  };

  return (
    <CustomButton
      buttonText={"Commander"}
      variant="primary"
      handleOnClick={handleOnClick}
      isLoading={isLoading}
    />
  );
}
