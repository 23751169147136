import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TSelectRoleFieldProps } from "./types/TSelectRoleFieldProps";
import { IUserRoleEntity } from "../../../../common/Entities/IRoleEntity";
import { getUserFriendlyRoleName } from "../../../../utils/roles/shared";

// Assuming you've imported or defined getUserFriendlyRoleName somewhere

export default function SelectRoleField(props: TSelectRoleFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (
    value: IUserRoleEntity | null,
    onChange: (value: any) => void
  ) => {
    onChange(value);
  };

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validation}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={props.roles ?? []}
          value={field.value || null}
          onChange={(e, value) => handleChange(value, field.onChange)}
          getOptionLabel={(option: IUserRoleEntity) =>
            getUserFriendlyRoleName(option.name)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={props.label}
              placeholder={props.placeholder}
              fullWidth={true}
            />
          )}
          renderOption={(props, option: IUserRoleEntity) => (
            <li {...props}>{getUserFriendlyRoleName(option.name)}</li>
          )}
          fullWidth={true}
        />
      )}
    />
  );
}
