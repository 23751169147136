import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../common/containers/Form/CustomTextFields";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { TSignUpFormAttributes } from "./types/TSignUpFormAttributes";

import { useNavigate } from "react-router-dom";
import { TOnSignUpRequest } from "../../services/types/Requests/TOnSignUpRequest";
import CustomEmailField from "./CustomEmailField";
import CustomPasswordField from "./CustomPasswordField";
import { Grid } from "@mui/material";
import useAuthRepository from "../../repository/useAuthRepository";
import { useRef } from "react";

const SignUpForm = () => {
  const dispatch = useAppDispatch();
  const { onSignUp, onSignIn } = useAuthRepository();
  const emailRef = useRef<string | null>(null);
  const passwordRef = useRef<string | null>(null);

  const submitHandler: SubmitHandler<TSignUpFormAttributes> = async (data) => {
    emailRef.current = data.email;
    passwordRef.current = data.password;
    const requestData: TOnSignUpRequest = {
      email: data.email,
      password: data.password,
      restaurant: { name: data.name },
      userType: { type: "RESTAURANT" },
      firstName: data.firstName,
      lastName: data.lastName,
    };
    const signedUpUser = await onSignUp(requestData);
    if (!signedUpUser) {
      emailRef.current = null;
      passwordRef.current = null;
    }
    return signedUpUser;
  };

  const successHandler = async () => {
    //Signin user on success
    if (emailRef.current && passwordRef.current) {
      const isConnected = await onSignIn({
        email: emailRef.current,
        password: passwordRef.current,
        remember: false,
      });
      if (isConnected) {
        navigate("/dashboard");
      }
    } else {
      navigate("/auth/signin");
    }
  };

  const navigate = useNavigate();

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="S'inscrire"
      onSuccess={successHandler}
      name="sign-up-form"
    >
      <Grid item mb={4}>
        <CustomTextField
          name="lastName"
          label="Nom"
          validation={{
            required:
              "Veuillez renseigner le nom du représentant du compte principale",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="firstName"
          label="Prénom"
          validation={{
            required:
              "Veuillez renseigner le prénom du représentant du compte principale",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomEmailField name="email" label="Email" />
      </Grid>
      <Grid item mb={4}>
        <CustomPasswordField
          name="password"
          label="Mot de passe"
          validation={{
            minLength: {
              value: 8,
              message: "Le mot de passe doit faire au moins 8 caractères",
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message:
                "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
            },
          }}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="name"
          label="Nom du restaurant"
          validation={{
            required: "Veuillez renseigner le nom de votre restaurant",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
    </CustomForm>
  );
};

export default SignUpForm;
