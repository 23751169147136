import React, { useEffect, useRef } from "react";
import { Box, Button, Grid } from "@mui/material";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { TCategoryCarousselProps } from "./types/TCategoryCarousselProps";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { CloseOutlined, ListOutlined } from "@mui/icons-material";

export const CategoryCarousel = (props: TCategoryCarousselProps) => {
  const selectedCategoryId = useAppSelector(
    (state) => state.menuPreview.selectedCategoryId
  );
  const dispatch = useAppDispatch();

  // Create a ref for each category button
  const categoryRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleOnClickCategory = (categoryId: number) => {
    dispatch(menuPreviewActions.setSelectedCategoryId(categoryId));
  };

  useEffect(() => {
    props.categories &&
      dispatch(
        menuPreviewActions.setSelectedCategoryId(props.categories[0].id!)
      );
  }, [props.categories, dispatch]);

  // Scroll to the selected category when it changes
  useEffect(() => {
    const selectedCategoryIndex = props.categories.findIndex(
      (category) => category.id === selectedCategoryId
    );
    if (
      selectedCategoryIndex !== -1 &&
      categoryRefs.current[selectedCategoryIndex]
    ) {
      categoryRefs.current[selectedCategoryIndex]?.scrollIntoView({
        behavior: "smooth",
        inline: "center", // Ensures the selected item is centered
      });
    }
  }, [selectedCategoryId, props.categories]);

  const handleOpenSelectCategoryModal = () => {
    dispatch(menuPreviewActions.setIsSelectCategoryModalOpen(true));
  };

  return (
    <Box
      sx={{
        whiteSpace: "nowrap",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 1000,
        overflowX: "hidden",
      }}
    >
      <ListOutlined
        sx={{
          cursor: "pointer",
          left: 10,

          position: "absolute",
          marginTop: "10px",
        }}
        onClick={handleOpenSelectCategoryModal}
      />

      <Box sx={{ ml: 5 }}>
        <ScrollMenu itemClassName="hide-scrollbar">
          <>
            {props.categories.map((category, index) => (
              <Box
                key={category.id}
                sx={{ display: "inline-block", padding: "0 10px" }}
                onClick={() => handleOnClickCategory(category.id!)}
              >
                <div ref={(el) => (categoryRefs.current[index] = el)}>
                  <Button
                    className={
                      selectedCategoryId === category.id
                        ? "category-caroussel-button-selected"
                        : "category-caroussel-button-unselected"
                    }
                  >
                    {category.name}
                  </Button>
                </div>
              </Box>
            ))}
          </>
        </ScrollMenu>
      </Box>
    </Box>
  );
};

export default CategoryCarousel;
