import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuAllergenEntity } from "../../../../../common/Entities/IMenuAllergenEntity";
import { TMenuAllergenSliceState } from "./types/TMenuAllergenSliceState";

const initialState: TMenuAllergenSliceState = {
  menuAllergenList: [],
};

const menuAllergenSlice = createSlice({
  name: "menuAllergen",
  initialState,
  reducers: {
    setMenuAllergenList: (
      state,
      action: PayloadAction<IMenuAllergenEntity[]>
    ) => {
      state.menuAllergenList = action.payload;
    },
  },
});

export const menuAllergenReducer = menuAllergenSlice.reducer;
export const menuAllergenActions = menuAllergenSlice.actions;
