import { Grid, Typography } from "@mui/material";

export default function RequiredBox() {
  return (
    <Grid
      item
      sx={{
        backgroundColor: "#273551",
        height: "22px",
        padding: "4px",
        paddingLeft: "8px",
        paddingRight: "8px",
        alignItems: "center",
        display: "flex",
        borderRadius: "6px",
      }}
    >
      <Typography sx={{ fontSize: "13px", fontWeight: "500", color: "white" }}>
        Requis
      </Typography>
    </Grid>
  );
}
