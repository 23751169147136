import React, { useEffect } from "react";
import { TMenuItemCustomizationPageProps } from "./types/TMenuItemCustomizationPageProps";
import { Box, Grid, Typography } from "@mui/material";
import MenuItemCustomizationTable from "../MenuItemCustomizationTable/MenuItemCustomizationTable";
import MenuItemCustomizationTitle from "../MenuItemCustomizationTitle/MenuItemCustomizationTitle";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import useMenuItemCustomizationRepository from "../../repository/useMenuItemCustomizationRepository";

export default function MenuItemCustomizationPage(
  props: TMenuItemCustomizationPageProps
) {
  const menuItemCustomizationState = useAppSelector(
    (state) => state.menuItemCustomization.menuItemCustomizations
  );
  const { onSetMenuItemCustomizationList } =
    useMenuItemCustomizationRepository();

  useEffect(() => {
    onSetMenuItemCustomizationList(props.menuItem.menuItemCustomizations);
  }, []);
  return (
    <div>
      {menuItemCustomizationState.length > 0 && (
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          mt={2}
          mb={2}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              "&::before, &::after": {
                content: '""',
                flex: 1,
                borderBottom: "1px solid grey",
              },
              "&::before": {
                marginRight: "0.5em",
              },
              "&::after": {
                marginLeft: "0.5em",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: `600`,
                fontSize: "18px",
              }}
            >
              Options supplémentaires
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid container justifyContent={"center"} mb={2} direction={"column"}>
        {menuItemCustomizationState.map((custo, custoIndex) => {
          return (
            <>
              <Grid item ml={1}>
                <MenuItemCustomizationTitle
                  menuItemCustomization={custo}
                  customizationIndex={custoIndex}
                />
              </Grid>
              <Grid item>
                <MenuItemCustomizationTable
                  menuItemCustomization={custo}
                  customizationIndex={custoIndex}
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    </div>
  );
}
