import { useAppDispatch } from "../../../common/store/redux";
import { findAllRoles } from "../services/roleService";
import { rolesActions } from "../slice/roleSlice";

export default function useRolesRepository() {
  const dispatch = useAppDispatch();
  const onFindListOfRoles = async (): Promise<boolean> => {
    const resultAction = await dispatch(findAllRoles());

    if (findAllRoles.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(rolesActions.setListOfRoles(resultAction.payload.userRoles));
    return true;
  };
  return { onFindListOfRoles };
}
