import { DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import CreateMenuCategoryForm from "../Forms/CreateMenuCategoryForm";
import { TCreateMenuCategoryModalProps } from "./types/TCreateMenuCategoryModalProps";
import { categoryActions } from "../../slice/categorySlice";

export default function CreateMenuCategoryModal(
  props: TCreateMenuCategoryModalProps
) {
  const isCreateCategoryModalOpen = useAppSelector(
    (state) => state.category.isCreateCategoryModalOpen
  );
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(categoryActions.setIsCreateCategoryModalOpen(false));
  };
  return (
    <>
      <MuiDialog
        open={isCreateCategoryModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
      >
        <DialogTitle>Création d'une catégorie</DialogTitle>
        <Grid container className="grid-center" p={2}>
          <CreateMenuCategoryForm
            onClose={handleCloseModal}
            menuId={props.selectedMenu.id!}
            selectedMenu={props.selectedMenu}
          />
        </Grid>
      </MuiDialog>
    </>
  );
}
