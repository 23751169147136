import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import AddMenuItemAllergenFields from "./AddMenuItemAllergenFields";
import { TAddMenuItemAllergenFormAttributes } from "./types/TAddMenuItemAllergenFormAttributes";
import { TAddMenuItemAllergenFormProps } from "./types/TAddMenuItemAllergenFormProps";

export default function AddMenuItemAllergenForm(
  props: TAddMenuItemAllergenFormProps
) {
  const submitHandler: SubmitHandler<
    TAddMenuItemAllergenFormAttributes
  > = async (data) => {};

  return (
    <CustomForm
      useCustomButton={true}
      onSubmit={submitHandler}
      name="add-menu-item-allergens-form"
      onSuccess={() => {}}
      useDefaultErrorDisplay={false}
      buttonStyle={{ width: "25%" }}
    >
      <AddMenuItemAllergenFields
        menuAllergenList={props.menuAllergenList}
        menuItem={props.menuItem}
        hasMenuItemAllergenChanged={props.hasMenuItemAllergenChanged}
        setHasMenuItemAllergenChanged={props.setHasMenuItemAllergenChanged}
      />
    </CustomForm>
  );
}
