import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { getBankAccountList } from "../../../../services/stripeAccountService";
import { stripeAccountActions } from "../../../StripeAccount/slices/stripeAccountSlice";
import { TBankAccountListProps } from "./types/TBankAccountListProps";

type BankAccountStatus =
  | "new"
  | "validated"
  | "verified"
  | "verification_failed"
  | "errored";

const statusTranslations: Record<BankAccountStatus, string> = {
  new: "Nouveau",
  validated: "Validé",
  verified: "Vérifié",
  verification_failed: "Échec de la vérification",
  errored: "Erreur",
};

const statusDescriptions: Record<BankAccountStatus, string> = {
  new: "En attente de validation par Stripe",
  validated: "Le compte bancaire a été validé comme existant par Stripe",
  verified: "La vérification du compte bancaire a réussie.",
  verification_failed: "La vérification a du compte bancaire échouée.",
  errored:
    "Un paiement envoyé à ce compte bancaire a échoué. Ce compte bancaire est désactivé.",
};

export const BankAccountList = (props: TBankAccountListProps) => {
  const dispatch = useAppDispatch();

  const handleVerify = (accountId: string) => {
    dispatch(stripeAccountActions.setOpenVerifyMicroDepotModal(true));
  };

  const handleDelete = (accountId: string) => {
    // Add logic to handle deleting the bank account
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <Table aria-label="bank account list table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "none",
                  fontSize: "12px",
                  color: "#B5B5C3",
                }}
              >
                Compte de chèque
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "none",
                  fontSize: "12px",
                  color: "#B5B5C3",
                }}
              >
                Statut
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "none",
                  fontSize: "12px",
                  color: "#B5B5C3",
                }}
              >
                Nom de la banque
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.bankAccountList ||
            props.bankAccountList.data.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    textAlign: "center",
                    color: "#B5B5C3",
                    fontStyle: "italic",
                  }}
                >
                  Vous n'avez pas de compte bancaire enregistré pour le moment.
                </TableCell>
              </TableRow>
            ) : (
              props.bankAccountList.data.map((account) => (
                <TableRow
                  key={account.id}
                  sx={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "#464E5F",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    N° ****{account.last4}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "#464E5F",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {statusTranslations[account.status as BankAccountStatus]}
                      <Tooltip
                        title={
                          statusDescriptions[
                            account.status as BankAccountStatus
                          ]
                        }
                      >
                        <InfoIcon
                          sx={{
                            ml: 1,
                            verticalAlign: "middle",
                            fontSize: "1rem",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "#464E5F",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {account.bank_name}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
