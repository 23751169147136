import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { TCustomPassowrdFieldProps } from "./types/TCustomPassowrdFieldProps";
import { Link } from "react-router-dom";
import CustomCheckboxField from "./CustomCheckboxField";

export default function CustomPasswordField(props: TCustomPassowrdFieldProps) {
  const { control } = useFormContext();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Controller
          name={props.name}
          control={control}
          rules={{
            ...(props.validation || {}),
            required: "Veuillez renseigner votre mot de passe",
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label={props.label}
              value={field.value || ""}
              variant="outlined"
              type="password"
              error={!!error}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          )}
        />
      </Grid>
      {props.loginDetails && (
        <>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Link
                to="/auth/forgot-password"
                style={{ textDecoration: "none" }}
              >
                <Typography sx={{ fontSize: "11px", color: "#474747" }}>
                  Mot de passe oublié ?
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <CustomCheckboxField
              name="remember"
              label={
                <Typography sx={{ fontSize: "14px" }}>
                  Rester connecté
                </Typography>
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
