import { IMenuItemAllergenEntity } from "../../../../../common/Entities/IMenuItemAllergenEntity";
import { useAppDispatch } from "../../../../../common/store/redux";
import { menuActions } from "../../menu/slice/menuSlice";
import { updateMenuItemAllergenFromList } from "../services/menuItemAllergenService";
import { TOnUpdateMenuItemAllergenFromListRequest } from "../services/type/Requests/TUpdateMenuItemAllergenFromListRequest";
import { TUseMenuItemAllergenRepositoryProps } from "./types/TUseMenuItemAllergenRepositoryProps";

export default function useMenuItemAllergenRepository(
  props: TUseMenuItemAllergenRepositoryProps
) {
  const dispatch = useAppDispatch();

  async function onUpdateMenuItemAllergenFromList(
    requestData: TOnUpdateMenuItemAllergenFromListRequest
  ): Promise<IMenuItemAllergenEntity[]> {
    const resultAction = await dispatch(
      updateMenuItemAllergenFromList(requestData)
    );

    if (updateMenuItemAllergenFromList.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const updatedMenuItemAllergen = resultAction.payload;
    dispatch(
      menuActions.updateMenuItemAllergenList({
        menuId: props.selectedMenu.id!,
        categoryId: props.selectedCategory.id!,
        itemId: props.selectedItem.id!,
        newMenuItemAllergenList: updatedMenuItemAllergen,
      })
    );
    return updatedMenuItemAllergen;
  }
  return {
    onUpdateMenuItemAllergenFromList,
  };
}
