import { Grid, Typography } from "@mui/material";
import { TCustomTooltipProps } from "./type/TCustomTooltipProps";

export default function CustomTooltip(props: TCustomTooltipProps) {
  return props.activeIndex !== null ? (
    <Grid
      container
      sx={{
        backgroundColor: "#0F123F",
        borderRadius: "10px",
        width: "150px",
        height: "100px",
      }}
      p={2}
      justifyContent={"center"}
    >
      <Grid item>
        <Typography sx={{ color: "white", fontWeight: "bold" }}>
          {props.sortedData
            .find((data, index) => index === props.activeIndex)
            ?.value.toFixed(2)}
          {` €`}
        </Typography>
      </Grid>
      <Grid item>
        {props.sortedBy === "Weekly" && (
          <Typography
            sx={{ color: "#BABEC6", fontSize: "13px", textAlign: "center" }}
          >
            Semaine du{" "}
            {props.sortedData
              .find((data, index) => index === props.activeIndex)
              ?.date.toLocaleDateString("fr-FR", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
          </Typography>
        )}
        {props.sortedBy === "Daily" && (
          <Typography
            sx={{ color: "#BABEC6", fontSize: "13px", textAlign: "center" }}
          >
            {props.sortedData
              .find((data, index) => index === props.activeIndex)
              ?.date.toLocaleDateString("fr-FR", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
          </Typography>
        )}
        {props.sortedBy === "Monthly" && (
          <Typography
            sx={{ color: "#BABEC6", fontSize: "13px", textAlign: "center" }}
          >
            {props.sortedData
              .find((data, index) => index === props.activeIndex)
              ?.date.toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
              })}
          </Typography>
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
