import { DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { appActions } from "../../store/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { InfoOutlined, ReportProblemOutlined } from "@mui/icons-material";

export default function RequiredInformationModal() {
  const openRequiredInformation = useAppSelector(
    (state) => state.app.showRequiredInformationModal
  );
  const requiredInformationMessage = useAppSelector(
    (state) => state.app.requiredInformationMessage
  );
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(appActions.setRequiredInformationMessage(""));
    dispatch(appActions.setShowRequiredInformationModal(false));
  };

  useEffect(() => {
    dispatch(appActions.setCustomerMenuLoading(false));
    dispatch(appActions.setAppLoading(false));
  }, []);

  return (
    <>
      <MuiDialog
        open={openRequiredInformation}
        onClose={handleCloseModal}
        maxWidth="sm"
      >
        <DialogTitle className="grid-center">Information requise</DialogTitle>
        <Grid container mb={2}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <ReportProblemOutlined
              sx={{
                color: "#FBBC04", // Adjust color as per your design
                fontSize: "35px",
                display: "block",
                margin: "0 auto",
                animation: `fadeIn 0.5s ease-in-out`,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            className="grid-center"
            pt={1}
            pl={1}
            pr={1}
          >
            <Typography sx={{ textAlign: "center" }}>
              {requiredInformationMessage}
            </Typography>
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
}
