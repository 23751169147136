import { Grid } from "@mui/material";
import MenuItemBox from "../MenuItemBox/MenuItemBox";
import MenuItemSample from "../MenuItemSample/MenuItemSample";
import CreateMenuItemModal from "../Modals/CreateMenuItemModal";
import { TMenuItemListProps } from "./types/TMenuItemListProps";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { itemActions } from "../../slice/itemSlice";
import { IMenuItemEntity } from "../../../../../../common/Entities/IMenuItemEntity";

export default function MenuItemList(props: TMenuItemListProps) {
  const dispatch = useAppDispatch();

  return (
    <>
      <CreateMenuItemModal
        selectedMenu={props.selectedMenu}
        selectedCategory={props.selectedCategory}
      />

      <Grid container sx={{ width: "100%" }} direction={"row"} mt={4}>
        {props.selectedCategory.itemList.map((item) => (
          <div key={item.id!}>
            <MenuItemBox
              key={item.id!}
              item={item}
              disabled={false}
              selectedMenu={props.selectedMenu}
              selectedCategory={props.selectedCategory}
            />
          </div>
        ))}
        <MenuItemSample />
      </Grid>
    </>
  );
}
