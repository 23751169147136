import React from "react";
import { Route, Routes } from "react-router-dom";
import CartPaymentPage from "../../../Cart/modules/Payment/pages/CartPaymentPage";

export default function MenuPayment() {
  return (
    <Routes>
      <Route path="payment" element={<CartPaymentPage />} />
    </Routes>
  );
}
