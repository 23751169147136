import React, { useEffect } from "react";
import AddMenuItemAllergenForm from "../Forms/AddMenuItemAllergenForm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import useMenuAllergenRepository from "../../repository/useMenuAllergenRepository copy";
import { TAddMenuItemAllergenPageProps } from "../Forms/types/TAddMenuItemAllergenPageProps";
import { Grid } from "@mui/material";

export default function AddMenuItemAllergenPage(
  props: TAddMenuItemAllergenPageProps
) {
  const menuAllergenList = useAppSelector(
    (state) => state.menuAllergen.menuAllergenList
  );
  const { onGetAllMenuAllergen } = useMenuAllergenRepository();

  useEffect(() => {
    if (menuAllergenList.length === 0) {
      onGetAllMenuAllergen();
    }
  }, []);

  useEffect(() => {}, []);
  return (
    <Grid item sx={{ maxWidth: "535px" }}>
      <AddMenuItemAllergenForm
        menuAllergenList={menuAllergenList}
        menuItem={props.menuItem}
        hasMenuItemAllergenChanged={props.hasMenuItemAllergenChanged}
        setHasMenuItemAllergenChanged={props.setHasMenuItemAllergenChanged}
      />
    </Grid>
  );
}
