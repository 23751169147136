import React, { useEffect, useState } from "react";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import { Box, Grid } from "@mui/material";
import { StyledTextField } from "../../../item/container/Forms/StyledTextField";
import UpdateMenuItemTextField from "../../../item/container/Forms/UpdateMenuItemTextField";
import { SubmitHandler } from "react-hook-form";
import { TUpdateMenuItemCustomizationFormAttributes } from "./types/TUpdateMenuItemCustomizationFormAttributes";
import UpdateMenuItemNumberField from "../../../item/container/Forms/UpdateMenuItemNumberField";
import { IMenuItemCustomizationOption } from "../../../../../../common/Entities/IMenuItemCustomizationOption";
import { TUpdateMenuItemCustomizationFormProps } from "./types/TUpdateMenuItemCustomizationFormProps";
import OptionsFields from "../OptionsFields/OptionsFields";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { customFormActions } from "../../../../../../common/store/customFormSlice";
import useMenuItemCustomizationRepository from "../../repository/useMenuItemCustomizationRepository";
import { IMenuItemCustomizationEntity } from "../../../../../../common/Entities/IMenuItemCustomizationEntity";
import { json } from "stream/consumers";
import NumberField from "../../../item/container/Forms/NumberField";
import YesNoSelectField from "./YesNoField";

export default function UpdateMenuItemCustomizationForm(
  props: TUpdateMenuItemCustomizationFormProps
) {
  const { onUpdateItemCustomizationTable } =
    useMenuItemCustomizationRepository();
  const dispatch = useAppDispatch();
  const [newOptionsList, setNewOptionsList] = useState<
    IMenuItemCustomizationOption[]
  >(props.menuItemCustomization.menuItemCustomizationOptions);
  const submitHandler: SubmitHandler<
    TUpdateMenuItemCustomizationFormAttributes
  > = async (data) => {
    // Filtrez les options vides avant de sauvegarder
    const filteredOptions = newOptionsList.filter(
      (option) => option.name.trim() !== ""
    );

    // Logique pour sauvegarder `filteredOptions` ou envoyer les données
    console.log(
      "Options à sauvegarder :",
      filteredOptions,
      data.customizationName,
      data.maxQuantity,
      data.required
    );
    if (filteredOptions.length === 0) {
      console.log("ici");
      throw new Error("Veuillez ajouter au moins une option");
    }
    const custo: IMenuItemCustomizationEntity = {
      name: data.customizationName,
      maxQuantity: data.maxQuantity,
      menuItemId: props.menuItemCustomization.menuItemId,
      menuItemCustomizationOptions: filteredOptions,
      required: data.required,
    };
    onUpdateItemCustomizationTable({
      index: props.custoIndex,
      newMenuItemCustomization: custo,
    });
    return true;
  };

  const successHandler = () => {
    props.handleCloseModal();
  };

  return (
    <Box p={2}>
      <CustomForm
        onSubmit={submitHandler}
        defaultButtonText="Modifier"
        onSuccess={successHandler}
        name="update-menu-item-customization-form"
        useDefaultErrorDisplay={false}
      >
        <Grid item mb={1}>
          <UpdateMenuItemTextField
            name="customizationName"
            label="Titre"
            isBold={false}
            defaultValue={props.menuItemCustomization.name}
          />
        </Grid>
        <Grid item mb={1}>
          <NumberField
            name="maxQuantity"
            label="Qty/max"
            defaultValue={props.menuItemCustomization.maxQuantity}
          />
        </Grid>
        <Grid item mb={1}>
          <YesNoSelectField name="required" label="Requis" isBold={false} />
        </Grid>
        <Grid item mb={1}>
          <OptionsFields
            newOptionsList={newOptionsList}
            setNewOptionsList={setNewOptionsList}
            menuItemCustomizationId={props.menuItemCustomization.id!}
          />
        </Grid>
      </CustomForm>
    </Box>
  );
}
