import { useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { refreshUser } from "../../../modules/Auth/services/authService";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import {
  retrieveAccessToken,
  retrieveRefreshToken,
} from "../../../utils/crypto";

const RestaurantProtectedRoute = () => {
  const { isAuthenticated, user, isOATValid } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const accessToken = retrieveAccessToken();
    const refreshToken = retrieveRefreshToken();

    if (location.pathname.startsWith("/dashboard")) {
      if (!isOATValid || (!accessToken && !refreshToken)) {
        navigate("/auth/signin");
        return;
      }

      if (!isAuthenticated || !user) {
        const token = accessToken || refreshToken;
        const type = accessToken ? "ACCESS" : "REFRESH";
        if (token) {
          dispatch(refreshUser({ token, type }));
        }
      }
    } else if (location.pathname.startsWith("/auth")) {
      if (isAuthenticated || user || accessToken || refreshToken) {
        navigate("/dashboard");
      }
    }
  }, [
    isAuthenticated,
    user,
    isOATValid,
    location.pathname,
    dispatch,
    navigate,
  ]);

  if (!isAuthenticated && location.pathname.startsWith("/auth")) {
    return <Outlet />;
  }

  return isAuthenticated && user ? <Outlet /> : null;
};

export default RestaurantProtectedRoute;
