import { Box, Typography } from "@mui/material";
import React from "react";
import { IItemQuantityBoxProps } from "./types/TItemQuantityBox";
import { cartActions } from "../../../../slices/cartSlice";
import { useAppDispatch } from "../../../../../../common/store/redux";

const ItemQuantityBox = (props: IItemQuantityBoxProps) => {
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    dispatch(
      cartActions.setSelectedCurrentCartItem({
        cartIndexes: props.cartIndexes,
        selectState: false,
      })
    );
    dispatch(cartActions.setOpenQuantityPopup(true));
  };
  return (
    <Box onClick={handleOnClick}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "14px",
          height: "14px",
          border: "1px solid #000",
          padding: "11px",
        }}
      >
        <Typography variant="body1">{props.quantity}</Typography>
      </Box>
    </Box>
  );
};
export default ItemQuantityBox;
