import { IMenuItemImageEntity } from "../../../../../common/Entities/IMenuItemImageEntity";
import { useAppDispatch } from "../../../../../common/store/redux";
import { menuActions } from "../../menu/slice/menuSlice";
import { updateMenuItemImage } from "../services/menuItemImageServices";
import { TOnUpdateMenuItemImageRequest } from "../services/types/Requests/TOnUpdateMenuItemImageRequest";
import { TUseMenuItemImageRepositoryProps } from "./types/TUseMenuItemImageRepositoryProps";

export default function useMenuItemImageRepository(
  props: TUseMenuItemImageRepositoryProps
) {
  const dispatch = useAppDispatch();

  async function onUpdateMenuItemImge(
    requestData: TOnUpdateMenuItemImageRequest
  ): Promise<IMenuItemImageEntity> {
    const resultAction = await dispatch(updateMenuItemImage(requestData));

    if (updateMenuItemImage.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const updatedImage = resultAction.payload;
    dispatch(
      menuActions.updateMenuItemImage({
        menuId: props.selectedMenu.id!,
        categoryId: props.selectedCategory.id!,
        itemId: props.selectedItem.id!,
        image: updatedImage,
      })
    );

    return updatedImage;
  }
  return { onUpdateMenuItemImge };
}
