import React, { useState } from "react";
import { RectangleProps } from "recharts";

const CustomBar = (props: RectangleProps) => {
  const { fill, x = 0, y = 0, width = 0, height = 0 } = props;
  const [isHovered, setIsHovered] = useState(false);
  const radius = 16;

  return (
    <g>
      <defs>
        <linearGradient id={`gradient-${x}`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={isHovered ? "#0F123F" : "#091E42"} />
          <stop offset="100%" stopColor={isHovered ? "#0F123F" : "#174CA8"} />
        </linearGradient>
        <clipPath id={`clip-${x}-${y}`}>
          <rect
            x={x}
            y={y}
            width={width}
            height={height > 0 ? height : 0}
            rx={radius}
            ry={radius}
          />
          <rect
            x={x}
            y={y + radius}
            width={width}
            height={height - radius > 0 ? height - radius : 0}
          />
        </clipPath>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={height > 0 ? height : 0}
        fill={`url(#gradient-${x})`}
        clipPath={`url(#clip-${x}-${y})`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </g>
  );
};

export default CustomBar;
