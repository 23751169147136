import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationUIEntity } from "../../../Entities/INotificationUIEntity";
import { TNotificationState } from "./types/TNotificationState";

const defaultInitialState: TNotificationState = {
  notifications: null,
};

const initialState: TNotificationState = defaultInitialState;

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificaitons: (
      state,
      action: PayloadAction<INotificationUIEntity[]>
    ) => {
      state.notifications = action.payload;
    },
    addNotificaiton: (state, action: PayloadAction<INotificationUIEntity>) => {
      if (!state.notifications) {
        state.notifications = [];
        state.notifications.push(action.payload);
      } else {
        state.notifications.push(action.payload);
      }
    },
  },
});

export const notificationReducer = notificationSlice.reducer;
export const notificationActions = notificationSlice.actions;
