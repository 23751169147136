import { Grid } from "@mui/material";
import { TAddSupplementPageProps } from "./types/TAddSupplementPageProps";
import AddSupplementsForm from "../Forms/AddSupplementsForm";
import SupplementTable from "../SupplementTable/SupplementTable";

export default function AddSupplementPage(props: TAddSupplementPageProps) {
  return (
    <div>
      <AddSupplementsForm
        itemId={props.selectedItemId}
        hasSupplementsChanged={props.hasSupplementsChanged}
        setHasSupplementsChanged={props.setHasSupplementsChanged}
      />
      <Grid container justifyContent={"center"} mb={2}>
        {props.supplementList.length > 0 && (
          <SupplementTable
            supplementList={props.supplementList}
            hasSupplementsChanged={props.hasSupplementsChanged}
            setHasSupplementsChanged={props.setHasSupplementsChanged}
          />
        )}
      </Grid>
    </div>
  );
}
