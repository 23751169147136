import { Grid, Typography } from "@mui/material";
import React from "react";
import { TCartItemPriceProps } from "./types/TCartItemPriceProps";

export default function CartItemPrice(props: TCartItemPriceProps) {
  const getClassName = (): string => {
    if (props.iconType == "checkbox") {
      return `priceTag-medium-green`;
    }
    if (props.rejected) {
      return `priceTag-medium-red`;
    }
    return `priceTag-medium-grey`;
  };
  return <Typography className={getClassName()}>{props.price} €</Typography>;
}
