import { Grid, IconButton } from "@mui/material";
import React from "react";
import DashboardHeaderSearchBar from "../SearchBar/SearchBar";
import { NotificationsOutlined, SettingsOutlined } from "@mui/icons-material";
import SettingsMenu from "../../../Settings/containers/SettingsMenu/SettingsMenu";

export default function DashboardHeader() {
  return (
    <>
      {/*<Grid item pl={2} pr={6}>
        <DashboardHeaderSearchBar />
      </Grid>*/}
      <Grid item pl={2} pr={2}>
        <SettingsMenu />
      </Grid>
      <Grid item pl={2} pr={2}>
        <>
          <IconButton onClick={() => {}}>
            <NotificationsOutlined sx={{ color: "#667085" }} />
          </IconButton>
        </>
      </Grid>
    </>
  );
}
