import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import { customFormActions } from "../../../../common/store/customFormSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import useAuthRepository from "../../repository/useAuthRepository";
import CustomEmailField from "./CustomEmailField";
import CustomPasswordField from "./CustomPasswordField";
import { TSignInFormAttributes } from "./types/TSignInFormAttributes";
import CustomTextField from "../../../../common/containers/Form/CustomTextFields";

const SignInForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { onSignIn } = useAuthRepository();

  const submitHandler: SubmitHandler<TSignInFormAttributes> = (data) => {
    return onSignIn({ ...data });
  };
  const successHandler = () => {
    navigate("/dashboard");
  };

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(customFormActions.setFormSuccess("sign-in-form"));
    }
  }, [!!isAuthenticated]);

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Connexion"
      onSuccess={() => successHandler()}
      name="sign-in-form"
    >
      <Grid item mb={4}>
        <CustomTextField
          name="email"
          label="Email/identifiant"
          validation={{ required: "Veuillez renseigner un email/identifiant" }}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomPasswordField
          name="password"
          label="Mot de passe"
          loginDetails={true}
        />
      </Grid>
    </CustomForm>
  );
};

export default SignInForm;
