import { v4 as uuidv4 } from "uuid";

/**
 * Truncates a string to a specified number of words.
 * @param text The input string to truncate.
 * @param maxWords The maximum number of words to keep.
 * @param ellipsis The string to append when truncating (default: '...').
 * @returns The truncated string.
 */
export const truncateWords = (
  text: string,
  maxWords: number,
  ellipsis: string = "..."
): string => {
  if (!text || typeof text !== "string") {
    return "";
  }

  if (maxWords <= 0) {
    return "";
  }

  const words = text.trim().split(/\s+/);
  if (words.length <= maxWords) {
    return text;
  }

  return words.slice(0, maxWords).join(" ") + ellipsis;
}; /**
 * Estimates the number of lines a text would occupy given a maximum width and font size.
 * @param text The input string to estimate.
 * @param maxWidth The maximum width of the container in pixels.
 * @param fontSize The font size in pixels.
 * @param fontFamily The font family (default: 'Arial').
 * @returns The estimated number of lines.
 */
export const estimateLines = (
  text: string,
  maxWidth: number,
  fontSize: number,
  fontFamily: string = "Arial"
): number => {
  if (!text || typeof text !== "string") {
    return 0;
  }

  // Create a hidden element to measure text width
  const element = document.createElement("span");
  element.style.fontFamily = fontFamily;
  element.style.fontSize = `${fontSize}px`;
  element.style.position = "absolute";
  element.style.whiteSpace = "nowrap";
  element.style.visibility = "hidden";
  document.body.appendChild(element);

  let totalLines = 1;
  let currentLine = "";

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const testLine = currentLine + char;
    element.textContent = testLine;

    if (element.offsetWidth > maxWidth) {
      totalLines++;
      currentLine = char;
    } else {
      currentLine = testLine;
    }
  }

  // Clean up
  document.body.removeChild(element);

  return totalLines;
};

/**
 * Truncates a string to a specified number of lines.
 * @param text The input string to truncate.
 * @param maxLines The maximum number of lines to keep.
 * @param maxWidth The maximum width of the container in pixels.
 * @param fontSize The font size in pixels.
 * @param fontFamily The font family (default: 'Arial').
 * @param ellipsis The string to append when truncating (default: '...').
 * @returns The truncated string.
 */
export const truncateLines = (
  text: string,
  maxLines: number,
  maxWidth: number,
  fontSize: number,
  fontFamily: string = "Arial",
  ellipsis: string = "..."
): string => {
  if (!text || typeof text !== "string") {
    return "";
  }

  if (maxLines <= 0 || maxWidth <= 0) {
    return "";
  }

  // Create a hidden element to measure text width
  const element = document.createElement("span");
  element.style.fontFamily = fontFamily;
  element.style.fontSize = `${fontSize}px`;
  element.style.position = "absolute";
  element.style.whiteSpace = "nowrap";
  element.style.visibility = "hidden";
  document.body.appendChild(element);

  let result = "";
  let currentLine = "";
  let lineCount = 1;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const testLine = currentLine + char;
    element.textContent = testLine;

    if (element.offsetWidth > maxWidth) {
      if (lineCount === maxLines) {
        // If we're on the last allowed line, truncate and add ellipsis
        if (currentLine) {
          result += (result ? "\n" : "") + currentLine.trim();
        }
        break;
      }
      result += (result ? "\n" : "") + currentLine.trim();
      currentLine = char;
      lineCount++;
    } else {
      currentLine = testLine;
    }
  }

  // Add the last line if there's room
  if (lineCount <= maxLines && currentLine) {
    result += (result ? "\n" : "") + currentLine.trim();
  }

  // Add ellipsis if text was truncated
  if (
    lineCount > maxLines ||
    (lineCount === maxLines && currentLine.length < text.length)
  ) {
    const lastLineBreak = result.lastIndexOf("\n");
    let lastLine =
      lastLineBreak >= 0 ? result.slice(lastLineBreak + 1) : result;

    element.textContent = lastLine + ellipsis;
    if (element.offsetWidth > maxWidth) {
      while (element.offsetWidth > maxWidth && lastLine.length > 0) {
        lastLine = lastLine.slice(0, -1);
        element.textContent = lastLine + ellipsis;
      }
    }

    result =
      lastLineBreak >= 0
        ? result.slice(0, lastLineBreak) + "\n" + lastLine + ellipsis
        : lastLine + ellipsis;
  }

  // Clean up
  document.body.removeChild(element);

  return result;
};

/**
 * Truncates a string to a specified number of characters.
 * @param text The input string to truncate.
 * @param maxChars The maximum number of characters to keep.
 * @param ellipsis The string to append when truncating (default: '...').
 * @returns The truncated string.
 */
export const truncateChars = (
  text: string,
  maxChars: number,
  ellipsis: string = "..."
): string => {
  if (!text || typeof text !== "string") {
    return "";
  }

  if (maxChars <= 0) {
    return "";
  }

  if (text.length <= maxChars) {
    return text;
  }

  return text.slice(0, maxChars - ellipsis.length) + ellipsis;
};

export function calculateLines(text: string, maxCharsPerLine: number): number {
  // Remove leading/trailing whitespace and split the text into lines
  const lines = text.trim().split("\n");
  let totalLines = 0;

  for (const line of lines) {
    if (line.length <= maxCharsPerLine) {
      // If the line fits, count it as one line
      totalLines++;
    } else {
      // If the line is too long, calculate how many lines it will take
      totalLines += Math.ceil(line.length / maxCharsPerLine);
    }
  }

  return totalLines;
}

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export function generateNumericId(): number {
  const uuid = uuidv4().replace(/\D/g, ""); // Retire tous les caractères non numériques
  return parseInt(uuid.slice(0, 10), 10); // Garde les 10 premiers chiffres pour un ID unique
}
