import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../common/store/redux";
import { TCreateMenuRequest } from "../service/Requests/TCreateMenuRequest";
import { createMenu, deleteMenu, updateMenu } from "../service/menusServices";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { TUpdateMenuRequest } from "../service/Requests/TUpdateMenuRequest";
import { TDeleteMenuRequest } from "../service/Requests/TDeleteMenuRequest";

export default function useMenuRepository() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  async function onCreateMenu(
    requestData: TCreateMenuRequest
  ): Promise<IMenuEntity> {
    const resultAction = await dispatch(createMenu(requestData));

    if (createMenu.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    navigate(`/dashboard/menus/${resultAction.payload.id!}`);
    return resultAction.payload;
  }

  async function onUpdateMenu(
    requestData: TUpdateMenuRequest
  ): Promise<IMenuEntity> {
    const resultAction = await dispatch(updateMenu(requestData));

    if (updateMenu.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    return resultAction.payload;
  }
  async function onDeleteMenu(
    requestData: TDeleteMenuRequest
  ): Promise<boolean> {
    const resultAction = await dispatch(deleteMenu(requestData));

    if (deleteMenu.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    return resultAction.payload;
  }
  return { onUpdateMenu, onCreateMenu, onDeleteMenu };
}
