import React from "react";
import { useAppDispatch } from "../../../common/store/redux";
import { TAuthenticateUserRequest } from "../services/types/Requests/TAuthenticateUserRequest";
import {
  authenticateUser,
  signInUser,
  signOut,
  signUpUser,
} from "../services/authService";
import { menuPreviewActions } from "../../MenuPreview/slices/menuPreviewSlice";
import { IUserEntity } from "../../../common/Entities/IUserEntity";
import {
  clearAccessToken,
  clearRefreshToken,
  clearTokens,
  retrieveRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from "../../../utils/crypto";
import { TOnLogoutRequest } from "../services/types/Requests/TOnLogoutRequest";
import { authActions } from "../slices/authSlice";
import { TOnSignInRequest } from "../services/types/Requests/TOnSignInRequest";
import { customFormActions } from "../../../common/store/customFormSlice";
import { TOnSignInResponse } from "../services/types/Responses/TOnSignInResponse";
import { appActions } from "../../../common/store/appSlice";
import { TOnSignUpRequest } from "../services/types/Requests/TOnSignUpRequest";
import { TOnSignupResponse } from "../services/types/Responses/TOnSignUpResponse";

export default function useAuthRepository() {
  const dispatch = useAppDispatch();
  const onAuthenticateUser = async (
    requestData: TAuthenticateUserRequest
  ): Promise<IUserEntity | null> => {
    const resultAction = await dispatch(authenticateUser(requestData));

    if (authenticateUser.rejected.match(resultAction)) {
      return null;
    }
    const authenticatedUser: IUserEntity = resultAction.payload.user;
    return authenticatedUser;
  };
  const onSignOut = async (): Promise<boolean> => {
    const resultAction = await dispatch(
      signOut({ refreshToken: retrieveRefreshToken() })
    );

    if (signOut.rejected.match(resultAction)) {
      return false;
    }
    dispatch(authActions.signOutSuccess());
    clearTokens();
    return true;
  };

  const onSignUp = async (
    request: TOnSignUpRequest
  ): Promise<IUserEntity | null> => {
    const resultAction = await dispatch(signUpUser(request));

    if (signUpUser.rejected.match(resultAction)) {
      if (resultAction.type === "authentication.email_already_exists") {
        dispatch(
          customFormActions.setFormError({
            name: "sign-up-form",
            message: "Adresse email déjà utilisée",
          })
        );
      }
      return null;
    }
    const response: TOnSignupResponse = resultAction.payload;
    return response.user;
  };

  const onSignIn = async (
    request: TOnSignInRequest
  ): Promise<IUserEntity | null> => {
    const resultAction = await dispatch(signInUser(request));

    if (signInUser.rejected.match(resultAction)) {
      if (resultAction.type === "authentication.failed") {
        dispatch(
          customFormActions.setFormError({
            name: "sign-in-form",
            message: "Email ou mot de passe incorrect",
          })
        );
      }
      return null;
    }
    const response: TOnSignInResponse = resultAction.payload;
    if (response.user.emailValidated) {
      if (response.authToken.refreshToken) {
        clearRefreshToken();
        storeRefreshToken(response.authToken.refreshToken);
      }
      clearAccessToken();
      storeAccessToken(response.authToken.accessToken);
      dispatch(authActions.signInSuccess(response));
    } else {
      dispatch(
        appActions.setRequiredInformationMessage(
          "Veuillez valider votre adresse email en cliquant sur le lien qui vous a été envoyé par email pour pouvoir vous connecter."
        )
      );
    }
    return response.user;
  };
  return { onAuthenticateUser, onSignOut, onSignIn, onSignUp };
}
