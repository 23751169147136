import React, { useEffect } from "react";
import UpdateMenuItemTextField from "../../../item/container/Forms/UpdateMenuItemTextField";
import UpdateMenuItemNumberField from "../../../item/container/Forms/UpdateMenuItemNumberField";
import { useFormContext } from "react-hook-form";
import { TAddSupplementsFieldsProps } from "./types/TAddSupplementsFieldsProps";
import { Grid } from "@mui/material";

export default function AddSupplementsFields(
  props: TAddSupplementsFieldsProps
) {
  const { watch } = useFormContext();
  const supplementName = watch("supplementName");
  const supplementMaxQuantity = watch("supplementMaxQuantity");
  const supplementPrice = watch("supplementPrice");

  useEffect(() => {
    console.log("supplementName", supplementName);
    console.log("supplementMaxQuantity", supplementMaxQuantity);
    console.log("supplementPrice", supplementPrice);
    if (
      !supplementName ||
      supplementName === "" ||
      !supplementMaxQuantity ||
      supplementMaxQuantity === "" ||
      !supplementPrice ||
      supplementPrice === ""
    ) {
      console.log("dans le false");
      props.setShowSubmitButton(false);
    }
    if (supplementName && supplementMaxQuantity && supplementPrice) {
      console.log("dans le true");
      props.setShowSubmitButton(true);
    }
  }, [supplementName, supplementMaxQuantity, supplementPrice]);
  return (
    <Grid container>
      <UpdateMenuItemTextField
        name="supplementName"
        label="Nom Garniture"
        validation={{
          required: " ",
        }}
        isBold={false}
        height="34px"
        width="130px"
      />

      <UpdateMenuItemNumberField
        name="supplementMaxQuantity"
        label="Qty/Max"
        validation={{
          required: " ",
        }}
        width="100px"
        height="36px"
      />

      <UpdateMenuItemNumberField
        name="supplementPrice"
        label="Prix/Unité"
        validation={{
          required: " ",
        }}
        currency="€"
        width="100px"
        height="36px"
      />
    </Grid>
  );
}
