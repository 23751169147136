import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyledTextField } from "./StyledTextField";
import { TUpdateMenuItemTextFieldProps } from "./types/TUpdateMenuItemTextFieldProps";
import { capitalizeFirstLetter } from "../../../../../../common/containers/Form/CustomForm";

const UpdateMenuItemTextField = (
  props: TUpdateMenuItemTextFieldProps & {
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  }
) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const errorMessage = errors[props.name]?.message;

  useEffect(() => {
    console.log("changed", props.defaultValue);
    setValue(props.name, props.defaultValue || "");
  }, [props.defaultValue, props.name, setValue]);

  return (
    <Grid item m={2}>
      <Controller
        name={props.name}
        control={control}
        rules={props.validation}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <StyledTextField
            {...field}
            value={
              props.firstLetterUppercase
                ? capitalizeFirstLetter(field.value)
                : field.value || ""
            }
            onChange={(e) => field.onChange(e.target.value)}
            label={props.label}
            variant="outlined"
            type="text"
            error={!!errorMessage}
            width={props.width}
            height={props.height}
            isBold={props.isBold}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              props.onKeyDown?.(e)
            }
            multiline={props.multiline}
            rows={props.multiline ? props.rows || 4 : undefined}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </Grid>
  );
};

export default UpdateMenuItemTextField;
