import { Grid } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../../common/containers/Form/CustomTextFields";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TCreateMenuRequest } from "../../service/Requests/TCreateMenuRequest";
import { menuActions } from "../../slice/menuSlice";
import CustomMenuTimePicker from "./CustomMenuTimePicker";
import { TCreateMenuFormAttributes } from "./types/TCreateMenuFormAttributes";
import { TCreateMenuFormProps } from "./types/TCreateMenuFormProps";
import useMenuRepository from "../../repository/useMenuRepository";

const CreateMenuForm = (props: TCreateMenuFormProps) => {
  const { onCreateMenu } = useMenuRepository();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const submitHandler: SubmitHandler<TCreateMenuFormAttributes> = async (
    data
  ) => {
    const activeFromDate = data.activeFrom ? data.activeFrom.toDate() : null;
    const activeToDate = data.activeTo ? data.activeTo.toDate() : null;

    const requestData = {
      ...data,
      activeFrom: activeFromDate,
      activeTo: activeToDate,
      type: "CUSTOM",
    } as TCreateMenuRequest;

    await onCreateMenu(requestData);
  };

  const successHandler = () => {
    dispatch(menuActions.setIsCreateMenuModalOpen(false));
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Créer le menu"
      onSuccess={successHandler}
      name="create-menu-form"
      useDefaultErrorDisplay={true}
      defaultErrorDisplayCollapse={true}
    >
      <Grid item mb={3}>
        <CustomTextField
          name="name"
          label="Nom du menu"
          validation={{ required: "Veuillez renseigner le nom de votre menu" }}
          firstLetterUppercase={true}
        />
      </Grid>
      <CustomMenuTimePicker />
    </CustomForm>
  );
};

export default CreateMenuForm;
