import { PanoramaOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { TBlankImageBoxProps } from "./types/TBlankImageBoxProps";

export default function BlankImageBox(props: TBlankImageBoxProps) {
  return (
    <Grid
      container
      sx={{
        border: !props.hovered ? "1px solid #ccc" : "",
        width: `100%`,
        height: `100%`,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={props.handleUpdatePreviewImage}
    >
      <PanoramaOutlined
        sx={{ color: "rgba(0, 0, 0, 0.3)", width: "50%", height: "50%" }}
      />
    </Grid>
  );
}
