import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAuthState } from "./types/TAuthState";
import { set } from "react-hook-form";
import { IRestaurantUserEntity } from "../../../common/Entities/IRestaurantUserEntity";
import { clear } from "console";
import { IUserEntity } from "../../../common/Entities/IUserEntity";
import { TOnSignInResponse } from "../services/types/Responses/TOnSignInResponse";
import { TOnSignupResponse } from "../services/types/Responses/TOnSignUpResponse";

const initialState: TAuthState = {
  user: null,
  isAuthenticated: null,
  isOATValid: true,
  justSignedIn: false,
  justSignedUp: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInSuccess: (state, action: PayloadAction<TOnSignInResponse>) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.justSignedIn = true;
    },

    signUpSuccess: (state, action: PayloadAction<TOnSignupResponse>) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.justSignedUp = true;
    },
    signOutSuccess: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    refreshUserFailure: (state) => {
      state.isOATValid = false;
      state.user = null;
      state.isAuthenticated = false;
    },
    refreshUserSuccess: (state, action: PayloadAction<IUserEntity>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setJustSignedIn: (state, action: PayloadAction<boolean>) => {
      state.justSignedIn = action.payload;
    },
    setJustSignedUp: (state, action: PayloadAction<boolean>) => {
      state.justSignedUp = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
