import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { TDeleteIAMConfirmationModalProps } from "./types/TDeleteIAMConfirmationModalProps";
import { useAppDispatch } from "../../../../common/store/redux";

export default function DeleteIAMConfirmationModal(
  props: TDeleteIAMConfirmationModalProps
) {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    props.setIsOpen(false);
  };

  return (
    <>
      <MuiDialog
        open={props.isOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        // Handle backdrop click to close modal using slotProps
        slotProps={{
          backdrop: true,
        }}
      >
        <DialogTitle>
          Suppression de l'utilisateur {props.firstName} {props.lastName}
        </DialogTitle>

        <DialogContent>
          Êtes-vous sûr de vouloir supprimer l'utilisateur {props.firstName}{" "}
          {props.lastName}?
        </DialogContent>
        <Grid container className="grid-center" mb={2} spacing={2}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button
              className="blue-round-button"
              onClick={props.handleDeleteIAM}
            >
              Oui
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button className="blue-round-button" onClick={handleCloseModal}>
              Non
            </Button>
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
}
