import { KeyboardArrowRight } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { clearCartState } from "../../../../utils/cart/cartLocalStorage";
import { TReturnToPaymentButtonProps } from "./types/TReturnToPaymentButtonProps";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { useNavigate } from "react-router-dom";

export default function ReturnToPaymentButton(
  props: TReturnToPaymentButtonProps
) {
  const paymentAvailable = useAppSelector(
    (state) => state.menuPreview.paymentAvailable
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGoToPayment = () => {
    clearCartState();
    navigate(
      `/customerMenu/payment?userRef=${props.userRef}&table=${props.table}&menuId=${props.menuId}`
    );
    dispatch(menuPreviewActions.setBackToMenu(false));
  };
  return (
    <div style={props.style}>
      {paymentAvailable === true && (
        <Grid item xs={12} ml={1}>
          <Button
            className="return-to-payment-button"
            onClick={handleGoToPayment}
          >
            Retourner au paiement
            <KeyboardArrowRight />
          </Button>
        </Grid>
      )}
    </div>
  );
}
