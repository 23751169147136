import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import SelectRoleField from "./SelectRoleField";
import { IUserRoleEntity } from "../../../../common/Entities/IRoleEntity";
import { TUpdateIAMFieldsProps } from "./types/TUpdateIAMFieldsProps";
import { useFormContext } from "react-hook-form";

export default function UpdateIAMFields(props: TUpdateIAMFieldsProps) {
  const { watch } = useFormContext();
  const role: IUserRoleEntity = watch("role");
  const rolesToStringList = (roles: IUserRoleEntity[]): string[] => {
    return roles.map((role) => role.name);
  };

  useEffect(() => {
    console.log("changed role", role);
    if (role && role.id === props.previousRole.id) {
      props.setIsFormDisabled(true);
    } else {
      props.setIsFormDisabled(false);
    }
  }, [role]);
  return (
    <Grid item mb={4}>
      <SelectRoleField
        name="role"
        label="Choisir un rôle"
        placeholder="Choisir un rôle"
        roles={props.roles}
        validation={{
          required: "Veuillez choisir un rôle",
        }}
        defaultValue={props.previousRole}
      />
    </Grid>
  );
}
