import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingBox() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
      sx={{
        backgroundColor: "white",
        position: "fixed",
        top: "0",
        zIndex: "9999999",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
