import React, { useEffect, useState } from "react";
import { CustomForm } from "../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../common/containers/Form/CustomTextFields";
import { Box, Grid } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { cartActions } from "../../../slices/cartSlice";
import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { customFormActions } from "../../../../../common/store/customFormSlice";
import { ICustomFormEntity } from "../../../../../common/Entities/ICustomFormEntity";
import { TAddCustomTipFormAttributes } from "./types/TAddCustomTipFormAttributes";
import MaxQuantityField from "../../../../Menu/modules/item/container/Forms/MaxQuantityField";
import CustomTipField from "./CustomTipField";

export default function AddCustomTipForm() {
  const dispatch = useAppDispatch();
  const forms = useAppSelector((state) => state.form.forms);

  const submitHandler: SubmitHandler<TAddCustomTipFormAttributes> = (data) => {
    dispatch(cartActions.setTip(data.customTip));
    dispatch(customFormActions.setFormSuccess("add-custom-tip-form"));
  };
  const successHandler = () => {
    dispatch(cartActions.setOpenAddCustomTipPopup(false));
  };
  return (
    <CustomForm
      onSubmit={submitHandler}
      onSuccess={successHandler}
      defaultButtonText="Ajouter"
      name="add-custom-tip-form"
      useDefaultErrorDisplay={false}
    >
      <Box mb={2} width={"100%"}>
        <CustomTipField
          name="customTip"
          label="Montant"
          validation={{
            required: " ",
          }}
        />
      </Box>
    </CustomForm>
  );
}
