import { Avatar, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import MoreMenu from "../MoreMenu/MoreMenu";
import { TIAMTableRowProps } from "./types/TIAMTableRowProps";
import { getUserFriendlyRoleName } from "../../../../utils/roles/shared";

export default function IAMTableRow(props: TIAMTableRowProps) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);

  const handleOpenMoreMenu = () => {
    setIsMoreMenuOpen(true);
  };

  return (
    <TableRow key={props.id} sx={{ border: "none", borderColor: "red" }}>
      <TableCell sx={{ borderBottom: "none" }}>
        <Grid container alignItems={"center"}>
          <Grid item mr={1}>
            <Avatar />
          </Grid>
          <Grid item>
            <Grid container direction={"column"}>
              <Typography
                sx={{
                  color: "#101828",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {props.firstName} {props.lastName}
              </Typography>
              <Typography
                sx={{
                  color: "#6B788E",
                  fontSize: "14px",
                }}
              >
                {props.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell sx={{ borderBottom: "none" }}>
        <Typography
          sx={{
            color: "#42526D",
            fontSize: "14px",
          }}
        >
          {props.validatedUser ? "Validé" : "En attente"}
        </Typography>
      </TableCell>
      <TableCell sx={{ borderBottom: "none" }}>
        <Typography
          sx={{
            color: "#42526D",
            fontSize: "14px",
          }}
        >
          {getUserFriendlyRoleName(props.previousRole.name)}
        </Typography>
      </TableCell>
      <TableCell sx={{ borderBottom: "none" }}>
        <MoreMenu
          validatedUser={props.validatedUser}
          firstName={props.firstName}
          lastName={props.lastName}
          previousRole={props.previousRole}
          roles={props.roles}
          iamId={props.id}
        />
      </TableCell>
    </TableRow>
  );
}
