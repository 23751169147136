import { DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import BlueRoundButton from "../../../../../../common/containers/Buttons/BlueRoundButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { TUpdateImageModalProps } from "./types/TUpdateImageModalProps";
import { imageActions } from "../../slices/imageSlice";
import UploadImageBox from "../UploadImageBox/UploadImageBox";
import useMenuItemImageRepository from "../../repository/useMenuItemImageRepository";
import { useRef } from "react";

export default function UpdateImageModal(props: TUpdateImageModalProps) {
  const dispatch = useAppDispatch();
  const { onUpdateMenuItemImge } = useMenuItemImageRepository({
    selectedItem: props.selectedItem,
    selectedMenu: props.selectedMenu,
    selectedCategory: props.selectedCategory,
  });
  const justUploadedImage = useRef<boolean>(false);
  const handleCloseModal = () => {
    props.setIsUpdateImageModalOpen(false);
    justUploadedImage.current === false &&
      props.setPreviewImage(props.selectedItem.menuItemImage.image.url);

    props.setFileToUpload(null);
    props.setDeleteImage(false);
    justUploadedImage.current = false;
  };

  const handleUploadImage = async (file: File | null) => {
    await onUpdateMenuItemImge({
      menuItemImage: props.selectedItem.menuItemImage,
      file: file,
    });
    justUploadedImage.current = true;
    handleCloseModal();
  };
  return (
    <>
      <MuiDialog
        open={props.isUpdateImageModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
      >
        <DialogTitle>Modification de l'image </DialogTitle>
        <Grid container justifyContent={"center"} p={2} direction={"column"}>
          <Grid item>{props.showRequirementMessage()}</Grid>
          <Grid item>
            <Grid container justifyContent={"center"}>
              <UploadImageBox
                fileToUpload={props.fileToUpload}
                previewImage={props.previewImage}
                setFileToUpload={props.setFileToUpload}
                setPreviewImage={props.setPreviewImage}
                item={props.selectedItem}
                width="390px"
                height="405px"
                setDeleteImage={props.setDeleteImage}
              />
            </Grid>
          </Grid>
          <Grid item mt={3}>
            {props.deleteImage ? (
              <>
                <BlueRoundButton
                  title="Supprimer"
                  onClick={() => {
                    handleUploadImage(null);
                  }}
                />
              </>
            ) : (
              <>
                <BlueRoundButton
                  title="Enregistrer"
                  onClick={() => {
                    handleUploadImage(props.fileToUpload);
                  }}
                  disabled={props.fileToUpload ? false : true}
                />
              </>
            )}
          </Grid>
          <Grid item mt={1}>
            <BlueRoundButton title="Annuler" onClick={handleCloseModal} />
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
}
