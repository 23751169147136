import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { TSideBarButtonProps } from "./types/TSideBarButtonProps";
import "../../../../common/containers/styles/customFonts.css";

// Define your custom styles
const styles = {
  button: {
    backgroundColor: "white",
    borderRadius: "20px",
    height: "34px",
  },
  buttonSelected: {
    backgroundColor: "#062E4B",
  },
  icon: {
    color: "blue",
    fontSize: "20px",
  },
  iconSelected: {
    color: "white",
  },
  text: {
    fontSize: "14px",
    fontWeight: 300,
    color: "#091E42",
  },
  textSelected: {
    color: "white",
    fontWeight: 600,
  },
};

export default function SideBarButton(props: TSideBarButtonProps) {
  const location = useLocation();
  const currentPath = location.pathname; // Ensure currentPath is defined first
  // Check if the current path matches exactly or starts with the specified link
  const isExactMatch = currentPath === props.link;
  const isStartsWithMatch =
    currentPath.startsWith(props.link) &&
    currentPath !== "/dashboard" &&
    props.link !== "/dashboard";

  const isSelected = isExactMatch || isStartsWithMatch || props.selected;

  return (
    <Link
      to={props.link}
      style={{ textDecoration: "none", color: "inherit" }}
      onClick={props.onClick}
    >
      <Grid item mt={2} mb={2}>
        <Grid
          container
          sx={{
            ...styles.button,
            ...(isSelected && styles.buttonSelected),
            "&:hover": {
              backgroundColor: `${!isSelected && "rgba(0, 0, 0, 0.04)"}`,
            },
          }}
          alignItems="center"
          pl={1}
          pr={1}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isSelected ? <>{props.iconSelected}</> : <>{props.icon}</>}
          </Grid>
          <Grid item pl={2}>
            <Typography
              className="font-avenir"
              sx={{
                ...styles.text,
                ...(isSelected && styles.textSelected),
              }}
            >
              {props.text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
}
