import { Grid } from "@mui/material";
import KitchenButton from "../KitchenButton/KitchenButton";
import { TKitchenTicketFooterButtonsProps } from "./types/TKitchenTicketFooterButtonsProps";

export default function KitchenTicketFooterButtons(
  props: TKitchenTicketFooterButtonsProps
) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      mt={2}
      mb={2}
      pl={2}
      pr={2}
    >
      <KitchenButton
        title="Rejetter"
        color="RED"
        onClick={props.handleOpenRejectModal}
      />
      <KitchenButton
        title="Modifier"
        color="BLUE"
        onClick={props.handleOpenEditModal}
      />
      <KitchenButton
        title="Terminer"
        color="GREEN"
        onClick={props.handleOpenCloseModal}
      />
    </Grid>
  );
}
