import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import {
  clearCartState,
  loadCartState,
} from "../../../utils/cart/cartLocalStorage";
import { TGetAllPendingCartsResponse } from "./types/Reponses/TGetAllPendingCartsResponse";
import { TRejectCartItemRequest } from "./types/Requests/TRejectCartItemRequest";
import { TRejectCartItemListRequest } from "./types/Requests/TRejectCartItemListRequest";
import { TRejectAllCartItemRequest } from "./types/Requests/TRejectAllCartItemRequest";
import { TCloseCartRequest } from "./types/Requests/TCloseCartRequest";

export const getAllPendingCarts = createAsyncThunk(
  "kitchen/initiate-cart",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetAllPendingCartsResponse>(
      dispatch,
      "/api/kitchen/find-pending-carts",
      MethodEnum.GET,
      null,
      false
    );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const rejectCartItem = createAsyncThunk(
  "kitchen/reject-cart-item",
  async (request: TRejectCartItemRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest(
      dispatch,
      "/api/kitchen/reject-cart-item",
      MethodEnum.PUT,
      request,
      false
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const rejectCartItemList = createAsyncThunk(
  "kitchen/reject-cart-item-list",
  async (
    request: TRejectCartItemListRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest(
      dispatch,
      "/api/kitchen/reject-cart-item-list",
      MethodEnum.PUT,
      request,
      false
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const rejectAllCart = createAsyncThunk(
  "kitchen/reject-all-cart-item",
  async (request: TRejectAllCartItemRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest(
      dispatch,
      "/api/kitchen/reject-all-cart-item",
      MethodEnum.PUT,
      request,
      false
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const closeCart = createAsyncThunk(
  "kitchen/close-cart",
  async (request: TCloseCartRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest(
      dispatch,
      "/api/kitchen/close-cart",
      MethodEnum.PUT,
      request,
      false
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);
