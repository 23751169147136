import React from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface TScanQRCodeFieldProps {
  name: string;
  label: string;
  validation?: Record<string, unknown>;
  defaultValue?: number;
  width?: string;
  height?: string;
}

const ScanQRCodeField = (props: TScanQRCodeFieldProps) => {
  const { control } = useFormContext();

  return (
    <Grid item m={2}>
      <Controller
        name={props.name}
        control={control}
        rules={props.validation}
        defaultValue={props.defaultValue}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={props.label}
            value={field.value ?? ""}
            onChange={(e) =>
              field.onChange(
                e.target.value === "" ? "" : Number(e.target.value)
              )
            }
            variant="outlined"
            type="number"
            error={!!error}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
            }}
            fullWidth
          />
        )}
      />
    </Grid>
  );
};

export default ScanQRCodeField;
