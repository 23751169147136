import React from "react";
import { TKitchenButtonProps } from "./types/TKitchenButtonProps";
import { Button } from "@mui/material";

export default function KitchenButton(props: TKitchenButtonProps) {
  const backgroundColor: string =
    props.color === "RED"
      ? "#F80000"
      : props.color === "GREEN"
        ? "#00AC4F"
        : "#091E42";
  const hoveredBackgroundColor: string =
    props.color === "RED"
      ? "rgba(248, 0, 0, 0.9)"
      : props.color === "GREEN"
        ? "rgba(0, 172, 79, 0.9)"
        : "rgba(9, 30, 66, 0.9)";
  return (
    <Button
      sx={{
        fontSize: "10px",
        fontWeight: "500",
        backgroundColor: backgroundColor,
        color: "white",
        height: "20px",
        width: "60px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: hoveredBackgroundColor,
        },
      }}
      onClick={props.onClick}
    >
      {props.title}
    </Button>
  );
}
