import { IUserRoleEntity } from "../../common/Entities/IRoleEntity";

interface RoleConfig {
  friendlyName: string;
  visible: boolean;
}

export const roleNameMapping: Record<string, RoleConfig> = {
  WAITER: { friendlyName: "Serveur", visible: true },
  ROOT: { friendlyName: "Utilisateur racine", visible: false },
  SUPERADMIN: { friendlyName: "Superadmin", visible: false },
  ANY: { friendlyName: "Membre", visible: false },
  CHEF: { friendlyName: "Chef", visible: true },
};

export function getUserFriendlyRoleName(dbRoleName: string): string {
  return roleNameMapping[dbRoleName]?.friendlyName || dbRoleName;
}

export function isRoleVisible(dbRoleName: string): boolean {
  return roleNameMapping[dbRoleName]?.visible ?? false; // Default to visible if not specified
}

export function getVisibleRoles(
  userRoles: IUserRoleEntity[]
): IUserRoleEntity[] {
  return userRoles.filter((userRole) => isRoleVisible(userRole.name));
}

export function isRoleEditable(userRole: IUserRoleEntity): boolean {
  return userRole.name === "ROOT";
}
