import { Grid } from "@mui/material";
import React from "react";
import { TQuantityIconsProps } from "./types/TQuantityIconsProps";
import ItemQuantityBox from "../../modules/Validation/containers/ItemQuantityBox/ItemQuantityBox";

export default function QuantityIcons(props: TQuantityIconsProps) {
  return (
    <div>
      {props.showQuantityBox && (
        <ItemQuantityBox
          quantity={props.cartItem.quantity}
          cartIndexes={props.cartIndexes}
        />
      )}
      {props.iconType === "checkbox" && (
        <span className="listItem-heading-medium-secondary">
          x{props.cartItem.quantity}
        </span>
      )}
    </div>
  );
}
