import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";
import { TMenuItemCustomizationSliceState } from "./types/TMenuItemCustomizationSliceState";
import { IMenuItemCustomizationEntity } from "../../../../../common/Entities/IMenuItemCustomizationEntity";
import { generateNumericId } from "../../../../../utils/sharedFunctions";

const initialState: TMenuItemCustomizationSliceState = {
  menuItemCustomizations: [],
  selectedMenuItemCustomization: null,
  isCreateMenuItemCustomizationModalOpen: false,
  hasItemCustomizationChanged: false,
};

const menuItemCustomizationSlice = createSlice({
  name: "menuItemCustomizationSlice",
  initialState,
  reducers: {
    setSelectedMenuItemCustomization: (
      state,
      action: PayloadAction<IMenuItemCustomizationEntity>
    ) => {
      state.selectedMenuItemCustomization = action.payload;
    },
    setMenuItemCustomizations: (
      state,
      action: PayloadAction<IMenuItemCustomizationEntity[]>
    ) => {
      state.menuItemCustomizations = action.payload;
    },

    setHasItemCustomizationChanged: (state, action: PayloadAction<boolean>) => {
      state.hasItemCustomizationChanged = action.payload;
    },
    setIsCreateMenuItemCustomizationModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateMenuItemCustomizationModalOpen = action.payload;
    },

    addMenuItemCustomization: (
      state,
      action: PayloadAction<IMenuItemCustomizationEntity>
    ) => {
      state.menuItemCustomizations.push({
        ...action.payload,
      });
      state.hasItemCustomizationChanged = true;
    },
    updateMenuItemCustomizations: (
      state,
      action: PayloadAction<{
        menuItemCustomizationListIndex: number;
        newMenuItemCustomization: IMenuItemCustomizationEntity;
      }>
    ) => {
      let toUpdate = [
        ...state.menuItemCustomizations.map((menuItemCustomization, index) => {
          if (index === action.payload.menuItemCustomizationListIndex) {
            menuItemCustomization.name =
              action.payload.newMenuItemCustomization.name;
            menuItemCustomization.maxQuantity =
              action.payload.newMenuItemCustomization.maxQuantity;
            menuItemCustomization.required =
              action.payload.newMenuItemCustomization.required;
            menuItemCustomization.menuItemCustomizationOptions = [
              ...action.payload.newMenuItemCustomization
                .menuItemCustomizationOptions,
            ];
          }
          return menuItemCustomization;
        }),
      ];
      state.menuItemCustomizations = toUpdate;
      state.hasItemCustomizationChanged = true;
    },
    deleteMenuItemCustomizationOption: (
      state,
      action: PayloadAction<{
        menuItemCustomizationListIndex: number;
        menuItemCustomizationOptionIndex: number;
      }>
    ) => {
      let newList = [
        ...state.menuItemCustomizations.map((menuItemCustomization, index) => {
          if (index === action.payload.menuItemCustomizationListIndex) {
            menuItemCustomization.menuItemCustomizationOptions = [
              ...menuItemCustomization.menuItemCustomizationOptions.filter(
                (option, optionIndex) =>
                  optionIndex !==
                  action.payload.menuItemCustomizationOptionIndex
              ),
            ];
          }
          return menuItemCustomization;
        }),
      ];
      state.menuItemCustomizations = newList;
      state.hasItemCustomizationChanged = true;
    },
    deleteMenuItemCustomization: (
      state,
      action: PayloadAction<{
        customizationIndex: number;
      }>
    ) => {
      let newList = [
        ...state.menuItemCustomizations.filter(
          (menuItemCustomization, index) =>
            index !== action.payload.customizationIndex
        ),
      ];
      state.menuItemCustomizations = newList;
      state.hasItemCustomizationChanged = true;
    },
  },
});

export const menuItemCustomizationReducer = menuItemCustomizationSlice.reducer;

export const menuItemCustomizationActions = menuItemCustomizationSlice.actions;
