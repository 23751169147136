import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import AccountBalance from "../../../Account/modules/FundsManagement/containers/AccountBalance/AccountBalance";
import AccountEarnings from "../../../Account/modules/FundsManagement/containers/AccountEarnings/AccountEarnings";
import AccountOrders from "../../../Account/modules/FundsManagement/containers/AccountOrders/AccountOrders";
import useFundsManagementRepository from "../../../Account/modules/FundsManagement/repository/useFundsManagementRepository";
import { getStripeAccount } from "../../../Account/services/stripeAccountService";
import useCartRepository from "../../../Cart/repository/useCartRepository";
import { onFindAllPaymentsByUserRefAndDate } from "../../../Payment/services/paymentServices";
import useReviewsRepository from "../../../Reviews/repository/useReviewsRepository";
import DashboardStripeAccountButton from "../CreateStripeAccountButton/DashboardStripeAccountButton";
import EarningChart from "../EarningChart/EarningChart";
import ReviewsPreview from "../ReviewsPreview/ReviewsPreview";

export default function DashboardHomePage() {
  const dispatch = useAppDispatch();
  const stripeAccount = useAppSelector(
    (state) => state.stripeAccount.stripeAccount
  );
  const payments = useAppSelector((state) => state.payment.payments);
  const justSignedIn = useAppSelector((state) => state.auth.justSignedIn);
  const monthOrders = useAppSelector((state) => state.cart.monthOrders);
  const { onFindMonthOrders } = useCartRepository();
  const reviewsReport = useAppSelector((state) => state.reviews.reviewsReport);
  const { onFindAllReviews } = useReviewsRepository();
  const availableBalance = useAppSelector(
    (state) => state.fundsManagement.availableBalance
  );
  const { onFindAccountBalance } = useFundsManagementRepository();

  useEffect(() => {
    //Get today orders
    !monthOrders && onFindMonthOrders();
    //Get all reviews
    !reviewsReport && onFindAllReviews();
    !stripeAccount && dispatch(getStripeAccount());
  }, []);

  useEffect(() => {
    //Find available balance
    stripeAccount && !availableBalance && onFindAccountBalance();
  }, [stripeAccount]);

  useEffect(() => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    if (payments === null) {
      dispatch(
        onFindAllPaymentsByUserRefAndDate({
          startDate: startDate,
          endDate: now,
        })
      );
    }
  }, [dispatch, stripeAccount, JSON.stringify(payments)]);

  /*const showStripeNotificationMessage = (
    stripeAccount: IStripeAccountEntity,
    justSignedIn: boolean
  ) => {
    if (
      stripeAccount.verificationStatus === "additional_information_required" &&
      justSignedIn
    ) {
      console.log("justSignedIn", justSignedIn);
      let listOfDisabled: string[] = [];
      if (stripeAccount.chargesEnabled === false) {
        listOfDisabled.push("Recevoir des paiements");
      }
      if (stripeAccount.transferEnabled === false) {
        listOfDisabled.push("Virer de l'argent vers un compte externe");
      }
      const listOfDisabledJoined = listOfDisabled.join(", ");
      dispatch(
        appActions.setErrorMessage(
          `Les fonctionalitées suivantes ont été désactivée pour votre compte : ${listOfDisabledJoined}. Veuillez contacter le service client.`
        )
      );
    }
    if (stripeAccount.verificationStatus === "pending") {
      dispatch(
        appActions.setErrorMessage(
          `Les fonctionnalitées du dashboard sont désactivées temporairement. Vous devez compléter votre profil Stripe pour réactiver les fonctionnalités.`
        )
      );
    }
  };

  useEffect(() => {
    if (stripeAccount) {
      showStripeNotificationMessage(stripeAccount, justSignedIn);
      dispatch(authActions.setJustSignedIn(false));
    }
  }, []);*/

  const blurClass =
    !stripeAccount ||
    (stripeAccount && stripeAccount.verificationStatus === "pending")
      ? "blur"
      : "";

  const displayStripeAccountBox = (): JSX.Element => {
    if (!stripeAccount) {
      return (
        <>
          <DashboardStripeAccountButton accountCreated={false} />
        </>
      );
    }
    if (stripeAccount && stripeAccount.verificationStatus === "pending") {
      return (
        <>
          <DashboardStripeAccountButton accountCreated={true} />
        </>
      );
    }
    return (
      <div className={blurClass}>
        <AccountEarnings payments={payments || []} />
      </div>
    );
  };

  return (
    <Grid container width={"100%"} direction={"column"}>
      <Grid item>
        <Grid container direction={"row"}>
          <Grid item m={2}>
            {displayStripeAccountBox()}
          </Grid>
          <Grid item className={blurClass} m={2}>
            <AccountBalance />
          </Grid>
          <Grid item className={blurClass} m={2}>
            <AccountOrders />
          </Grid>
        </Grid>
      </Grid>
      <Grid item justifyContent={"start"}>
        <Grid item>
          <Typography>
            Virement vers des comptes externes :{" "}
            <span style={{ fontWeight: "bold" }}>
              {stripeAccount?.transferEnabled ? "Activé" : "Désactivé"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Réception des paiements :{" "}
            <span style={{ fontWeight: "bold" }}>
              {stripeAccount?.chargesEnabled ? "Activé" : "Désactivé"}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={blurClass} mt={5} mb={5}>
        <Box sx={{ display: "flex", flexWrap: "nowrap", width: "100%" }}>
          <Box sx={{ minWidth: "200px", maxWidth: "785px", width: "100%" }}>
            <EarningChart payments={payments || []} />
          </Box>
          <Box
            sx={{
              width: `calc(100% - 785})`,
              flexShrink: 0,
              padding: 2,
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            <ReviewsPreview reviewsReport={reviewsReport} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
