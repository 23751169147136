import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import CustomButton from "../../../../containers/Button/CustomButton";
import { TAddItemsButtonProps } from "./types/TAddItemsButtonProps";

export default function AddItemsButton(props: TAddItemsButtonProps) {
  const userRef = useAppSelector((state) => state.cart.currentCart.userRef);
  const table = useAppSelector((state) => state.cart.currentCart.table);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    navigate(
      `/customerMenu/empty?userRef=${props.userRef}&table=${props.table}&menuId=${props.menuId}`
    );
  };
  return (
    <CustomButton
      buttonText="+ Ajouter des articles"
      variant="secondary"
      handleOnClick={handleOnClick}
    />
  );
}
