import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

interface StyledNumberFieldProps {
  width?: string;
  height?: string;
}

export const StyledNumberField = styled(TextField, {
  shouldForwardProp: (prop) =>
    !["width", "height", "inputFontSize", "labelFontSize"].includes(
      prop as string
    ),
})<StyledNumberFieldProps>(({ theme, width, height }) => ({
  width: width || "425px",
  height: height || "65px",
  "& .MuiOutlinedInput-root": {
    height: "100%",
    "& fieldset": {
      borderColor: "#273551",
      borderWidth: "2px",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#273551",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#273551",
      borderWidth: "2px",
    },
    "& .MuiInputBase-input": {
      color: "black",
      height: "100%",
      fontSize: "16px", // Default to 16px if not specified
    },
  },
  "& .MuiInputLabel-root": {
    color: "#181818",
  },
}));
