import { Box, DialogContent, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { cartActions } from "../../../slices/cartSlice";
import AddCustomTipForm from "../Form/AddCustomTipForm";

export default function AddCustomTipModal() {
  const openAddCustomTipPopup = useAppSelector(
    (state) => state.cart.openAddCustomTipPopup
  );
  const dispatch = useAppDispatch();
  const handleClosePopup = () => {
    dispatch(cartActions.setOpenAddCustomTipPopup(false));
  };
  return (
    <>
      <MuiDialog open={openAddCustomTipPopup} onClose={handleClosePopup}>
        <DialogTitle>Ajout d'un pourboire</DialogTitle>
        <DialogContent>Veuillez entrer le montant du pourboire</DialogContent>
        <Box justifyContent={"center"} p={2}>
          <AddCustomTipForm />
        </Box>
      </MuiDialog>
    </>
  );
}
