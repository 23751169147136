import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuCategoryEntity } from "../../../../../common/Entities/IMenuCategoryEntity";
import { TItemSliceState } from "./types/TItemSliceState";
import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";

const initialState: TItemSliceState = {
  selectedItem: null,
  isCreateMenuItemModalOpen: false,
  isDeleteMenuItemModalOpen: false,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<IMenuItemEntity>) => {
      state.selectedItem = action.payload;
    },
    setIsCreateMenuItemModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateMenuItemModalOpen = action.payload;
    },
    setIsDeleteMenuItemModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteMenuItemModalOpen = action.payload;
    },
    // New reducer to sync selectedItem with updated menuList
    syncSelectedItem: (state, action: PayloadAction<IMenuEntity[]>) => {
      if (!state.selectedItem) return; // Early return if there's no selected item

      const updatedItem = action.payload
        .flatMap((menu) => menu.categories)
        .flatMap((category) => category.itemList)
        .find((item) => item.id === state.selectedItem!.id);

      if (updatedItem) {
        state.selectedItem = updatedItem;
      } else {
        state.selectedItem = null;
      }
    },
  },
});

export const itemReducer = itemSlice.reducer;

export const itemActions = itemSlice.actions;
