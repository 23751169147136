import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import { IUserRoleEntity } from "../../../../common/Entities/IRoleEntity";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import useIAMRepository from "../../respository/useIAMRepository";
import CreateIAMFields from "./CreateIAMFields";
import { TCreateIAMFormAttributes } from "./types/TCreateIAMFormAttributes";
import { TCreateIAMFormProps } from "./types/TCreateIAMFormProps";

export default function CreateIAMForm(props: TCreateIAMFormProps) {
  const dispatch = useAppDispatch();
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const { onCreateIAM } = useIAMRepository();

  const submitHandler: SubmitHandler<TCreateIAMFormAttributes> = async (
    data
  ) => {
    console.log("role", data.role);
    const foundRole: IUserRoleEntity | undefined = props.roles.find(
      (role) => role.id === data.role.id
    );
    return await onCreateIAM({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      role: foundRole!,
      attachedToUserId: authenticatedUser!.userId,
    });
  };
  const successHandler = () => {
    props.handleCloseModal();
  };

  const rolesToStringList = (roles: IUserRoleEntity[]): string[] => {
    return roles.map((role) => role.name);
  };

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Ajouter l'utilisateur"
      onSuccess={successHandler}
      name="create-iam-form"
      defaultErrorDisplayCollapse={true}
    >
      <CreateIAMFields
        roles={props.roles}
        rootUserEmail={props.rootUserEmail}
      />
    </CustomForm>
  );
}
