import CartRecapBox from "../../../../containers/OrderRecapBox/CartRecapBox";
import { TCartValidationRecapBoxProps } from "./types/TCartValidationRecapBoxProps";

export default function CartValidationRecapBox(
  props: TCartValidationRecapBoxProps
) {
  return (
    <CartRecapBox
      showTotalPrice={false}
      cart={props.cart}
      showQuantityBox={true}
      /* showQuantityBox={false}*/
      iconType="delete"
    />
  );
}
