import React from "react";
import { TMenuItemCustomizationTitleProps } from "./types/TMenuItemCustomizationTitleProps";
import { Grid, Typography } from "@mui/material";
import RequiredBox from "../RequiredBox/RequiredBox";
import MoreMenu from "../MoreMenu/MoreMenu";

export default function MenuItemCustomizationTitle(
  props: TMenuItemCustomizationTitleProps
) {
  return (
    <Grid
      container
      direction={"row"}
      sx={{ alignItems: "center", display: "flex" }}
    >
      <Grid item>
        <MoreMenu
          customizationIndex={props.customizationIndex}
          menuItemCustomization={props.menuItemCustomization}
        />
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.menuItemCustomization.name}
        </Typography>
      </Grid>
      {props.menuItemCustomization.required && (
        <Grid item ml={1}>
          <RequiredBox />
        </Grid>
      )}
    </Grid>
  );
}
