import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { TKitchenTicketProps } from "./types/TKitchenTicketProps";

import { calculateTotalCartPrice } from "../../../../utils/cart/shared";
import KitchenItemBox from "../KitchenItemBox/KitchenItemBox";
import KitchenTable from "../KitchenTable/KitchenTable";
import KitchenTicketFooterButtons from "./KitchenTicketFooterButtons";
import KitchenTicketFooterTotals from "./KitchenTicketFooterTotals";

const KitchenTicket: React.FC<TKitchenTicketProps> = (props) => {
  const [tickets, setTickets] = useState<JSX.Element[]>([]);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const footerRef = useRef<HTMLDivElement>(null);

  // State to store dynamic max ticket height
  const [maxTicketHeight, setMaxTicketHeight] = useState<number>(570); // Default value
  const [allItems, setAllItems] = useState<JSX.Element[]>([]);

  // Update maxTicketHeight based on window size
  useEffect(() => {
    const handleResize = () => {
      // Calculate the available height, subtracting any fixed element heights like a header (e.g., 100px)
      const availableHeight = window.innerHeight - 100; // Example fixed header height
      setMaxTicketHeight(availableHeight);
      console.log("availableHeight", availableHeight);
    };

    // Initial calculation
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createAllItems = () => {
    const items: JSX.Element[] = [];
    props.cart.categories.forEach((category) => {
      category.items.forEach((item) => {
        for (let i = 0; i < item.quantity - item.rejectedQuantity; i++) {
          items.push(
            <div
              key={`${item.id}-${i}`}
              ref={(el) => itemRefs.current.push(el)}
            >
              <KitchenItemBox
                cartItem={item}
                isLastIndex={false}
                isFirstIndex={false}
                cartId={props.cart.id!}
              />
            </div>
          );
        }
      });
    });
    return items;
  };

  const splitTickets = (allItems: JSX.Element[]) => {
    const footerHeight = 180;
    const newTickets: JSX.Element[] = [];
    let currentTicketItems: JSX.Element[] = [];
    let currentHeight = 0;
    const MAX_HEIGHT = maxTicketHeight - 100; // Dynamic ticket height minus a margin

    for (let i = 0; i < allItems.length; i++) {
      const itemHeight = itemRefs.current[i]?.offsetHeight || 0;
      console.log("currentHeight + itemHeight", currentHeight + itemHeight);
      console.log("MAX_TICKET_HEIGHT", MAX_HEIGHT);
      if (i === allItems.length - 1) {
        if (currentHeight + itemHeight + footerHeight > MAX_HEIGHT) {
          newTickets.push(createTicket(currentTicketItems, false));
          currentTicketItems = [];
          currentHeight = 0;
          currentTicketItems.push(allItems[i]);
          currentHeight += itemHeight;
          newTickets.push(createTicket(currentTicketItems, true));
        } else {
          currentTicketItems.push(allItems[i]);
          currentHeight += itemHeight;
          newTickets.push(createTicket(currentTicketItems, true));
        }
      } else {
        if (currentHeight + itemHeight > MAX_HEIGHT) {
          newTickets.push(createTicket(currentTicketItems, false));
          currentTicketItems = [];
          currentHeight = 0;
          currentTicketItems.push(allItems[i]);
          currentHeight += itemHeight;
        } else {
          currentTicketItems.push(allItems[i]);
          currentHeight += itemHeight;
        }
      }
    }

    setTickets(newTickets);
  };

  useEffect(() => {
    if (maxTicketHeight) {
      console.log("props.qsffqqsfqsfqsfqsfqf");
      setAllItems([]);
      setTickets([]);
      const createdItems = createAllItems();
      setAllItems(createdItems);

      // Render all items in a single ticket initially
      setTickets([createTicket(allItems, true)]);

      // Use a timeout to ensure the DOM has updated before measuring heights
      setTimeout(() => splitTickets(allItems), 0);
    }
  }, [JSON.stringify(props.cart), maxTicketHeight]);

  const createTicket = (items: JSX.Element[], isLast: boolean) => (
    <Box
      key={`ticket-${tickets.length}`}
      className="kitchen-ticket"
      sx={{
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        width: "265px",
        maxHeight: `${maxTicketHeight}px`, // Use dynamic height
        marginBottom: "20px",
      }}
    >
      <Box p={1}>
        {items}
        {isLast && (
          <div ref={footerRef}>
            <KitchenTicketFooterButtons
              handleOpenCloseModal={props.handleOpenCloseModal}
              handleOpenEditModal={props.handleOpenEditModal}
              handleOpenRejectModal={props.handleOpenRejectModal}
            />
            <Divider />
            <KitchenTicketFooterTotals cart={props.cart} />
          </div>
        )}
      </Box>
    </Box>
  );

  return <>{tickets}</>;
};

export default KitchenTicket;
