import React from "react";
import { urlBase64ToUint8Array } from "../../../../utils/sharedFunctions";
import { useAppDispatch } from "../../../store/redux";
import {
  findAllNotifications,
  subscribeToNotifications,
} from "../services/notificationService";
import { TSubscribeResponse } from "../services/types/Responses/TSubscribeResponse";
import { INotificationEntity } from "../../../Entities/INotificaitonEntity";
import { INotificationUIEntity } from "../../../Entities/INotificationUIEntity";
import { Typography } from "@mui/material";
import { notificationActions } from "../slices/notificationSlice";
import { TFindAllNotificationsResponse } from "../services/types/Responses/TFindAllNotificationsResponse";
import { Notifications } from "@mui/icons-material";
import { IPaidFullyNotificationEntity } from "../../../Entities/IPaidFullyNotificationEntity";
import { IPaidPartiallyNotificationEntity } from "../../../Entities/IPaidPartiallyNotificationEntity";
import { ITipNotificationEntity } from "../../../Entities/ITipNotificationEntity";

export default function useNotificationRepository() {
  const dispatch = useAppDispatch();

  const getNewOrderContent = (table: string): JSX.Element => {
    return (
      <Typography
        sx={{ fontSize: "14px", color: "#334155", fontWeight: "400" }}
      >
        La <span style={{ fontWeight: "bold" }}>table {table}</span> viens de
        passer une <span style={{ fontWeight: "bold" }}>nouvelle commande</span>
      </Typography>
    );
  };

  const getOrderRejectedContent = (table: string): JSX.Element => {
    return (
      <Typography
        sx={{ fontSize: "14px", color: "#334155", fontWeight: "400" }}
      >
        Un ou plusieurs articles provenant de la table{" "}
        <span style={{ fontWeight: "bold" }}>{table}</span> ont été rejetés par
        la cuisine.
      </Typography>
    );
  };

  const getPaidFullyContent = (
    notification: IPaidFullyNotificationEntity
  ): JSX.Element => {
    return (
      <Typography
        sx={{ fontSize: "14px", color: "#334155", fontWeight: "400" }}
      >
        La
        <span style={{ fontWeight: "bold" }}>table {notification.table}</span> a
        payé{" "}
        <span style={{ fontWeight: "bold" }}> €{notification.amountPaid}</span>{" "}
        et viens de réglé la{" "}
        <span style={{ fontWeight: "bold" }}>totalité de l'adittion</span>
      </Typography>
    );
  };
  const getPaidPartiallyContent = (
    notification: IPaidPartiallyNotificationEntity
  ): JSX.Element => {
    return (
      <Typography
        sx={{ fontSize: "14px", color: "#334155", fontWeight: "400" }}
      >
        La
        <span style={{ fontWeight: "bold" }}>table {notification.table}</span> a
        payé{" "}
        <span style={{ fontWeight: "bold" }}> €{notification.amountPaid}</span>{" "}
        il reste
        <span style={{ fontWeight: "bold" }}>
          €{notification.amountRemaining}
        </span>{" "}
        a payé
      </Typography>
    );
  };
  const getTipContent = (notification: ITipNotificationEntity): JSX.Element => {
    return (
      <Typography
        sx={{ fontSize: "14px", color: "#334155", fontWeight: "400" }}
      >
        La
        <span style={{ fontWeight: "bold" }}>
          table {notification.table}
        </span>{" "}
        viens de versé un pourboire d'un montant de
        <span style={{ fontWeight: "bold" }}>
          {" "}
          €{notification.tipAmount}
        </span>{" "}
      </Typography>
    );
  };

  const notificationToNotificationUI = (
    notification: INotificationEntity
  ): INotificationUIEntity => {
    let content: JSX.Element = <></>;
    switch (notification.type) {
      case "NEW_ORDER": {
        content = getNewOrderContent(notification.table);
        break;
      }
      case "ORDER_REJECTED": {
        content = getOrderRejectedContent(notification.table);
        break;
      }
      case "PAID_FULLY": {
        content = getPaidFullyContent(
          notification as IPaidFullyNotificationEntity
        );
        break;
      }
      case "PAID_PARTIALLY": {
        content = getPaidPartiallyContent(
          notification as IPaidPartiallyNotificationEntity
        );
        break;
      }
      case "TIP": {
        content = getTipContent(notification as ITipNotificationEntity);
        break;
      }
    }
    return {
      id: notification.id,
      table: notification.table,
      type: notification.type,
      content: content,
    };
  };

  const onAddTipNotification = async (notification: ITipNotificationEntity) => {
    const notificationUI: INotificationUIEntity =
      notificationToNotificationUI(notification);
    dispatch(notificationActions.addNotificaiton(notificationUI));
  };

  const onAddPaidPartiallyNotification = async (
    notification: IPaidPartiallyNotificationEntity
  ) => {
    const notificationUI: INotificationUIEntity =
      notificationToNotificationUI(notification);
    dispatch(notificationActions.addNotificaiton(notificationUI));
  };

  const onAddPaidFullyNotification = async (
    notification: IPaidFullyNotificationEntity
  ) => {
    const notificationUI: INotificationUIEntity =
      notificationToNotificationUI(notification);
    dispatch(notificationActions.addNotificaiton(notificationUI));
  };

  const onAddNewOrderNotificaiton = async (
    notification: INotificationEntity
  ) => {
    const notificationUI: INotificationUIEntity =
      notificationToNotificationUI(notification);
    dispatch(notificationActions.addNotificaiton(notificationUI));
  };

  const onAddOrderRejectedNotificaiton = async (
    notification: INotificationEntity
  ) => {
    const notificationUI: INotificationUIEntity = {
      table: notification.table,
      type: notification.type,
      id: notification.id,
      content: getOrderRejectedContent(notification.table),
    };
    dispatch(notificationActions.addNotificaiton(notificationUI));
  };

  const onFindAllNotifications =
    async (): Promise<TFindAllNotificationsResponse> => {
      // Dispatch action to save the subscription on the server
      const resultAction = await dispatch(findAllNotifications());

      if (findAllNotifications.rejected.match(resultAction)) {
        throw new Error("Failed to subscribe to notifications");
      }
      let notificationUIS: INotificationUIEntity[] = [];
      const notifications = resultAction.payload.notifications;
      notifications.map((notification) => {
        notificationUIS.push(notificationToNotificationUI(notification));
      });
      dispatch(notificationActions.setNotificaitons(notificationUIS));
      return resultAction.payload;
    };
  const onSubscribeUserToPush = async (request: {
    table: string | null;
    userRef: string;
  }): Promise<TSubscribeResponse> => {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        `${process.env.REACT_APP_VAPID_PUBLIC_KEY}`
      ),
    });
    // Correctly access the keys from the subscription object
    const subscriptionJson = subscription.toJSON();

    // Check if keys exist before accessing them
    if (
      !subscriptionJson.keys ||
      !subscriptionJson.keys.p256dh ||
      !subscriptionJson.keys.auth
    ) {
      throw new Error("Subscription keys are missing or undefined");
    }

    // Dispatch action to save the subscription on the server
    const resultAction = await dispatch(
      subscribeToNotifications({
        endpoint: subscription.endpoint,
        expirationTime: subscription.expirationTime,
        keys: {
          p256dh: subscriptionJson.keys.p256dh, // Access the 'p256dh' key correctly
          auth: subscriptionJson.keys.auth, // Access the 'auth' key correctly
        },
        userRef: request.userRef,
        table: request.table,
      })
    );

    if (subscribeToNotifications.rejected.match(resultAction)) {
      throw new Error("Failed to subscribe to notifications");
    }
    return resultAction.payload;
  };

  return {
    onSubscribeUserToPush,
    onAddNewOrderNotificaiton,
    onAddOrderRejectedNotificaiton,
    onFindAllNotifications,
    onAddPaidFullyNotification,
    onAddPaidPartiallyNotification,
    onAddTipNotification,
  };
}
