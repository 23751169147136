import { Grid, Typography } from "@mui/material";
import CartCategoryPrice from "../../../../containers/OrderRecapBox/CartCategoryPrice";
import CartCategoryCheckBox from "../CheckBox/CartCategoryCheckBox";
import { TCartCategoryProps } from "./types/TCartCategoryProps";

export default function CartCategory(props: TCartCategoryProps) {
  return (
    <Grid item container xs={12}>
      <Grid item xs={2}>
        <CartCategoryCheckBox
          cartCategory={props.cartCategory}
          categoryIndex={props.categoryIndex}
        />
      </Grid>
      <Grid
        item
        container
        xs={7}
        sx={{ wordBreak: "break-word" }}
        alignItems={"center"}
      >
        <Grid xs={8}>
          <Typography className="cart-recap-box-item-title">
            {props.cartCategory.category.name}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <span className="listItem-heading-medium-secondary">
            {`x${props.cartCategory.items.reduce((accumulator, currentVal) => {
              return accumulator + currentVal.quantity;
            }, 0)}`}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={3} className="grid-center">
        <CartCategoryPrice cartCategory={props.cartCategory} />
      </Grid>
    </Grid>
  );
}
