import { Button, Grid, Typography } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import useKitchenRepository from "../../repository/useKitchenRepository";
import { TCloseCartModalProps } from "./types/TCloseCartModalProps";

export const RejectCartMessages: Record<string, string> = {
  PENDING_MAIN_TITLE: "Terminer la commande",
  PENDING_SECOND_TITLE:
    "Etes-vous sûr de vouloir terminer la commande ? Le serveur sera notifié.",
  CONFIRMED_MAIN_TITLE: "La commande a été terminée",
  CONFIRMED_SECOND_TITLE:
    "Veuillez en informer un serveur a proximité pour qu'il puisse assister le client.",
};

export default function CloseCartModal(props: TCloseCartModalProps) {
  const { onCloseCart } = useKitchenRepository();
  const [mainTitle, setMainTitle] = useState<string>(
    RejectCartMessages.PENDING_MAIN_TITLE
  );
  const [secondTitle, setSecondTitle] = useState<string>(
    RejectCartMessages.PENDING_SECOND_TITLE
  );
  const [status, setStatus] = useState<"PENDING" | "CONFIRMED">("PENDING");
  const handleCloseModal = () => {
    props.setIsOpen(false);
  };

  const handleConfirm = async () => {
    await onCloseCart({ cartId: props.cart.id!, table: props.cart.table });
    setStatus("CONFIRMED");
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  useEffect(() => {
    switch (status) {
      case "PENDING": {
        setMainTitle(RejectCartMessages.PENDING_MAIN_TITLE);
        setSecondTitle(RejectCartMessages.PENDING_SECOND_TITLE);
        break;
      }
      default: {
        setMainTitle(RejectCartMessages.CONFIRMED_MAIN_TITLE);
        setSecondTitle(RejectCartMessages.CONFIRMED_SECOND_TITLE);
        break;
      }
    }
  }, [status]);
  return (
    <MuiDialog
      open={props.isOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      slotProps={{
        backdrop: true,
      }}
      PaperProps={{
        sx: {
          borderRadius: "24px", // Adjust this value as needed
          overflow: "hidden", // This ensures the content doesn't overflow the rounded corners
        },
      }}
    >
      <Grid container pt={4} pb={4} width={"100%"} justifyContent={"center"}>
        <Grid container direction="column" alignItems="center" maxWidth={"80%"}>
          <Grid
            item
            sx={{
              borderRadius: "50%",
              backgroundColor: "#091E42",
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 2,
            }}
            className="font-inter"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "26px",
                color: "white",
              }}
            >
              {props.cart.table}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "black",
                textAlign: "center",
              }}
            >
              {mainTitle}
            </Typography>
          </Grid>
          <Grid item sx={{ maxWidth: "358px", mt: 2 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#A08686",
                textAlign: "center",
              }}
            >
              {secondTitle}
            </Typography>
          </Grid>
          {status === "PENDING" && (
            <Grid
              container
              sx={{
                maxWidth: "358px",
                mt: 3,
                justifyContent: "center",
                gap: 5,
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  border: "3px solid black",
                  fontSize: "20px",
                  width: "147px",
                  height: "50px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                onClick={handleCancel}
              >
                Annuler
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#00AC4F",
                  borderRadius: "10px",
                  fontSize: "20px",
                  width: "147px",
                  height: "50px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#00AC4F",
                  },
                }}
                onClick={handleConfirm}
              >
                Confirmer
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MuiDialog>
  );
}
