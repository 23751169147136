import { Box, Typography } from "@mui/material";
import BackToMenuButton from "../BackToMenuButton/BackToMenuButton";
import { TCartHeaderProps } from "./types/TCartHeaderProps";

export default function CartHeader(props: TCartHeaderProps) {
  return (
    <div>
      <BackToMenuButton
        userRef={props.userRef}
        table={props.table}
        menuId={props.menuId}
      />
      <Box
        sx={{
          borderRadius: !props.disabledBoxShadow ? "15px" : "",
          boxShadow: !props.disabledBoxShadow
            ? "0 1px 4px 1px rgba(0,0,0,0.14)"
            : "",
          mt: 2,
          mb: 1,
          p: 1,
        }}
      >
        <Typography
          sx={{ fontSize: "26px", fontWeight: "bold", color: "black" }}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{ fontSize: "15px", fontWeight: "500", color: "#818181" }}
        >
          {props.subtitle}
        </Typography>
      </Box>
    </div>
  );
}
