import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CustomTimePicker from "./CustomTimePicker";
import { TCustomMenuTimePickerProps } from "./types/TCustomMenuTimePickerProps";
import { TUpdateMEnuTimeframeFormAttributes } from "./types/TUpdateMEnuTimeframeFormAttributes";
import { useFormContext } from "react-hook-form";

export default function CustomMenuTimePicker(
  props: TCustomMenuTimePickerProps
) {
  const { setError, watch, clearErrors } =
    useFormContext<TUpdateMEnuTimeframeFormAttributes>();
  const activeFrom = watch("activeFrom");
  const activeTo = watch("activeTo");

  useEffect(() => {
    if (activeFrom && activeTo && activeFrom.isSame(activeTo)) {
      setError("activeFrom", {
        message:
          "Veuillez choisir une heure de début différente de l'heure de fin",
      });
      setError("activeTo", {
        message:
          "Veuillez choisir une heure de fin différente de l'heure de début",
      });
    } else {
      clearErrors("activeFrom");
      clearErrors("activeTo");
    }
  }, [activeFrom, activeTo]);

  useEffect(() => {
    if (activeFrom && activeFrom.isAfter(activeTo)) {
      setError("activeFrom", {
        message:
          "Veuillez choisir une heure de début inférieure à l'heure de fin",
      });
    } else {
      clearErrors("activeFrom");
    }
  }, [activeFrom]);

  useEffect(() => {
    if (activeTo && activeTo.isBefore(activeFrom)) {
      setError("activeTo", {
        message:
          "Veuillez choisir une heure de fin supérieure à l'heure de début",
      });
    } else {
      clearErrors("activeTo");
    }
  }, [activeTo]);
  return (
    <div>
      <Grid item mb={4}>
        <CustomTimePicker
          name="activeFrom"
          label="Heure de début"
          validation={{
            required: "Veuillez renseigner une heure de début",
          }}
          defaultValue={
            props.selectedMenu?.activeFrom
              ? new Date(props.selectedMenu.activeFrom)
              : null
          }
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTimePicker
          name="activeTo"
          label="Heure de fin"
          validation={{
            required: "Veuillez renseigner une heure de fin",
          }}
          defaultValue={
            props.selectedMenu?.activeTo
              ? new Date(props.selectedMenu.activeTo)
              : null
          }
        />
      </Grid>
    </div>
  );
}
