import { Grid } from "@mui/material";
import GradientDivider from "../../../../common/containers/GradientDivider/GradientDivider";
import CartItem from "../CartItem/CartItem";
import { TCartItemWithCategoryProps } from "./types/TCartItemWithCategoryProps";
import CartItemPrice from "./CartItemPrice";
import CartCategory from "../../modules/Payment/containers/CartCategory/CartCategory";
import React from "react";
import { calculateCartItemPrice } from "../../../../utils/cart/shared";

export default function CartItemWithCategory(
  props: TCartItemWithCategoryProps
) {
  return (
    <Grid item container>
      <CartCategory
        cartCategory={props.cartCategory}
        categoryIndex={props.categoryIndex}
        cart={props.cart}
      />

      {props.cartCategory.items.map((item, itemIndex) => (
        <React.Fragment key={itemIndex}>
          {props.iconType === "checkbox" ? (
            Array.from({ length: item.quantity }).map((_, quantityIndex) => (
              <Grid item container mt={1} key={`${itemIndex}-${quantityIndex}`}>
                <Grid item xs={7} pl={4} mb={2}>
                  <CartItem
                    fontType="category-item"
                    cartIndexes={{
                      categoryIndex: props.categoryIndex,
                      itemIndex,
                    }}
                    cartItem={item}
                    iconType={props.iconType}
                    showQuantityBox={props.showQuantityBox}
                  />
                </Grid>

                <Grid item container xs={3} alignItems={"center"}>
                  <Grid item>
                    <CartItemPrice
                      iconType={props.iconType}
                      price={calculateCartItemPrice({ ...item, quantity: 1 })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item container mt={1}>
              <Grid item xs={7} pl={4} mb={2}>
                <CartItem
                  fontType="category-item"
                  cartIndexes={{
                    categoryIndex: props.categoryIndex,
                    itemIndex,
                  }}
                  cartItem={item}
                  iconType={props.iconType}
                  showQuantityBox={props.showQuantityBox}
                />
              </Grid>

              <Grid item container xs={3} alignItems={"center"}>
                <Grid item>
                  <CartItemPrice
                    iconType={props.iconType}
                    price={calculateCartItemPrice(item)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      ))}

      {props.categoryIndex !== props.lastCategoryIndex && (
        <Grid item xs={12} paddingLeft={5} paddingRight={5}>
          <GradientDivider />
        </Grid>
      )}
    </Grid>
  );
}
