import { DialogTitle } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { menuItemCustomizationActions } from "../../slice/menuItemCustomizationSlice";
import CreateMenuItemCustomizationForm from "../Forms/CreateMenuItemCustomizationForm";
import { TCreateMenuItemCustomizationModalProps } from "./types/TCreateMenuItemCustomizationModalProps";

export default function CreateMenuItemCustomizationModal(
  props: TCreateMenuItemCustomizationModalProps
) {
  const isOpen = useAppSelector(
    (state) =>
      state.menuItemCustomization.isCreateMenuItemCustomizationModalOpen
  );
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(
      menuItemCustomizationActions.setIsCreateMenuItemCustomizationModalOpen(
        false
      )
    );
  };
  return (
    <>
      <MuiDialog open={isOpen} onClose={handleCloseModal} maxWidth="sm">
        <DialogTitle>Création de la personalisation</DialogTitle>
        <CreateMenuItemCustomizationForm
          handleCloseModal={handleCloseModal}
          menuItemId={props.menuItemId}
        />
      </MuiDialog>
    </>
  );
}
