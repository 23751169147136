import React from "react";
import { TOnInitiatePaymentResponse } from "../services/types/Responses/TOnInitiatePaymentRespons";
import { initiatePayment } from "../services/paymentServices";
import { useAppDispatch } from "../../../common/store/redux";
import { TOnInitiatePaymentRequest } from "../services/types/Requests/TOnInitiatePaymentRequest";

export default function usePaymentRepository() {
  const dispatch = useAppDispatch();
  const onInitiatePayment = async (
    request: TOnInitiatePaymentRequest
  ): Promise<TOnInitiatePaymentResponse | null> => {
    const resultAction = await dispatch(initiatePayment(request));
    if (initiatePayment.rejected.match(resultAction)) {
      return null;
    }
    return resultAction.payload;
  };
  return { onInitiatePayment };
}
