import { InputAdornment, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TCustomTipFieldProps } from "./types/TCustomTipFieldProps";

const CustomTipField = (props: TCustomTipFieldProps) => {
  const { control } = useFormContext();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value;
    // Allow empty input, digits, and one dot with up to two decimal places
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      onChange(value);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    let value = e.target.value.trim();

    // Remove trailing dot
    if (value.endsWith(".")) {
      value = value.slice(0, -1);
    }

    onChange(String(Number(value).toFixed(2)));
  };

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validation}
      defaultValue={props.defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type="number"
          onChange={(e) => handleChange(e, field.onChange)}
          onBlur={(e) => {
            field.onBlur();
            handleBlur(e, field.onChange);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
            inputMode: "decimal",
          }}
          value={field.value ?? ""}
          error={!!error}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
          }}
          fullWidth
        />
      )}
    />
  );
};

export default CustomTipField;
