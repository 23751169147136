import accountBalanceIcon from "../../../../../../common/images/account_balance_icon.png";
import { useAppSelector } from "../../../../../../common/store/redux";
import StatsBox from "../StatsBox/StatsBox";

export default function AccountBalance() {
  const availableBalance = useAppSelector(
    (state) => state.fundsManagement.availableBalance
  );
  return (
    <>
      <StatsBox
        statValue={availableBalance ?? 0}
        title="Solde disponible"
        img={{ url: accountBalanceIcon }}
        transferButton={true}
      />
    </>
  );
}
